import Box from '@amzn/awsui-components-react/polaris/box';
import Container from '@amzn/awsui-components-react/polaris/container';
import Grid from '@amzn/awsui-components-react/polaris/grid';
import Header from '@amzn/awsui-components-react/polaris/header';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useState } from 'react';
import AggregationSelector from '../aggregation_selector/AggregationSelector';
import AggregationTable from '../aggregation_table/AggregationTable';
import StudySummaryPieChart from '../chats/pie_chat/StudySummaryPieChart';
import StudySummaryBarChart from '../chats/bar_chart/StudySummaryBarChart';
import {
  getAggregationLabel,
  getAggregationLabelValue,
  getAggregationSelectorText,
} from '../utils/aggregation_utils';

const StudySummaryDetails = ({
  heading,
  dataValuePrefix,
  dataValueSuffix,
  aggregation,
  isAggregationLoading,
  initialSelectedAggId,
  aggItems,
  aggTypes,
  getItems,
  getColumnDefinitions,
  xDomainSortArray,
  yTile,
}) => {
  const [selectedAggId, setSelectedAggId] = useState(initialSelectedAggId);
  const [selectAggBucket, setSelectedAggBucket] = useState(null);

  const changeAggregation = newAggId => {
    if (selectedAggId === newAggId) {
      return;
    }

    setSelectedAggId(newAggId);
    setSelectedAggBucket(null);
  };

  const getEmptyPieChartView = () => {
    return (
      <Box textAlign="center" color="inherit">
        <b>No selection yet</b>
      </Box>
    );
  };

  const getHeader = () => {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <AggregationSelector
              selectedAggId={selectedAggId}
              aggItems={aggItems}
              changeAggregation={changeAggregation}
            />
          </SpaceBetween>
        }
      >
        {heading} details
      </Header>
    );
  };

  const getBarChart = () => {
    return (
      <Box className="right-divider" padding={{ right: 'l' }}>
        <SpaceBetween direction="vertical" size="xs">
          <Box fontWeight="bold">
            {heading} {getAggregationSelectorText(selectedAggId, aggItems)}
          </Box>
          <StudySummaryBarChart
            selectedAggId={selectedAggId}
            aggregation={get(aggregation, selectedAggId, {})}
            aggTypes={aggTypes}
            isLoading={isAggregationLoading}
            xDomainSortArray={xDomainSortArray}
            yTile={yTile}
          />
        </SpaceBetween>
      </Box>
    );
  };

  const getPieChart = () => {
    return (
      <Box>
        <SpaceBetween direction="vertical" size="xs">
          <Box fontWeight="bold">
            {selectAggBucket
              ? `${heading} of ${getAggregationLabelValue(selectedAggId, selectAggBucket)}`
              : `Please select a ${toLower(
                  getAggregationLabel(selectedAggId, aggItems),
                )} for a breakdown`}
          </Box>
          <StudySummaryPieChart
            aggregation={get(aggregation, [selectedAggId, selectAggBucket], {})}
            aggTypes={aggTypes}
            emptyView={getEmptyPieChartView()}
            dataValuePrefix={dataValuePrefix}
            dataValueSuffix={dataValueSuffix}
          />
        </SpaceBetween>
      </Box>
    );
  };

  const getAggregationTable = () => {
    return (
      <AggregationTable
        aggId={selectedAggId}
        aggText={getAggregationLabel(selectedAggId, aggItems)}
        aggregation={get(aggregation, selectedAggId, {})}
        getItems={getItems}
        getColumnDefinitions={getColumnDefinitions}
        setSelectedAggBucket={setSelectedAggBucket}
        isLoading={isAggregationLoading}
      />
    );
  };

  return (
    <Container header={getHeader()}>
      <SpaceBetween direction="vertical" size="l">
        <Grid gridDefinition={[{ colspan: 7 }, { colspan: 5 }]}>
          {getBarChart()}
          {getPieChart()}
        </Grid>
        {getAggregationTable()}
      </SpaceBetween>
    </Container>
  );
};

StudySummaryDetails.propTypes = {
  heading: PropTypes.string.isRequired,
  dataValueSuffix: PropTypes.string,
  aggregation: PropTypes.object,
  isAggregationLoading: PropTypes.bool,
  initialSelectedAggId: PropTypes.string.isRequired,
  aggItems: PropTypes.array.isRequired,
  aggTypes: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  getColumnDefinitions: PropTypes.func.isRequired,
  xDomainSortArray: PropTypes.array,
  yTile: PropTypes.string.isRequired,
  dataValuePrefix: PropTypes.string,
};

StudySummaryDetails.defaultProps = {
  aggregation: {},
  dataValueSuffix: '',
  dataValuePrefix: '',
  isAggregationLoading: false,
  xDomainSortArray: [],
};
export default StudySummaryDetails;
