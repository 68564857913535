import { catchError, concatMap, mergeMap } from 'rxjs/operators';
import { from } from 'rxjs';
import { decoratePayload } from '../../../../../redux/epics/utils';

const ZERO_ACTIONS_STREAM = () => [];
export default ({ actionType, payloadGenerator, successCallback, errorCallback }) => {
  if (!actionType) {
    return ZERO_ACTIONS_STREAM;
  }
  const epic = (action$, store, { API }) =>
    action$.ofType(actionType).pipe(
      mergeMap(action => {
        const originalPayload = action.payload;
        const { api } = originalPayload;
        return from(decoratePayload(API, api, originalPayload, store, payloadGenerator)).pipe(
          concatMap(
            successCallback
              ? response => successCallback(response, store, action)
              : ZERO_ACTIONS_STREAM,
          ),
          catchError(
            errorCallback ? error => errorCallback(error, store, action) : ZERO_ACTIONS_STREAM,
          ),
        );
      }),
    );

  return epic;
};
