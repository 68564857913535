import { forEach, get, set } from 'lodash';
import { pick } from 'lodash/object';
import { QRE_SUMMARY_ATTRIBUTES } from '../components/qre_tab/constants/qreTypes';

const removeActivityPost80 = QRE => {
  const aggByStatus = {};
  forEach(get(QRE, 'aggByStatus'), (statusValue, statusKey) => {
    const statusPlaceholder = {};
    forEach(statusValue, (aggByValue, aggByKey) => {
      statusPlaceholder[aggByKey] = aggByValue;
      if (aggByKey === 'aggByFederalQRE') {
        set(
          statusPlaceholder,
          [aggByKey],
          pick(get(statusPlaceholder, [aggByKey]), QRE_SUMMARY_ATTRIBUTES),
        );
      } else {
        forEach(aggByValue, (clientValue, clientKey) => {
          set(
            statusPlaceholder[aggByKey],
            [clientKey],
            pick(get(statusPlaceholder, [aggByKey, clientKey]), QRE_SUMMARY_ATTRIBUTES),
          );
        });
      }
    });
    aggByStatus[statusKey] = statusPlaceholder;
  });
  return { ...QRE, aggByStatus };
};

const calculateAdditionalBenefits = QRE => {
  const aggByStatus = {};
  forEach(get(QRE, 'aggByStatus'), (statusValue, statusKey) => {
    const statusPlaceholder = {};
    forEach(statusValue, (aggByValue, aggByKey) => {
      statusPlaceholder[aggByKey] = aggByValue;
      if (aggByKey === 'aggByFederalQRE') {
        set(
          statusPlaceholder,
          [aggByKey, 'sumByAdditionalBenefits'],
          get(aggByValue, 'sumByLaborFederalActivityPost80') -
            get(aggByValue, 'sumByLaborFederalActivityPre80'),
        );
      } else {
        forEach(aggByValue, (clientValue, clientKey) => {
          set(
            statusPlaceholder,
            [aggByKey, clientKey, 'sumByAdditionalBenefits'],
            get(clientValue, 'sumByLaborFederalActivityPost80') -
              get(clientValue, 'sumByLaborFederalActivityPre80'),
          );
        });
      }
    });
    aggByStatus[statusKey] = statusPlaceholder;
  });
  return removeActivityPost80({ ...QRE, aggByStatus });
};
export default calculateAdditionalBenefits;
