// Trigger - Event
export const TRIGGERING_EVENT_INITIAL_STATE = {
  triggeringEvent: { label: null, value: null },
};

// Frequency - one time, Trigger - Time
export const ONE_TIME_INITIAL_STATE = {
  relativeOperator: { label: null, value: null },
  relativeDate: { label: null, value: null },
  temporalUnit: { label: null, value: null },
  temporalData: '',
};

export const TEMPORAL_DATA_INITIAL_STATE = {
  temporalUnit: { label: null, value: null },
  temporalData: '',
};

// Frequency - Recurring, Trigger - Time
export const RECURRING_INITIAL_STATE = {
  relativeStartDate: { label: null, value: null },
  relativeStartDateValue: '',
  relativeEndDate: { label: null, value: null },
  relativeEndDateValue: '',
  temporalDataList: [],
  daysOfWeek: {
    MONDAY: false,
    TUESDAY: false,
    WEDNESDAY: false,
    THURSDAY: false,
    FRIDAY: false,
    SATURDAY: false,
    SUNDAY: false,
  },
};

export const isRecurringSchedule = deliverySchedule => {
  return deliverySchedule.frequency.value === 'RECURRING';
};

export const isRecurringCustomDateSelected = (deliverySchedule, date) => {
  return deliverySchedule.frequency.value === 'RECURRING' && date === 'CUSTOM_DATE';
};

export const isOneTimeSchedule = deliverySchedule => {
  return (
    deliverySchedule.frequency.value === 'ONE_TIME' && deliverySchedule.trigger.value === 'TIME'
  );
};

export const isEventSchedule = deliverySchedule => {
  return (
    deliverySchedule.frequency.value === 'ONE_TIME' && deliverySchedule.trigger.value === 'EVENT'
  );
};

export const isOnReferenceDateNotSelected = deliverySchedule => {
  return (
    deliverySchedule.frequency.value === 'ONE_TIME' &&
    deliverySchedule.trigger.value === 'TIME' &&
    deliverySchedule.relativeOperator.value &&
    deliverySchedule.relativeOperator.value !== 'ON' &&
    deliverySchedule.relativeDate.value
  );
};

export const isAnyDaysOfWeekChecked = deliverySchedule => {
  const daysOfWeekValues = Object.values(deliverySchedule.daysOfWeek);
  return (
    daysOfWeekValues.filter(value => {
      return value === true;
    }).length > 0
  );
};
