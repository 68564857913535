import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import { UPDATE_SURVEYS_ENDPOINT } from '../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { configureRefreshStateAction } from '../../../common/redux/configureRefreshState';
import { UPDATE_SURVEY_DATA_REFRESH_DELAY_TIME_IN_MS } from '../../../common/components/table/constants';

export const UPDATE_SURVEY_ACTION_TYPES = createAPILifecycleActionTypes(
  'UPDATE_DURATION_SURVEY_ACTION_TYPES',
);
export const updateSurveyAction = createAPILifecycleActions(UPDATE_SURVEY_ACTION_TYPES.NAME);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SURVEY_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_SURVEY_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case UPDATE_SURVEY_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case UPDATE_SURVEY_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const updateSurveyEpic = createAmplifyAPICallEpic({
  actionType: UPDATE_SURVEY_ACTION_TYPES.BEGIN,
  api: UPDATE_SURVEYS_ENDPOINT,
  successCallback: response => [
    updateSurveyAction.SUCCESS(response),
    configureRefreshStateAction.BEGIN(UPDATE_SURVEY_DATA_REFRESH_DELAY_TIME_IN_MS),
  ],
  errorCallback: () => [updateSurveyAction.FAILURE()],
});
