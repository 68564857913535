import React from 'react';
import { Button, DatePicker, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { exportDataAction } from '../../../../../common/redux/export';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../../../../../common/config/api_constants';
import REPORT_TYPES, { EXPORT_REQUEST_REPORT_TYPES } from '../../../constants/report_types';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../common/constants/study_period';
import { triggerReportRefreshAction } from '../redux/TriggerReportRefresh';
import { isReportRefreshable, REPORT_REFRESH_STATUS } from '../../../constants/api_constants';
import { getUpdateStatus } from '../../utils/UpdateStatusUtils';
import addContextInPayloadObjects from '../../utils/ApiUtils';

const ReportTableActions = ({
  reportGeneratedTill,
  setReportGeneratedTill,
  id,
  query,
  exportDataBegin,
  reportData,
  studyPeriod,
  triggerReportRefreshBegin,
  reportRefreshStatusData,
}) => {
  const reportId = get(reportData, [id, 'reportId']);
  const onExportHandler = () => {
    const type = EXPORT_REQUEST_REPORT_TYPES[id];

    exportDataBegin({
      body: {
        type,
        isJSON: false,
        query: {
          clientId: DEFAULT_BUSINESS_ID,
          country: DEFAULT_COUNTRY,
          studyPeriod,
          reportId,
          query,
        },
      },
    });
  };
  const onReportRefreshHandler = () => {
    triggerReportRefreshBegin({
      body: {
        reports: addContextInPayloadObjects(
          [
            {
              reportType: id,
            },
          ],
          studyPeriod,
        ),
      },
    });
  };
  const updateStatus = getUpdateStatus(reportRefreshStatusData, reportData, id);
  const currentTime = new Date().getTime();
  const threeDaysInEpochMillis = 259200000;
  return (
    <SpaceBetween size="s" direction="horizontal">
      {isReportRefreshable(id) && (
        <Button
          iconName="refresh"
          variant="icon"
          onClick={onReportRefreshHandler}
          disabled={
            updateStatus === REPORT_REFRESH_STATUS.InProgress ||
            isActionDisabledForStudyPeriod(studyPeriod)
          }
        >
          Refresh
        </Button>
      )}
      <DatePicker
        isDateEnabled={date =>
          date.getTime() + threeDaysInEpochMillis >= currentTime && date.getTime() < currentTime
        }
        onChange={({ detail }) => setReportGeneratedTill(detail.value)}
        value={reportGeneratedTill}
        openCalendarAriaLabel={selectedDate =>
          `Choose Date${selectedDate ? `, selected date is ${selectedDate}` : ''}`
        }
        nextMonthAriaLabel="Next month"
        placeholder="YYYY/MM/DD"
        previousMonthAriaLabel="Previous month"
        todayAriaLabel="Today"
      />
      <Button
        onClick={onExportHandler}
        variant="normal"
        disabled={
          isActionDisabledForStudyPeriod(studyPeriod) && id === REPORT_TYPES.COST_SHARE_REPORT
        }
      >
        Export
      </Button>
    </SpaceBetween>
  );
};

ReportTableActions.propTypes = {
  reportGeneratedTill: PropTypes.string.isRequired,
  setReportGeneratedTill: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  query: PropTypes.string.isRequired,
  reportData: PropTypes.object.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  triggerReportRefreshBegin: PropTypes.func.isRequired,
  reportRefreshStatusData: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    reportData: get(state, 'entities.reporting.data', null) || null,
    studyPeriod: getStudyPeriod(state),
    reportRefreshStatusData: get(state, 'entities.getReportRefreshStatus.data', {}),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportDataBegin: exportDataAction.BEGIN,
      triggerReportRefreshBegin: triggerReportRefreshAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportTableActions);
