import Pagination from '@amzn/awsui-components-react/polaris/pagination';
import PropTypes from 'prop-types';
import React from 'react';

const TPagination = props => {
  const { currentPageIndex, pagesCount, setCurrentPageIndex, openEnd } = props;

  return (
    <Pagination
      currentPageIndex={currentPageIndex}
      onChange={({ detail: { currentPageIndex: newPageIndex } }) =>
        setCurrentPageIndex(newPageIndex)
      }
      pagesCount={pagesCount}
      openEnd={openEnd}
    />
  );
};

TPagination.propTypes = {
  currentPageIndex: PropTypes.number.isRequired,
  pagesCount: PropTypes.number.isRequired,
  setCurrentPageIndex: PropTypes.func.isRequired,
  openEnd: PropTypes.bool.isRequired,
};

export default TPagination;
