import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { getActivityDescription } from '../../../utils/DataUtils';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
});

export function ActivityDescription({ data, surveyType }) {
  const { section } = styles;

  return (
    <View style={section}>
      <Text>{getActivityDescription(data, surveyType)}</Text>
    </View>
  );
}

ActivityDescription.propTypes = {
  data: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
};

export default ActivityDescription;
