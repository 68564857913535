import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import {
  ITEM_TYPE_CONTRACT,
  ITEM_TYPE_LABOR,
} from '../../../../../../../common/config/api_constants';
import ChooseReason from '../components/components/ChooseReason';
import ChoosePopulation from '../components/components/ChoosePopulation';

export const getReasonsForOverride = (
  itemType,
  Q_OVERRIDE_REASONS,
  setSelectedReasonForQ,
  selectedReasonForQ,
) => {
  switch (itemType) {
    case ITEM_TYPE_CONTRACT:
      return null;
    case ITEM_TYPE_LABOR:
    default:
      return (
        <ChooseReason
          REASONS={Q_OVERRIDE_REASONS}
          selectedReason={selectedReasonForQ}
          setSelectedReason={setSelectedReasonForQ}
        />
      );
  }
};

export const getReasonsForNQOverride = (
  itemType,
  NQ_OVERRIDE_REASONS,
  selectedReasonForNQ,
  setSelectedReasonForNQ,
) => {
  switch (itemType) {
    case ITEM_TYPE_CONTRACT:
      return null;
    case ITEM_TYPE_LABOR:
    default:
      return (
        <Grid gridDefinition={[{ colspan: 6 }]}>
          <ChooseReason
            REASONS={NQ_OVERRIDE_REASONS}
            selectedReason={selectedReasonForNQ}
            setSelectedReason={setSelectedReasonForNQ}
          />
        </Grid>
      );
  }
};

export const getPopulationForQOverride = (
  itemType,
  POPULATIONS,
  selectedPopulation,
  setSelectedPopulation,
) => {
  switch (itemType) {
    case ITEM_TYPE_CONTRACT:
      return null;
    case ITEM_TYPE_LABOR:
    default:
      return (
        <ChoosePopulation
          POPULATION={POPULATIONS}
          selectedPopulation={selectedPopulation}
          setSelectedPopulation={setSelectedPopulation}
        />
      );
  }
};
