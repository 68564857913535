import Modal from '@amzn/awsui-components-react/polaris/modal';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import Button from '@amzn/awsui-components-react/polaris/button';
import { TextContent, Tiles } from '@amzn/awsui-components-react';
import { values } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { QUALIFICATIONS } from '../../../constants/update_selected_constants';
import ModalContent from './ModalContent';

const ModalSkelton = ({
  isVisible,
  onDismiss,
  onCancelHandler,
  onConfirmHandler,
  selectedEmployees,
  getEntityTypeText,
  setIsQualified,
  content,
  selectedQualification,
  isModalSubmittable,
}) => {
  return (
    <Modal
      visible={isVisible}
      onDismiss={onDismiss}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler} disabled={!isModalSubmittable}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Update selected"
    >
      <SpaceBetween size="m">
        <TextContent>
          <p>
            {selectedEmployees} selected {getEntityTypeText()} will be updated.
          </p>
        </TextContent>
        <Box>
          <TextContent>
            <p>Qualification</p>
          </TextContent>
          <Tiles
            onChange={({ detail }) => setIsQualified(detail.value)}
            value={selectedQualification}
            items={values(QUALIFICATIONS)}
          />
        </Box>
        {content}
      </SpaceBetween>
    </Modal>
  );
};

ModalSkelton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onCancelHandler: PropTypes.func.isRequired,
  onConfirmHandler: PropTypes.func.isRequired,
  selectedEmployees: PropTypes.number.isRequired,
  getEntityTypeText: PropTypes.func.isRequired,
  setIsQualified: PropTypes.func.isRequired,
  content: PropTypes.element.isRequired,
  selectedQualification: PropTypes.string.isRequired,
  isModalSubmittable: PropTypes.bool.isRequired,
};

ModalContent.defaultProps = {
  selectedReason: null,
  selectedSMESurvey: null,
  areSurveysConfigured: false,
};

export default ModalSkelton;
