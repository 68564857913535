const authReducer = (state = { authState: null }, action) => {
  switch (action.type) {
    case 'SET_AUTH_STATE':
      return { ...state, authState: action.payload.authState };
    default:
      return state;
  }
};

export default authReducer;
