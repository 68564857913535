import { Box, Button, Container } from '@amzn/awsui-components-react';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { get, includes, size } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import qs from 'qs';
import { connect, useDispatch } from 'react-redux';
import './App.css';
import { useHistory } from 'react-router-dom';
import { keys } from 'lodash/object';
import awsAuthConfig from './common/config/amplify.auth';
import amplifyConfig from './common/config/amplify.config';
import { setAuthState } from './redux/actions/authActions';
import Routes from './Routes';

Amplify.configure(amplifyConfig);
Auth.configure({ oauth: awsAuthConfig });

const AUTH_STATES = {
  SIGNED_IN: 'signedIn',
  SIGN_IN_ONGOING: 'signIn_OnGoing',
  SIGNED_OUT: null,
};

const isSignInOngoing = location => {
  const queryParamKeys = keys(qs.parse(get(location, 'search'), { ignoreQueryPrefix: true }));
  return (
    size(queryParamKeys) === 2 &&
    includes(queryParamKeys, 'code') &&
    includes(queryParamKeys, 'state')
  );
};

const App = props => {
  const dispatch = useDispatch();
  const { location } = useHistory();

  if (isSignInOngoing(location)) {
    dispatch(setAuthState(AUTH_STATES.SIGN_IN_ONGOING));
  }

  useEffect(() => {
    Auth.currentSession()
      .then(() => dispatch(setAuthState(AUTH_STATES.SIGNED_IN)))
      .catch(() => dispatch(setAuthState(AUTH_STATES.SIGNED_OUT)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
        case 'cognitoHostedUI': {
          dispatch(setAuthState(AUTH_STATES.SIGNED_IN));
          break;
        }
        case 'signOut':
        case 'signIn_failure':
        case 'cognitoHostedUI_failure': {
          dispatch(setAuthState(AUTH_STATES.SIGNED_OUT));
          break;
        }
        default:
          break;
      }
    });
  });

  const getContent = () => {
    const { authState } = props;

    if (authState === AUTH_STATES.SIGNED_IN) {
      return <Routes />;
    }

    return (
      <Container
        header={
          <Box textAlign="center" variant="h1">
            Welcome
          </Box>
        }
        className="sign-in-container"
      >
        <Box textAlign="center">
          <Button
            variant="primary"
            loading={authState === AUTH_STATES.SIGN_IN_ONGOING}
            disabled={authState === AUTH_STATES.SIGN_IN_ONGOING}
            onClick={() => Auth.federatedSignIn()}
          >
            Sign In to Radical
          </Button>
        </Box>
      </Container>
    );
  };

  return <div className="app">{getContent()}</div>;
};

App.propTypes = {
  authState: PropTypes.string,
};

App.defaultProps = {
  authState: null,
};

const mapStateToProps = state => ({
  authState: get(state, 'auth.authState', null),
});

export default connect(mapStateToProps)(App);
