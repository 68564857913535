import { Select, TextContent } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import React from 'react';
import PropTypes from 'prop-types';

const ChoosePopulation = ({ POPULATION, selectedPopulation, setSelectedPopulation }) => {
  return (
    <Box>
      <TextContent>
        <p>Choose the population</p>
      </TextContent>
      <Select
        placeholder="Select One"
        selectedOption={selectedPopulation}
        onChange={({ detail }) => setSelectedPopulation(detail.selectedOption)}
        options={POPULATION}
      />
    </Box>
  );
};

ChoosePopulation.propTypes = {
  POPULATION: PropTypes.array.isRequired,
  selectedPopulation: PropTypes.string,
  setSelectedPopulation: PropTypes.func.isRequired,
};

ChoosePopulation.defaultProps = {
  selectedPopulation: null,
};

export default ChoosePopulation;
