import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import ProjectsTable from './projects_table/components/ProjectsTable';

const ProjectsTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
      <div>
        <ProjectsTable />
      </div>
    </Grid>
  );
};

export default ProjectsTab;
