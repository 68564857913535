import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { SurveyEmployeeSection } from './components/SurveyEmployeeSection';
import { SurveySecondaryAssigneeSection } from './components/SurveySecondaryAssigneeSection';

export function SurveyEmployeeListSection({ data }) {
  return (
    <View>
      <Heading name="Employee" />
      <SurveyEmployeeSection data={data} />
      <SurveySecondaryAssigneeSection data={data} />
    </View>
  );
}

SurveyEmployeeListSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyEmployeeListSection;
