import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../../common/components/table/constants';

import CCAction from '../components/CCAction';

const EmptyTableView = () => <p>No cc.</p>;

const ccTableConfig = {
  dataTrackingId: 'esKey',
  dataLoadingText: 'Loading CCs',
  emptyElement: EmptyTableView,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  multiSelectConfig: { selectionType: 'multi' },
  headerConfig: {
    title: 'CC',
    Actions: CCAction,
  },
  // TODO : This needs to be dynamic
  getDataRequestBody: () => {
    return {
      isReceiverTypeRecipient: false,
    };
  },
};
export default ccTableConfig;
