import { get, isEmpty, isNull } from 'lodash';
import isString from 'lodash/isString';
import isEqual from 'lodash/isEqual';
import { SURVEY_STATUS_DEACTIVATED } from '../../../constants/update_selected_constants';
import {
  ITEM_TYPE_CONTRACT,
  ITEM_TYPE_LABOR,
} from '../../../../../../../common/config/api_constants';

const isModalSubmittable = ({ state, itemType }) => {
  const selectedQualification = get(state, 'selectedQualification');
  if (!isString(selectedQualification)) {
    return false;
  }

  if (isEqual(selectedQualification, 'NQ')) {
    if (isEqual(itemType, ITEM_TYPE_CONTRACT)) {
      return true;
    }
    if (isNull(get(state, 'selectedReasonForNQ', null))) {
      return false;
    }
    return true;
  }

  if (isEqual(selectedQualification, 'Q')) {
    if (isEqual(itemType, ITEM_TYPE_LABOR)) {
      if (
        isEmpty(get(state, 'selectedSME', {})) ||
        isNull(get(state, 'selectedReasonForQ', null)) ||
        isNull(get(state, 'selectedPopulation', null))
      ) {
        return false;
      }
    }
    if (isEqual(itemType, ITEM_TYPE_CONTRACT)) {
      if (isEmpty(get(state, 'selectedSME', {}))) {
        return false;
      }
    }
    if (
      isEqual(get(state, 'areSurveysConfigured', true), true) &&
      isNull(get(state, 'selectedSMESurvey', null))
    ) {
      return false;
    }
    if (get(state, 'selectedSMESurvey.description') === SURVEY_STATUS_DEACTIVATED) return false;
  }
  return true;
};

export default isModalSubmittable;
