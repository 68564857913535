import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tiles } from '@amzn/awsui-components-react';
import FileUpload from '../../../../../../common/components/file_uploader/FileUploader';

const UpdateAllModal = props => {
  const [value, setValue] = React.useState('update');
  const { isVisible, onDismiss, onConfirm, onCancel } = props;
  const headerMap = {
    update: 'Update all',
    import: 'Update SME via import',
  };

  const initialState = {
    form: {
      description: null,
      file: null,
    },
  };

  const [state, setState] = useState(initialState);

  const onCancelHandler = () => {
    onCancel();
    setState(initialState);
  };

  const onConfirmHandler = () => {
    setState({ ...state, value });
    onConfirm(state);
    setState(initialState);
  };

  const Content = () => {
    switch (value) {
      case 'update':
        return (
          <Box variant="p">
            Grouping will be automatically updated based on the latest population data.
          </Box>
        );
      case 'import':
        return (
          <SpaceBetween size="l">
            <Box variant="p">Grouping will be updated based on the given file below:</Box>
            <FileUpload setFileState={setState} state={state} />
          </SpaceBetween>
        );
      default:
        return <div />;
    }
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={headerMap[value]}
    >
      <SpaceBetween size="l">
        <Tiles
          onChange={({ detail }) => setValue(detail.value)}
          value={value}
          items={[
            {
              label: 'Update automatically',
              description: 'Update based on the latest population data',
              value: 'update',
            },
            {
              label: 'Import',
              description: 'Update based on the imported file',
              value: 'import',
            },
          ]}
        />

        <Content />
      </SpaceBetween>
    </Modal>
  );
};

UpdateAllModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UpdateAllModal;
