import Box from '@amzn/awsui-components-react/polaris/box';
import PieChart from '@amzn/awsui-components-react/polaris/pie-chart';
import PropTypes from 'prop-types';
import * as React from 'react';
import { datumValuePrefix, getData, getDetailPopoverContent } from './utils';

const StudySummaryPieChart = ({
  aggregation,
  aggTypes,
  viewType,
  emptyView,
  isLoading,
  dataValueSuffix,
  dataValuePrefix,
}) => {
  return (
    <Box className="standard-border" margin={{ top: 'l' }} padding="l">
      <PieChart
        data={getData({ aggregation, aggTypes })}
        detailPopoverContent={getDetailPopoverContent(viewType)}
        segmentDescription={datumValuePrefix(viewType, dataValuePrefix, dataValueSuffix)}
        errorText="Error loading data."
        loadingText="Loading chart"
        size="medium"
        hideFilter
        empty={emptyView}
        statusType={isLoading ? 'loading' : 'finished'}
      />
    </Box>
  );
};

StudySummaryPieChart.propTypes = {
  aggregation: PropTypes.object,
  aggTypes: PropTypes.object,
  viewType: PropTypes.string,
  emptyView: PropTypes.element,
  isLoading: PropTypes.bool,
  dataValueSuffix: PropTypes.string,
  dataValuePrefix: PropTypes.string,
};

StudySummaryPieChart.defaultProps = {
  aggregation: {},
  aggTypes: {},
  viewType: 'number',
  emptyView: (
    <Box textAlign="center" color="inherit">
      <b>No data available</b>
    </Box>
  ),
  isLoading: false,
  dataValueSuffix: '',
  dataValuePrefix: '',
};

export default StudySummaryPieChart;
