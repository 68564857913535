import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { TRIGGER_IMPORT } from '../../../common/config/api_endpoints';

export const TRIGGER_IMPORT_ACTION_TYPES = createAPILifecycleActionTypes(
  'TRIGGER_IMPORT_ACTION_TYPES',
);
export const triggerImportAction = createAPILifecycleActions(TRIGGER_IMPORT_ACTION_TYPES.NAME);

const initialState = {
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TRIGGER_IMPORT_ACTION_TYPES.BEGIN:
      return state;
    case TRIGGER_IMPORT_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
      };
    case TRIGGER_IMPORT_ACTION_TYPES.FAILURE:
      return {
        ...state,
        data: null,
        error: payload,
      };
    case TRIGGER_IMPORT_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const triggerImportEpic = createAmplifyAPICallEpic({
  actionType: TRIGGER_IMPORT_ACTION_TYPES.BEGIN,
  api: TRIGGER_IMPORT,
  successCallback: response => [triggerImportAction.SUCCESS(response)],
  errorCallback: () => [triggerImportAction.FAILURE()],
});
