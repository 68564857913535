import { get, isEmpty, set } from 'lodash';
import { join } from 'lodash/array';
import map from 'lodash/map';
import size from 'lodash/size';
import {
  GET_ACTIVITIES_ENDPOINT,
  GET_EMPLOYEES_FOR_SURVEY_ENDPOINT,
  GET_PROJECT_DOCUMENTS,
  GET_VENDORS_FOR_SURVEY_ENDPOINT,
  SEARCH_POPULATION_ENDPOINT,
  SEARCH_PROJECTS_QNQ_ENDPOINT,
  VALARI_API_NAME,
} from '../../../../../config/api_endpoints';
import postWithContext from '../../../../../utils/api_util';
import { ITEM_TYPE_LABOR } from '../../../../../config/api_constants';
import { getSerializedData } from '../../../../table/utils/table_redux_payload_utils';

export const SURVEY_TYPE = {
  LABOR: 'Labor',
  CONTRACT: 'Contract',
};

const parser = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const getSurveyType = data => {
  if (get(data, 'surveyType', ' ') === SURVEY_TYPE.CONTRACT) {
    return SURVEY_TYPE.CONTRACT;
  }
  return SURVEY_TYPE.LABOR;
};

export const getProjectDescription = (data, surveyType) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    default:
      return `Select all projects you and/or your employees worked on in
       ${data.studyPeriod}. You can add project(s) if you cannot find your 
       team’s project(s) in the list.`;

    case SURVEY_TYPE.CONTRACT:
      return `Select all projects vendors worked on in ${data.studyPeriod}. 
      You can add project(s) if you cannot find in the list.`;
  }
};

export const getActivityDescription = (data, surveyType) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    default:
      return `Select all activities you and/or your employees worked 
      on in ${data.studyPeriod}. You can add activities if you 
      cannot find your team’s activities in the list.`;

    case SURVEY_TYPE.CONTRACT:
      return `Select all activities vendors worked on in ${data.studyPeriod}.
         You can add activities if you cannot find in the list.`;
  }
};

const transformReceivedEmployees = ({ employeeAlias, employeeName, jobTitle }) => {
  return { employeeAlias, employeeName, jobTitle };
};

const getSecondaryAssigneeDetails = async (employees, studyPeriod) => {
  let secondaryAssigneeDetails = [];
  const query = join(
    map(employees, employee => {
      return `recordId equals ${employee}`;
    }),
    ' || ',
  );

  if (size(employees) > 0) {
    await postWithContext(VALARI_API_NAME, SEARCH_POPULATION_ENDPOINT, {
      body: {
        itemType: ITEM_TYPE_LABOR,
        pageSize: 20,
        pageNumber: 1,
        query,
        studyPeriod,
      },
    }).then(r => {
      secondaryAssigneeDetails = map(getSerializedData(get(r, 'body.data', [])), res =>
        transformReceivedEmployees(res),
      );
    });
  }

  return secondaryAssigneeDetails;
};

const getAdditionalSurveyData = async (item, searchProjectQuery) => {
  const promises = [
    postWithContext(VALARI_API_NAME, GET_ACTIVITIES_ENDPOINT, {
      body: {
        surveyType: item.surveyType,
        studyPeriod: item.studyPeriod,
      },
    }),
    postWithContext(VALARI_API_NAME, GET_EMPLOYEES_FOR_SURVEY_ENDPOINT, {
      body: {
        surveyId: item.surveyId,
        studyPeriod: item.studyPeriod,
      },
    }),
    postWithContext(VALARI_API_NAME, GET_VENDORS_FOR_SURVEY_ENDPOINT, {
      body: {
        surveyId: item.surveyId,
        studyPeriod: item.studyPeriod,
      },
    }),
    postWithContext(VALARI_API_NAME, GET_PROJECT_DOCUMENTS, {
      body: {
        projectIdList: map(
          get(parser(get(item, 'userResponse', '{}')), 'Project.data.selectedProjects', []),
          ({ id }) => id,
        ),
        surveyId: item.surveyId,
        studyPeriod: item.studyPeriod,
      },
    }),
  ];
  if (!isEmpty(searchProjectQuery)) {
    promises.push(
      postWithContext(VALARI_API_NAME, SEARCH_PROJECTS_QNQ_ENDPOINT, {
        body: {
          studyPeriod: item.studyPeriod,
          query: searchProjectQuery,
        },
      }),
    );
  }
  const response = await Promise.all(promises)
    .then(r => {
      return r;
    })
    .catch(() => {
      return [];
    });

  return response;
};

const parseProjectData = data => {
  const projectData = {};
  JSON.parse(data).forEach(project => {
    set(projectData, project.projectId, project);
  });
  return projectData;
};

const getSurveyData = async item => {
  const userResponse = parser(get(item, 'userResponse', {}));
  const selectedProjects = get(userResponse, 'Project.data.selectedProjects', []);
  let searchProjectQuery = '';
  selectedProjects.forEach(({ id }) => {
    searchProjectQuery += `projectId equals ${id} || `;
  });
  const response = await getAdditionalSurveyData(item, searchProjectQuery);
  const secondaryAssigneeDetails = await getSecondaryAssigneeDetails(
    getSerializedData(get(item, 'serializedSecondaryAssignees', [])),
    item.studyPeriod,
  );
  if (isEmpty(response)) {
    return null;
  }
  return {
    ...item,
    activityList: get(response, [0, 'body', 'activityList'], []),
    userResponse,
    employeeData: parser(get(response, [1, 'employeeData'], [])),
    vendorData: parser(get(response, [2, 'vendorData'], [])),
    projectDocumentation: parser(get(response[3], 'body.projectIdToDocumentDataMap', '{}')),
    secondaryAssigneeDetails,
    searchProjectsResponse: parseProjectData(get(response, [4, 'body', 'data'], '[]')),
  };
};

export default getSurveyData;
