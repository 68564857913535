import Box from '@amzn/awsui-components-react/polaris/box';
import Table from '@amzn/awsui-components-react/polaris/table';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useEffect } from 'react';

const AggregationTable = ({
  aggId,
  aggText,
  aggregation,
  getItems,
  getColumnDefinitions,
  setSelectedAggBucket,
  isLoading,
}) => {
  const [selectedItems, setSelectedItems] = React.useState([]);

  useEffect(() => {
    setSelectedItems([]);
  }, [aggId]);

  const onSelectionChange = newSelectedItems => {
    setSelectedItems(newSelectedItems);
    setSelectedAggBucket(get(newSelectedItems, [0, 'bucket']));
  };

  return (
    <Table
      trackBy="bucket"
      columnDefinitions={getColumnDefinitions({ aggId, aggText })}
      onSelectionChange={({ detail }) => onSelectionChange(detail.selectedItems)}
      selectedItems={selectedItems}
      items={getItems({ aggId, aggregation })}
      selectionType="single"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No aggregation buckets</b>
        </Box>
      }
      loadingText="Loading aggregation buckets"
      loading={isLoading}
    />
  );
};

AggregationTable.propTypes = {
  aggId: PropTypes.string.isRequired,
  aggText: PropTypes.string.isRequired,
  aggregation: PropTypes.object.isRequired,
  getItems: PropTypes.func.isRequired,
  getColumnDefinitions: PropTypes.func.isRequired,
  setSelectedAggBucket: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

AggregationTable.defaultProps = {
  isLoading: false,
};

export default AggregationTable;
