import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import CCTable from './components/cc_table/components/CCTable';

const CCTab = ({ notificationId }) => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <CCTable notificationId={notificationId} />
      </div>
    </Grid>
  );
};
CCTab.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default CCTab;
