import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { GET_IMPORT_METADATA } from '../../../common/config/api_endpoints';

export const GET_IMPORT_METADATA_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_IMPORT_METADATA_ACTION_TYPES',
);
export const getImportMetadataAction = createAPILifecycleActions(
  GET_IMPORT_METADATA_ACTION_TYPES.NAME,
);

const initialState = {
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_IMPORT_METADATA_ACTION_TYPES.BEGIN:
      return state;
    case GET_IMPORT_METADATA_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
      };
    case GET_IMPORT_METADATA_ACTION_TYPES.FAILURE:
      return {
        ...state,
        data: null,
        error: payload,
      };
    case GET_IMPORT_METADATA_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const getImportMetadataEpic = createAmplifyAPICallEpic({
  actionType: GET_IMPORT_METADATA_ACTION_TYPES.BEGIN,
  api: GET_IMPORT_METADATA,
  successCallback: response => [getImportMetadataAction.SUCCESS(response)],
  errorCallback: () => [getImportMetadataAction.FAILURE()],
});
