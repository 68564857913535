import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../common/components/table/constants';
import { ITEM_TYPE_LABOR } from '../../../../../../../common/config/api_constants';
import LaborActions from '../components/LaborActions';
import { getImportStatusHeaderDescriptionForGroupingPage } from '../../../../../../import_data/utils/import_utils';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../../import_data/constants/import_types';

const EmptyTableView = () => <p>No Employees.</p>;

export default ({ importTypeMetadata, data }) => {
  return {
    dataTrackingId: 'partitionKey',
    dataLoadingText: 'Loading employees.',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Grouping',
      description: getImportStatusHeaderDescriptionForGroupingPage(importTypeMetadata, data),
      Actions: LaborActions,
      importTypeMetadata,
    },
    // TODO : This needs to be dynamic
    getDataRequestBody: () => {
      return {
        itemType: ITEM_TYPE_LABOR,
      };
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes: SUPPORTED_IMPORT_TYPES.LABOR_GROUPING,
        },
      };
    },
  };
};
