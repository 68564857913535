import { set } from 'lodash';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import { CONFIGURE_SURVEYS_ENDPOINT } from '../../../../common/config/api_endpoints';

export const CONFIGURE_SURVEYS_ACTION_TYPES = createAPILifecycleActionTypes(
  'CONFIGURE_SURVEYS_ACTION_TYPES',
);
export const configureSurveysAction = createAPILifecycleActions(
  CONFIGURE_SURVEYS_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIGURE_SURVEYS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case CONFIGURE_SURVEYS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case CONFIGURE_SURVEYS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case CONFIGURE_SURVEYS_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const payloadGenerator = async ({ originalPayload }) => {
  const newPayload = {};
  set(newPayload, ['body'], originalPayload.body);
  return newPayload;
};

const successCallback = (response, store, action) => {
  const originalPayload = action.payload;
  const { onSuccess } = originalPayload;
  if (response.status === 200) {
    onSuccess();
  }
  return [configureSurveysAction.SUCCESS(response)];
};

export const configureSurveysEpic = createAmplifyAPICallEpic({
  actionType: CONFIGURE_SURVEYS_ACTION_TYPES.BEGIN,
  payloadGenerator,
  api: CONFIGURE_SURVEYS_ENDPOINT,
  successCallback,
  errorCallback: () => [configureSurveysAction.FAILURE()],
});
