import { createAction } from 'redux-actions';

export const CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES = {
  NAME: 'CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES',
  ADD: 'CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES_ADD',
  DELETE: 'CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES_DELETE',
  FAILURE: 'CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES_FAILURE',
  CLEAR: 'CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES_CLEAR',
};
export const configureAttachmentStateAction = {
  ADD: createAction(CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.ADD),
  DELETE: createAction(CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.DELETE),
  FAILURE: createAction(CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.FAILURE),
  CLEAR: createAction(CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.CLEAR),
};

const deleteAttachment = (attachments, attachmentId) => {
  const currentAttachments = [];
  // eslint-disable-next-line array-callback-return
  attachments.map(attachment => {
    if (attachment.id === attachmentId) {
      currentAttachments.push({
        ...attachment,
        active: false,
      });
    } else {
      currentAttachments.push(attachment);
    }
  });
  return currentAttachments;
};

const initialState = {
  attachments: [],
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.ADD: {
      const prevState = state.attachments;
      return {
        ...state,
        attachments: [...prevState, payload.body],
      };
    }
    case CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.DELETE: {
      const prevAttachment = state.attachments;
      return {
        ...state,
        attachments: deleteAttachment(prevAttachment, payload),
      };
    }
    case CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.FAILURE:
      return state;
    case CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};
