import { get, set } from 'lodash';
import { createAction } from 'redux-actions';
import createAmplifyAPICallEpic from '../../../../../redux/epics/utils';
import { GET_DOCUMENT_UPLOAD_PRESIGNED_URL } from '../../../../config/api_endpoints';
import { putFileInS3Action } from './PutFileInS3';

const GET_DOCUMENT_UPLOAD_PRESIGNED_URL_ACTION_NAME = 'GET_DOCUMENT_UPLOAD_PRESIGNED_URL_ACTION';
export const getDocumentUploadPresignedURLAction = createAction(
  GET_DOCUMENT_UPLOAD_PRESIGNED_URL_ACTION_NAME,
);

const errorCallback = (response, store, action) => {
  const { onUploadFailure } = action.payload;
  const { action: onUploadFailureAction } = onUploadFailure;
  return [onUploadFailureAction()];
};

const successCallback = (response, store, action) => {
  if (response !== null && response.body !== null) {
    const { documentFile, onUploadSuccess, onUploadFailure } = action.payload;
    const { documentId, presignedUrl } = response.body;

    const documentIdentifier = get(onUploadSuccess, ['documentIdentifier']);
    set(onUploadSuccess, ['payload', 'body', documentIdentifier], documentId);

    const uploadToS3Payload = { documentId, presignedUrl, documentFile };

    return [putFileInS3Action({ uploadToS3Payload, onUploadSuccess, onUploadFailure })];
  }
  return errorCallback(response, store, action);
};

export const getDocumentUploadPresignedURLEpic = createAmplifyAPICallEpic({
  actionType: GET_DOCUMENT_UPLOAD_PRESIGNED_URL_ACTION_NAME,
  api: GET_DOCUMENT_UPLOAD_PRESIGNED_URL,
  successCallback,
  errorCallback,
});
