import { get, map } from 'lodash';

const getTriggerTypeValue = deliverySchedule => {
  const frequencyType = get(deliverySchedule, 'frequency.value', null);
  let triggerType;
  if (frequencyType === 'RECURRING') {
    triggerType = 'TIME';
  } else {
    triggerType = get(deliverySchedule, 'trigger.value', null);
  }
  return triggerType;
};

const extractSelectedDays = daysOfWeekObj => {
  const arr = [];
  Object.keys(daysOfWeekObj).forEach(key => {
    if (daysOfWeekObj[key] === true) {
      arr.push(key);
    }
  });
  return arr;
};

const createDeliverySchedulePayload = deliverySchedule => {
  return {
    frequency: get(deliverySchedule, 'frequency.value', null),
    trigger: getTriggerTypeValue(deliverySchedule),
    schedule: {
      relativeStartDate: get(deliverySchedule, 'relativeStartDate.value', null),
      relativeStartDateValue: get(deliverySchedule, 'relativeStartDateValue', null),
      relativeEndDate: get(deliverySchedule, 'relativeEndDate.value', null),
      relativeEndDateValue: get(deliverySchedule, 'relativeEndDateValue', null),
      temporalDataList: extractSelectedDays(deliverySchedule.daysOfWeek),
      relativeOperator: get(deliverySchedule, 'relativeOperator.value', null),
      relativeDate: get(deliverySchedule, 'relativeDate.value', null),
      temporalUnit: get(deliverySchedule, 'temporalUnit.value', null),
      temporalData: get(deliverySchedule, 'temporalData', null),
      event: get(deliverySchedule, 'triggeringEvent.value', null),
    },
  };
};

const createNotificationPayload = (
  notificationId,
  detailComponentState,
  attachments,
  deliverySchedule,
) => {
  return {
    notificationId,
    notificationName: get(detailComponentState, 'name', null),
    templateId: get(detailComponentState, 'template.value', null),
    recipient: get(detailComponentState, 'recipient.value', null),
    ccList: map(get(detailComponentState, 'selectedCCList', []), 'value'),
    applicableTo: get(detailComponentState, 'applicableTo.value', null),
    applicableCostCenter: {
      operation: get(detailComponentState, 'applicableCCOperation.value', null),
      costCenterIdentifiers: map(
        get(detailComponentState, 'selectedCostCenterIdentifier', []),
        ({ label, value }) => {
          return { identifier: value, name: label };
        },
      ),
    },
    attachments,
    frequencyType: get(deliverySchedule, 'frequency.value', null),
    triggerType: getTriggerTypeValue(deliverySchedule),
    deliverySchedule: JSON.stringify(createDeliverySchedulePayload(deliverySchedule)),
    applicableEntitySection: [get(detailComponentState, 'selectedApplicableEntitySection.value', null)]
  };
};

export default createNotificationPayload;
