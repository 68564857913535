import PropTypes from 'prop-types';
import * as React from 'react';
import get from 'lodash/get';
import { map, mergeWith } from 'lodash';
import StudySummaryDetails from '../../../common/study_summary_details/StudySummaryDetails';
import { AGGREGATION_ITEMS, AGGREGATIONS_IDS } from '../../constants/aggregations';
import { getItems, getTableColumnDefinitions } from './utils';
import QRE_SUM_AGG_TYPES from '../../constants/qreTypes';
import { aggregationsMergeFun } from '../../../common/utils/aggregation_utils';

const QREDetails = ({ qreStudySummary, isQREStudySummaryLoading, selectedStatuses }) => {
  const getMergedAggregation = () => {
    const selectedStatusesAggregations = map(selectedStatuses, selectedStatus =>
      get(qreStudySummary, ['aggByStatus', selectedStatus], {}),
    );
    return mergeWith({}, ...selectedStatusesAggregations, aggregationsMergeFun);
  };

  return (
    <StudySummaryDetails
      heading="QRE"
      dataValuePrefix="$"
      aggregation={getMergedAggregation()}
      isAggregationLoading={isQREStudySummaryLoading}
      initialSelectedAggId={AGGREGATIONS_IDS.BY_STATE}
      aggItems={AGGREGATION_ITEMS}
      aggTypes={QRE_SUM_AGG_TYPES}
      getItems={getItems}
      getColumnDefinitions={getTableColumnDefinitions}
      yTile="QRE"
    />
  );
};

QREDetails.propTypes = {
  qreStudySummary: PropTypes.object,
  isQREStudySummaryLoading: PropTypes.bool,
  selectedStatuses: PropTypes.array.isRequired,
};

QREDetails.defaultProps = {
  qreStudySummary: {},
  isQREStudySummaryLoading: false,
};
export default QREDetails;
