import { set } from 'lodash';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../../redux/actions/action_utils';
import { CANCEL_SCHEDULED_NOTIFICATION } from '../../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../../redux/epics/utils';

export const CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES = createAPILifecycleActionTypes(
  'CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES',
);
export const cancelScheduledNotificationAction = createAPILifecycleActions(
  CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const payloadGenerator = async ({ originalPayload }) => {
  const newPayload = {};
  set(newPayload, ['body'], originalPayload.body);
  return newPayload;
};

const successCallback = (response, store, action) => {
  const originalPayload = action.payload;
  const { onSuccess } = originalPayload;
  if (response.status === 200) {
    onSuccess(response.body.status);
  }
  return [cancelScheduledNotificationAction.SUCCESS(response)];
};

export const cancelScheduledNotificationEpic = createAmplifyAPICallEpic({
  actionType: CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.BEGIN,
  payloadGenerator,
  api: CANCEL_SCHEDULED_NOTIFICATION,
  successCallback,
  errorCallback: () => [cancelScheduledNotificationAction.FAILURE()],
});
