import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import { isNumber, isObject, mergeWith } from 'lodash';
import { AGGREGATIONS_IDS } from '../../surveys_tab/constants/aggregations';
import { getFormattedDate } from '../../surveys_tab/components/survey_status_details/utils';

export const getAggregationLabel = (aggId, aggItems) => {
  const { label } = find(aggItems, ({ id }) => isEqual(aggId, id)) || {};
  return label;
};

export const getAggregationLabelValue = (aggId, selectAggBucket) => {
  return isEqual(aggId, AGGREGATIONS_IDS.BY_DATE)
    ? getFormattedDate(selectAggBucket)
    : selectAggBucket;
};

export const getAggregationSelectorText = (aggId, aggItems) => {
  const { aggSelectorText } = find(aggItems, ({ id }) => isEqual(aggId, id)) || {};
  return aggSelectorText;
};

export const getAggregationSelectorItems = aggItems => {
  return map(aggItems, ({ id, aggSelectorText }) => {
    return { id, text: aggSelectorText };
  });
};

export const aggregationsMergeFun = (objVal, srcVal) => {
  if (isNumber(objVal)) {
    return objVal + srcVal;
  }
  if (isObject(objVal)) {
    return mergeWith({}, objVal, srcVal, aggregationsMergeFun);
  }
  return srcVal;
};
