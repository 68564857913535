import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { filter, get, has, isEmpty, map } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { lowerCase } from 'lodash/string';
import { isEqual } from 'lodash/lang';
import { searchLaborPopulationActions } from '../../../../../population/components/labor_tab/components/employees_table/redux/population';
import { ITEM_TYPE_CONTRACT, ITEM_TYPE_LABOR } from '../../../../../../common/config/api_constants';
import { searchSurveysAction } from '../../../../../survey/redux/surveys';
import ModalSkelton from './components/ModalSkelton';
import ModalContent from './components/ModalContent';
import isModalSubmittable from './utils/utils';
import { SURVEY_STATUS_SUBMITTED } from '../../constants/update_selected_constants';

const UpdateSelectedModal = ({
  itemType,
  entityType,
  selectedEmployees,
  isFetchingData,
  isVisible,
  onDismiss,
  onConfirm,
  onCancel,
  getDataBegin,
  data,
  qOverrideReasons,
  nqOverrideReasons,
  surveys,
  isFetchingSurveys,
  searchSurveys,
  clearSurveys,
  applicableEntitySections,
}) => {
  const initialState = {
    searchBy: 'employeeAlias',
    selectedQualification: 'Q',
    selectedSME: {},
    selectedSMESurvey: null,
    pageNumber: 1,
    selectedReasonForQ: null,
    selectedReasonForNQ: null,
    status: 'pending',
    filterText: '',
    selectedPopulation: null,
    // areSurveysConfigured = This will get added later
  };
  const [state, setState] = useState(initialState);

  const getApplicableSurveyType = () => {
    if (isEqual(itemType, ITEM_TYPE_LABOR)) {
      return 'Labor';
    }
    if (isEqual(itemType, ITEM_TYPE_CONTRACT)) {
      return 'Contract';
    }

    return '';
  };

  useEffect(() => {
    searchSurveys({
      body: {
        pageNumber: 1,
        pageSize: 10,
        query: `surveyType equals ${getApplicableSurveyType()}`,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isVisible && !has(state, 'areSurveysConfigured')) {
      setState({ ...state, areSurveysConfigured: !isEmpty(surveys) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const Q_OVERRIDE_REASONS = map(qOverrideReasons, qOverrideReason => {
    return { label: qOverrideReason };
  });

  const NQ_OVERRIDE_REASONS = map(nqOverrideReasons, nqOverrideReason => {
    return { label: nqOverrideReason };
  });

  const POPULATION = map(applicableEntitySections, applicableEntitySection => {
    return { label: applicableEntitySection };
  });

  const setSearchBy = value => {
    setState({ ...state, searchBy: value });
  };
  const setIsQualified = value => {
    setState({ ...state, selectedQualification: value });
  };

  const getSearchSurveysQueryString = userAlias => {
    const surveyType = getApplicableSurveyType();
    if (isEmpty(userAlias) || isEmpty(surveyType)) {
      return '';
    }

    return `surveyType equals ${surveyType} && userAlias equals ${userAlias}`;
  };

  const refreshSelectedSMESurveys = selectedSME => {
    clearSurveys();

    const smeUserAlias = get(selectedSME, 'description');
    if (isEmpty(smeUserAlias)) {
      return;
    }

    searchSurveys({
      body: {
        pageNumber: 1,
        pageSize: 10,
        query: getSearchSurveysQueryString(smeUserAlias),
      },
    });
  };

  const setSMEOption = value => {
    const { selectedSME: previouslySelectedSME } = state;
    if (isEqual(previouslySelectedSME, value)) {
      return;
    }

    setState({ ...state, selectedSME: value, selectedSMESurvey: null });
    refreshSelectedSMESurveys(value);
  };

  const setSelectedSMESurvey = value => {
    setState({ ...state, selectedSMESurvey: value });
  };

  const setSelectedReasonForQ = value => {
    setState({ ...state, selectedReasonForQ: value });
  };

  const setSelectedReasonForNQ = value => {
    setState({ ...state, selectedReasonForNQ: value });
  };

  const setSelectedPopulation = value => {
    setState({ ...state, selectedPopulation: value });
  };

  const onCancelHandler = () => {
    onCancel();
    setState(initialState);
  };

  const onConfirmHandler = () => {
    if (!isModalSubmittable({ state, itemType })) {
      return;
    }
    onConfirm(state);
    setState(initialState);
  };

  const getStatus = () => {
    if (isFetchingData) {
      return 'loading';
    }
    return 'finished';
  };

  const getSMEs = () => {
    return map(get(data, '1', []), ({ employeeName, employeeAlias, jobTitle, recordId }) => {
      return {
        label: employeeName,
        description: employeeAlias,
        tags: [jobTitle],
        recordId,
      };
    });
  };

  const getQueryString = filteringText => {
    if (isEmpty(filteringText)) return '';

    const { searchBy } = state;
    return `${searchBy} equals ${filteringText} || ${searchBy} contains ${filteringText}`;
  };

  const fetchData = filteringText => {
    getDataBegin({
      body: {
        itemType: ITEM_TYPE_LABOR,
        pageSize: 10,
        pageNumber: 1,
        query: getQueryString(filteringText),
      },
    });
  };

  const getSMESurveys = () => {
    const { selectedSME } = state;
    if (isEmpty(selectedSME)) {
      return [];
    }

    const smeSurveys = filter(
      map(
        get(surveys, '1', []),
        ({
          surveyName,
          surveyBusinessUnit,
          surveyCompanyOFACode,
          surveyCostCenter,
          surveyCostCenterName,
          status: surveyStatus,
          surveyId,
        }) => {
          return {
            label: surveyName,
            description: surveyStatus,
            tags: [
              surveyBusinessUnit,
              surveyCompanyOFACode,
              surveyCostCenter,
              surveyCostCenterName,
            ],
            surveyId,
          };
        },
      ),
      ({ description }) => description !== SURVEY_STATUS_SUBMITTED,
    );

    return isEmpty(smeSurveys) ? [{ label: 'Create new survey' }] : smeSurveys;
  };

  const handleLoadItems = ({ detail: { filteringText } }) => {
    if (state.filterText === filteringText) return;
    setState({ ...state, filterText: filteringText });
    fetchData(filteringText);
  };

  const getEntityTypeText = () => {
    return lowerCase(entityType) + (selectedEmployees > 1 ? 's' : '');
  };

  const getModalContent = () => {
    const {
      selectedReasonForQ,
      selectedReasonForNQ,
      selectedSMESurvey,
      selectedSME,
      searchBy,
      selectedQualification,
      areSurveysConfigured,
      selectedPopulation,
    } = state;
    return (
      <ModalContent
        itemType={itemType}
        selectedReasonForQ={selectedReasonForQ}
        selectedReasonForNQ={selectedReasonForNQ}
        getEntityTypeText={getEntityTypeText}
        selectedSMESurvey={selectedSMESurvey}
        selectedSME={selectedSME}
        setSelectedReasonForQ={setSelectedReasonForQ}
        setSelectedReasonForNQ={setSelectedReasonForNQ}
        Q_OVERRIDE_REASONS={Q_OVERRIDE_REASONS}
        NQ_OVERRIDE_REASONS={NQ_OVERRIDE_REASONS}
        setSMEOption={setSMEOption}
        setSelectedSMESurvey={setSelectedSMESurvey}
        handleLoadItems={handleLoadItems}
        getStatus={getStatus}
        getSMEs={getSMEs}
        isFetchingSurveys={isFetchingSurveys}
        getSMESurveys={getSMESurveys}
        searchBy={searchBy}
        selectedQualification={selectedQualification}
        setSearchBy={setSearchBy}
        areSurveysConfigured={areSurveysConfigured}
        POPULATION={POPULATION}
        setSelectedPopulation={setSelectedPopulation}
        selectedPopulation={selectedPopulation}
      />
    );
  };

  const { selectedQualification } = state;
  return (
    <ModalSkelton
      content={getModalContent()}
      isVisible={isVisible}
      onDismiss={onDismiss}
      onConfirmHandler={onConfirmHandler}
      getEntityTypeText={getEntityTypeText}
      onCancelHandler={onCancelHandler}
      selectedEmployees={selectedEmployees}
      setIsQualified={setIsQualified}
      selectedQualification={selectedQualification}
      isModalSubmittable={isModalSubmittable({ state, itemType })}
    />
  );
};
UpdateSelectedModal.propTypes = {
  itemType: PropTypes.string.isRequired,
  entityType: PropTypes.string.isRequired,
  selectedEmployees: PropTypes.number.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  qOverrideReasons: PropTypes.array.isRequired,
  nqOverrideReasons: PropTypes.array.isRequired,
  surveys: PropTypes.object.isRequired,
  isFetchingSurveys: PropTypes.bool.isRequired,
  searchSurveys: PropTypes.func.isRequired,
  clearSurveys: PropTypes.func.isRequired,
  applicableEntitySections: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  isFetchingData: get(state, 'entities.population.labor.population.isFetching', false) || false,
  data: get(state, 'entities.population.labor.population.data', {}) || {},
  qOverrideReasons: get(state, 'entities.schemas.data.metaData.groupingQOverrideReasons', []) || [],
  nqOverrideReasons:
    get(state, 'entities.schemas.data.metaData.groupingNQOverrideReasons', []) || [],
  surveys: get(state, 'entities.surveys.data', {}) || {},
  isFetchingSurveys: get(state, 'entities.surveys.isFetching', false) || false,
  applicableEntitySections:
    get(state, 'entities.schemas.data.metaData.applicableEntitySection', []) || [],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchLaborPopulationActions.BEGIN,
      clearData: searchLaborPopulationActions.CLEAR,
      searchSurveys: searchSurveysAction.BEGIN,
      clearSurveys: searchSurveysAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(UpdateSelectedModal);
