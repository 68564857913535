import get from 'lodash/get';
import { isObject, isNumber } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import * as React from 'react';
import map from 'lodash/map';
import { round } from 'lodash/math';
import { Grid } from '@amzn/awsui-components-react';
import METRICS from '../../constants/metrics';
import ValueWithLabel from '../../../../../../common/components/value_with_label/ValueWithLabelForSurveysTab';

const processMetrics = (metrics, key, isPercentage) => {
  const metric = get(metrics, key, {});
  if (!isNumber(metric)) {
    return 'N/A';
  }

  if (isPercentage && isNumber(metric)) {
    return `${round(metric, 0)}%`;
  }
  return `${metric.toFixed(1)}`;
};

const getOverview = surveysStudySummary => {
  const metrics = get(surveysStudySummary, 'metrics', {});

  if (!isObject(metrics) || isEmpty(metrics)) {
    return null;
  }

  return (
    <Grid
      gridDefinition={[
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
      ]}
    >
      {map(METRICS, ({ label, key, isPercentage }) => {
        return (
          <div className="right-divider">
            <ValueWithLabel key={label} label={label}>
              {processMetrics(metrics, key, isPercentage)}
            </ValueWithLabel>
          </div>
        );
      })}
    </Grid>
  );
};

export default getOverview;
