import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import keys from 'lodash/keys';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import indexOf from 'lodash/indexOf';
import isEqual from 'lodash/isEqual';
import { AGGREGATIONS_IDS } from '../../../surveys_tab/constants/aggregations';

export const getXDomain = ({ selectedAggId, aggregation, xDomainSortArray }) => {
  const xDomain = keys(aggregation);

  if (isEqual(selectedAggId, AGGREGATIONS_IDS.BY_DATE)) {
    return sortBy(xDomain);
  }

  return sortBy(xDomain, bucket => {
    return indexOf(xDomainSortArray, bucket);
  });
};

export const getSeries = ({ aggregation, aggTypes }) => {
  if (!isObject(aggregation) || isEmpty(aggregation)) {
    return [];
  }

  return map(aggTypes, ({ label, key, color }) => {
    return {
      title: label,
      type: 'bar',
      data: map(aggregation, (bucket, bucketKey) => {
        return { x: bucketKey, y: get(bucket, [key], 0) };
      }),
      color,
    };
  });
};
