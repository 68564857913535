import { filter, isNull, isUndefined, size } from 'lodash';
import getOptionsDefinition from '../configure_notification/components/utils/util';

export const getSelectedObject = (options, value) => {
  const selectedOption = getOptionsDefinition(
    filter(options, opt => {
      return opt.value === value;
    }),
  );
  if (size(selectedOption) === 1) {
    return selectedOption[0];
  }
  return { label: null, value: null };
};
export const getSelectedCCListOption = (options, selectedValues) => {
  const ccList = [];
  if (isNull(selectedValues) || isUndefined(selectedValues)) {
    return ccList;
  }
  const updatedOptions = getOptionsDefinition(options);
  // eslint-disable-next-line no-restricted-syntax
  for (const cc of selectedValues) {
    // eslint-disable-next-line no-restricted-syntax
    for (const opt of updatedOptions) {
      if (opt.value === cc) {
        ccList.push(opt);
      }
    }
  }
  return ccList;
};
export const getSelectedDaysOfWeek = (temporalDataList, deliverySchedule) => {
  const { daysOfWeek } = deliverySchedule;
  // eslint-disable-next-line no-restricted-syntax
  for (const temporalData of temporalDataList) {
    daysOfWeek[temporalData] = true;
  }
  return daysOfWeek;
};
export const getUploadedAttachment = (
  attachments,
  clearNotificationAttachments,
  addAttachments,
) => {
  clearNotificationAttachments();
  // eslint-disable-next-line no-restricted-syntax
  for (const attachment of attachments) {
    addAttachments({
      body: {
        label: attachment.label,
        size: attachment.size,
        active: attachment.active,
        id: attachment.id,
        presignedUrl: attachment.s3PresignedUrl,
      },
    });
  }
};

export const getSelectedCostCentersList = selectedValues => {
  const costCenterList = [];
  if (isNull(selectedValues) || isUndefined(selectedValues)) {
    return costCenterList;
  }
  // eslint-disable-next-line no-restricted-syntax
  for (const selectedValue of selectedValues) {
    costCenterList.push({
      label: selectedValue.name,
      value: selectedValue.identifier,
      description: selectedValue.identifier,
    });
  }
  return costCenterList;
};
