import React from 'react';
import { Button } from '@amzn/awsui-components-react';
import { get, isEmpty, isNull } from 'lodash';
import { NOTIFICATION_TYPE_ERROR } from '../../../common/components/with_page/notifications/constants';
import {
  convertISOToDateTime,
  getDateInMMDDYYYYHHMIFormat,
} from '../../../common/utils/date_time_util';
import {
  IMPORT_DISABLE_POSSIBLE,
  IMPORT_STATUS,
  IMPORT_STATUS_NAME,
  IMPORT_TYPE_NAMES,
} from '../constants/import_types';
import { downloadFileFromURL } from '../../../common/utils/api_util';
import { triggerImportAction } from '../redux/TriggerImport';

const filterByImportStatus = (importTypesMetadata, status) => {
  return importTypesMetadata.filter(importTypeMetadata => {
    const { importStatus } = importTypeMetadata;
    return importStatus === status;
  });
};

const getLastTriggeredImportTypeMetadata = importTypesMetadata => {
  importTypesMetadata.sort((importTypeMetadata1, importTypeMetadata2) => {
    const lastTriggerDate1 = convertISOToDateTime(
      get(importTypeMetadata1, 'lastTriggerDate', null),
    );
    const lastTriggerDate2 = convertISOToDateTime(
      get(importTypeMetadata2, 'lastTriggerDate', null),
    );

    // descending order
    return lastTriggerDate1.getTime() < lastTriggerDate2.getTime() ? 1 : -1;
  });

  return importTypesMetadata.length > 0 ? importTypesMetadata[0] : null;
};

export const getImportTypeMetadataForHeader = (importMetadata, supportedImportTypes) => {
  const supportedImportTypesMetadata = importMetadata.filter(importTypeMetadata => {
    const { importType } = importTypeMetadata;
    return supportedImportTypes.includes(importType);
  });

  const inProgressImportTypesMetadata = filterByImportStatus(
    supportedImportTypesMetadata,
    IMPORT_STATUS.InProgress,
  );
  if (inProgressImportTypesMetadata.length > 0) {
    return getLastTriggeredImportTypeMetadata(inProgressImportTypesMetadata);
  }

  return getLastTriggeredImportTypeMetadata(supportedImportTypesMetadata);
};

const getDownloadButton = (failedTemplateURL, importType) => {
  const downloadFile = () => {
    if (!isNull(failedTemplateURL) && !isEmpty(failedTemplateURL)) {
      downloadFileFromURL({
        presignedURL: failedTemplateURL,
        fileName: `${importType}-Errors.csv`,
      });
    }
  };
  return <Button onClick={downloadFile}>Download error report</Button>;
};

const getSIMButton = () => {
  const link =
    'https://issues.amazon.com/issues/create?assignedFolder=57e64fe0-a4f2-4f25-bf19-dc1dfea8f69e';
  return (
    <Button variant="normal" href={link}>
      Raise a SIM
    </Button>
  );
};

export const getImportFailureNotification = ({
  tableIdentifier,
  importType,
  failedTemplateURL,
  supportedImportTypes,
}) => {
  const importTypeName = IMPORT_TYPE_NAMES[importType];
  if (supportedImportTypes && supportedImportTypes.includes(importType)) {
    if (!isNull(failedTemplateURL) && !isEmpty(failedTemplateURL)) {
      return {
        type: NOTIFICATION_TYPE_ERROR,
        content: `Validation failed for ${importTypeName} data. 
      Resolve errors in the file and try again.`,
        action: getDownloadButton(failedTemplateURL, importType),
        id: `${tableIdentifier}_${importType}`,
      };
    }
    return {
      type: NOTIFICATION_TYPE_ERROR,
      content: `Import failed for ${importTypeName} data. Please cut a SIM.`,
      action: getSIMButton(),
      id: `${tableIdentifier}_${importType}`,
    };
  }
  return {};
};

export const processUserLevelImportMetadata = importMetadata => {
  const userLevelFailedImportTypes = importMetadata.filter(importTypeMetadata => {
    const userLevelImportMetadata = get(importTypeMetadata, 'userLevelImportMetadata', null);
    const userLevelImportStatus = get(
      userLevelImportMetadata,
      'importStatus',
      IMPORT_STATUS.Unknown,
    );

    return userLevelImportStatus === IMPORT_STATUS.Failed;
  });

  if (userLevelFailedImportTypes.length === 0) {
    return null;
  }

  const lastTriggeredImportTypeMetadata = getLastTriggeredImportTypeMetadata(
    userLevelFailedImportTypes,
  );
  return {
    importType: lastTriggeredImportTypeMetadata.importType,
    failedTemplateURL: get(
      lastTriggeredImportTypeMetadata,
      'userLevelImportMetadata.failedImportTemplateURL',
    ),
  };
};

export const getImportStatusHeaderDescription = importTypeMetadata => {
  if (importTypeMetadata === null) return '';

  const lastImportDate = get(importTypeMetadata, 'lastImportDate', '');
  let importStatus = get(importTypeMetadata, 'importStatus', IMPORT_STATUS.Unknown);
  if (isNull(importStatus)) {
    importStatus = IMPORT_STATUS.Unknown;
  }
  return `Last imported: ${getDateInMMDDYYYYHHMIFormat(lastImportDate)}; Import status: ${
    IMPORT_STATUS_NAME[importStatus]
  }`;
};

export const getImportStatusHeaderDescriptionForGroupingPage = (importTypeMetadata, data) => {
  if (isNull(importTypeMetadata) && isNull(data)) return '';

  let importStatus = get(importTypeMetadata, 'importStatus', IMPORT_STATUS.Unknown);
  if (isNull(importStatus)) {
    importStatus = IMPORT_STATUS.Unknown;
  }
  const lastUpdateDate = getDateInMMDDYYYYHHMIFormat(get(data, 'lastChangedOn', ''));
  let updateAllStatus = get(data, 'groupingStatus', IMPORT_STATUS.Unknown);
  if (isNull(updateAllStatus)) {
    updateAllStatus = IMPORT_STATUS.Unknown;
  }
  let lastUpdateStatus;

  if (importStatus === IMPORT_STATUS.InProgress || updateAllStatus === 'In Progress') {
    lastUpdateStatus = IMPORT_STATUS_NAME.InProgress;
  } else if (importStatus !== IMPORT_STATUS.Unknown || updateAllStatus !== IMPORT_STATUS.Unknown) {
    lastUpdateStatus = IMPORT_STATUS_NAME.Completed;
  } else {
    lastUpdateStatus = IMPORT_STATUS_NAME.Unknown;
  }
  return `Last updated: ${lastUpdateDate}; Update status: ${lastUpdateStatus}`;
};

export const isImportDisabled = config => {
  return (
    process.env.REACT_APP_IMPORT_DISABLED === 'true' &&
    get(config, 'importTypeMetadata.isImportDisabled', false)
  );
};

export const isImportInProgress = config => {
  const importStatus = get(config, 'importTypeMetadata.importStatus', IMPORT_STATUS.Unknown);
  return importStatus === IMPORT_STATUS.InProgress;
};

export const getImportDropdownItems = (importTypes, config) => {
  return importTypes.map(importType => {
    return {
      id: importType,
      text: IMPORT_TYPE_NAMES[importType],
      disabled: IMPORT_DISABLE_POSSIBLE[importType] && isImportDisabled(config),
    };
  });
};

export const getUploadToS3PayloadForTriggerImport = ({ documentFile, fileImportType }) => {
  return {
    documentFile,
    presignedURLPayload: {
      useCase: fileImportType,
    },
    onUploadSuccess: {
      action: triggerImportAction.BEGIN,
      payload: {
        body: {
          importType: fileImportType,
          fileName: documentFile.name,
        },
      },
      documentIdentifier: 's3objectName',
    },
    onUploadFailure: {
      action: triggerImportAction.FAILURE,
    },
  };
};
