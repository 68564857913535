import * as React from 'react';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { Box, Button, Header, Link } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { bindActionCreators } from 'redux';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../common/constants/route_paths';

import requestDataBody from '../constants/notificationTypesConfig';
import { getNotificationEventsOverviewAction } from '../redux';
import TextFiltering from '../../../../common/components/table/components/TextFiltering';
import { getStudyPeriod } from '../../../../common/constants/study_period';
import { SURVEY_NAMES } from '../../Notifications/common/constants/constants';
import { getNotificationStatus } from '../../Notifications/common/util/utils';
import '../../Notifications/common/css/style.css';
import { configureAttachmentStateAction } from '../../configure_notification/redux/configureAttachmentState';
import { configureTemplateStateAction } from '../../configure_notification/redux/configureTemplateState';

const NotificationTypes = ({
  navigateTo,
  data,
  getDataBegin,
  isFetching,
  studyPeriod,
  clearAttachmentState,
  clearTemplateState,
}) => {
  const [visibleItems, setVisibleItems] = React.useState(data);

  useEffect(() => {
    getDataBegin(requestDataBody);
  }, [getDataBegin, studyPeriod]);

  useEffect(() => {
    setVisibleItems(data);
  }, [getDataBegin, data]);

  useEffect(() => {
    clearAttachmentState();
    clearTemplateState();
  });

  const onSelectHandler = item => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_DETAIL_PAGE, studyPeriod),
      state: item.sortKey,
    });
  };

  const addNotificationHandler = () => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.CREATE_NOTIFICATION, studyPeriod),
    });
  };

  return (
    <Cards
      trackBy="sortKey"
      loading={isFetching}
      loadingText="Loading Notification Types"
      ariaLabels={{
        itemSelectionLabel: (e, t) => `select ${t.name}`,
        allItemsSelectionLabel: () => 'select all',
        selectionGroupLabel: 'Item selection',
      }}
      cardDefinition={{
        header: item => (
          <Header actions={getNotificationStatus(item)}>
            <Link
              fontSize="heading-m"
              onFollow={() => {
                onSelectHandler(item);
              }}
            >
              {item.notificationName}
            </Link>
          </Header>
        ),
        sections: [
          {
            id: 'formattedTimeline',
            header: 'Delivery schedule',
            content: e => e.formattedTimeline,
          },
          {
            id: 'totalJobCount',
            header: 'Number of recipients',
            content: e => e.totalJobCount,
          },
          {
            id: 'applicableTo',
            header: 'Survey',
            content: e => SURVEY_NAMES[e.applicableTo],
          },
        ],
      }}
      cardsPerRow={[{ cards: 1 }, { minWidth: 700, cards: 4 }]}
      items={visibleItems}
      visibleSections={['formattedTimeline', 'totalJobCount', 'applicableTo']}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No Notification Types</b>
        </Box>
      }
      filter={
        <TextFiltering
          setVisibleItems={setVisibleItems}
          filterOn="notificationName"
          filteringPlaceholder="Search"
          data={data}
        />
      }
      header={
        <Header actions={<Button onClick={addNotificationHandler}>Add notification</Button>}>
          Notification types
        </Header>
      }
    />
  );
};
NotificationTypes.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  clearAttachmentState: PropTypes.func.isRequired,
  clearTemplateState: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    data:
      get(state, 'entities.notifications.notificationEventsOverview.data.ddbEventTypeDOS', []) ||
      [],
    isFetching:
      get(state, 'entities.notifications.notificationEventsOverview.isFetching', false) || false,
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: getNotificationEventsOverviewAction.BEGIN,
      clearData: getNotificationEventsOverviewAction.CLEAR,
      navigateTo: payload => push(payload),
      clearAttachmentState: configureAttachmentStateAction.CLEAR,
      clearTemplateState: configureTemplateStateAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationTypes);
