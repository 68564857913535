import { GET_NOTIFICATION_EVENTS_OVERVIEW_ENDPOINT } from '../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';

const GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES',
);
export const getNotificationEventsOverviewAction = createAPILifecycleActions(
  GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES.NAME,
);

const getSerializedData = payload => {
  try {
    return JSON.parse(payload.data);
  } catch (e) {
    return [];
  }
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: getSerializedData(payload),
        error: null,
      };
    case GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const getNotificationEventsOverviewEpic = createAmplifyAPICallEpic({
  actionType: GET_NOTIFICATION_EVENTS_OVERVIEW_ACTION_TYPES.BEGIN,
  api: GET_NOTIFICATION_EVENTS_OVERVIEW_ENDPOINT,
  successCallback: response => [getNotificationEventsOverviewAction.SUCCESS(response.body)],
  errorCallback: () => [getNotificationEventsOverviewAction.FAILURE()],
});
