import { filter, get, isEmpty } from 'lodash';
import Box from '@amzn/awsui-components-react/polaris/box';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import { DatePicker, Grid, Select } from '@amzn/awsui-components-react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { getTemplates } from './utils/SurveyUtils';
import { updateJobCodesQNQAction } from '../../qnq/components/job_codes_tab/components/job_codes_table/redux/updateJobCodesQnq';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../common/constants/route_paths';
import { getStudyPeriod } from '../../../common/constants/study_period';

const CreateSurveysComponent = ({
  surveyType,
  populationType,
  data,
  state,
  setState,
  populationName,
  studyPeriod,
  navigateTo,
}) => {
  const today = new Date();
  useEffect(() => {
    if (isEmpty(data)) {
      navigateTo({
        pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriod),
      });
    }
  }, [data, navigateTo, studyPeriod]);

  return (
    <SpaceBetween size="xs">
      <Box fontSize="heading-m">
        <b>{surveyType}</b> <i>{populationName}</i>
      </Box>
      <Grid gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}>
        <Box>
          Release date
          <DatePicker
            disabled={today.getTime() > Date.parse(get(state[populationType], 'releaseDate'))}
            onChange={({ detail }) => {
              setState({
                ...state,
                [populationType]: { ...state[populationType], releaseDate: detail.value },
              });
            }}
            isDateEnabled={date => date.getTime() > today.getTime()}
            value={get(state[populationType], 'releaseDate')}
            openCalendarAriaLabel={selectedDate =>
              `Choose Date${selectedDate ? `, selected date is ${selectedDate}` : ''}`
            }
            nextMonthAriaLabel="Next month"
            placeholder="YYYY/MM/DD"
            previousMonthAriaLabel="Previous month"
            todayAriaLabel="Today"
          />
        </Box>
        <Box>
          Due date
          <DatePicker
            onChange={({ detail }) =>
              setState({
                ...state,
                [populationType]: { ...state[populationType], dueDate: detail.value },
              })
            }
            value={get(state[populationType], 'dueDate')}
            isDateEnabled={date =>
              date.getTime() > today.getTime() &&
              date.getTime() > Date.parse(get(state[populationType], 'releaseDate'))
            }
            openCalendarAriaLabel={selectedDate =>
              `Choose Date${selectedDate ? `, selected date is ${selectedDate}` : ''}`
            }
            locale="en-US"
            nextMonthAriaLabel="Next month"
            placeholder="YYYY/MM/DD"
            previousMonthAriaLabel="Previous month"
            todayAriaLabel="Today"
          />
        </Box>
        <Box>
          Survey Template
          <Grid gridDefinition={[{ colspan: 11 }]}>
            <Select
              options={getTemplates(data, surveyType)}
              onChange={({ detail }) => {
                setState({
                  ...state,
                  [populationType]: {
                    ...state[populationType],
                    template: filter(
                      getTemplates(data, surveyType),
                      template => template.value === detail.selectedOption.value,
                    )[0],
                  },
                });
              }}
              selectedOption={get(state, `${populationType}.template`, '')}
            />
          </Grid>
        </Box>
      </Grid>
    </SpaceBetween>
  );
};
CreateSurveysComponent.propTypes = {
  surveyType: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  populationType: PropTypes.string.isRequired,
  populationName: PropTypes.string.isRequired,
  navigateTo: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: updateJobCodesQNQAction.BEGIN,
      clearData: updateJobCodesQNQAction.CLEAR,
      navigateTo: payload => push(payload),
    },
    dispatch,
  );

const mapStateToProps = state => ({
  data: get(state, 'entities.schemas.data.metaData', []) || [],
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSurveysComponent);
