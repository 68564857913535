import * as React from 'react';
import { get } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../common/components/table/constants';
import ReportTableActions from '../components/ReportTableActions';
import REPORT_TYPES from '../../../constants/report_types';
import { getDateInMMDDYYYYHHMIFormat } from '../../../../../common/utils/date_time_util';
import {
  isReportRefreshable,
  REPORT_REFRESH_STATUS,
  REPORT_REFRESH_STATUS_NAME,
} from '../../../constants/api_constants';
import { getUpdateStatus } from '../../utils/UpdateStatusUtils';

export const REPORT_TRACKING_ID = 'reportItemId';

const EmptyTableView = () => <p>No data.</p>;

const getReportTableConfig = ({
  selectedReportCard,
  reportGeneratedOn,
  reportGeneratedTill,
  setReportGeneratedTill,
  reportRefreshStatusData,
  reportData,
}) => {
  const reportType = get(selectedReportCard, ['id'], REPORT_TYPES.FINANCE_AND_VP_QUICKSIGHT_REPORT);
  const reportName = get(selectedReportCard, ['name']);

  let updateStatus;
  if (isReportRefreshable(reportType)) {
    updateStatus = getUpdateStatus(reportRefreshStatusData, reportData, reportType);
  } else {
    updateStatus = REPORT_REFRESH_STATUS.Completed;
  }

  const getTitle = () => {
    return reportName;
  };

  const getDescription = () => {
    if (isEmpty(reportGeneratedOn)) {
      return `Last updated: -; Update status: ${REPORT_REFRESH_STATUS_NAME[updateStatus]}`;
    }
    return `Last updated: ${getDateInMMDDYYYYHHMIFormat(reportGeneratedOn)}; Update status: ${
      REPORT_REFRESH_STATUS_NAME[updateStatus]
    }`;
  };

  const Action = ({ query }) => (
    <ReportTableActions
      reportGeneratedTill={reportGeneratedTill}
      setReportGeneratedTill={setReportGeneratedTill}
      id={reportType}
      query={query}
    />
  );

  Action.propTypes = {
    query: PropTypes.string.isRequired,
  };

  return {
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: {},
    dataTrackingId: REPORT_TRACKING_ID,
    dataLoadingText: `Loading ${reportName}`,
    headerConfig: {
      title: getTitle(),
      Actions: Action,
      description: getDescription(),
    },
    getDataRequestBody: () => {
      if (isEmpty(reportGeneratedTill)) {
        return { reportType };
      }
      return { reportType, reportGeneratedTill };
    },
  };
};

export default getReportTableConfig;
