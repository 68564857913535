import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import SuppliesTable from './components/SuppliesTable';

const GroupingSuppliesTable = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <SuppliesTable />
      </div>
    </Grid>
  );
};

export default GroupingSuppliesTable;
