import BarChart from '@amzn/awsui-components-react/polaris/bar-chart';
import Box from '@amzn/awsui-components-react/polaris/box';
import PropTypes from 'prop-types';
import * as React from 'react';
import isEqual from 'lodash/isEqual';
import { getSeries, getXDomain } from './utils';
import { getFormattedDate } from '../../../surveys_tab/components/survey_status_details/utils';
import { AGGREGATIONS_IDS } from '../../../surveys_tab/constants/aggregations';

const StudySummaryBarChart = ({
  selectedAggId,
  aggregation,
  aggTypes,
  xDomainSortArray,
  isLoading,
  yTile,
}) => {
  return (
    <Box className="standard-border" margin={{ top: 'l' }} padding="l">
      <BarChart
        series={getSeries({ aggregation, aggTypes })}
        xDomain={getXDomain({ selectedAggId, aggregation, xDomainSortArray })}
        errorText="Error loading data."
        height={300}
        loadingText="Loading chart"
        xScaleType="categorical"
        yTitle={yTile}
        hideFilter
        empty={
          <Box textAlign="center" color="inherit">
            <b>No data available</b>
          </Box>
        }
        statusType={isLoading ? 'loading' : 'finished'}
        i18nStrings={{
          xTickFormatter: e =>
            isEqual(selectedAggId, AGGREGATIONS_IDS.BY_DATE) ? getFormattedDate(e) : e,
        }}
      />
    </Box>
  );
};

StudySummaryBarChart.propTypes = {
  selectedAggId: PropTypes.string,
  aggregation: PropTypes.object,
  aggTypes: PropTypes.object,
  xDomainSortArray: PropTypes.array,
  isLoading: PropTypes.bool,
  yTile: PropTypes.string.isRequired,
};

StudySummaryBarChart.defaultProps = {
  selectedAggId: null,
  aggregation: {},
  aggTypes: {},
  xDomainSortArray: [],
  isLoading: false,
};

export default StudySummaryBarChart;
