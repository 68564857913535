import Box from '@amzn/awsui-components-react/polaris/box';
import { Select, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';

const ChooseSurvey = ({
  isFetchingSurveys,
  selectedSMESurvey,
  setSelectedSMESurvey,
  getSMESurveys,
  areSurveysConfigured,
}) => {
  const isDisabled = () => {
    return !areSurveysConfigured;
  };

  const getOptions = () => {
    return isDisabled() ? [{ label: 'No survey configured yet' }] : getSMESurveys();
  };

  const getSelectedOption = () => {
    return isDisabled() ? { label: 'No survey configured yet' } : selectedSMESurvey;
  };

  return (
    <Box>
      <TextContent>
        <p>Choose the Survey</p>
      </TextContent>
      <Select
        selectedOption={getSelectedOption()}
        onChange={({ detail }) => setSelectedSMESurvey(detail.selectedOption)}
        statusType={isFetchingSurveys ? 'loading' : 'finished'}
        loadingText="Loading surveys"
        options={getOptions()}
        filteringType="auto"
        selectedAriaLabel="Selected"
        errorText="Error fetching results."
        disabled={isDisabled()}
      />
    </Box>
  );
};

ChooseSurvey.propTypes = {
  isFetchingSurveys: PropTypes.bool.isRequired,
  selectedSMESurvey: PropTypes.object,
  setSelectedSMESurvey: PropTypes.func.isRequired,
  getSMESurveys: PropTypes.func.isRequired,
  areSurveysConfigured: PropTypes.bool,
};

ChooseSurvey.defaultProps = {
  selectedSMESurvey: null,
  areSurveysConfigured: false,
};

export default ChooseSurvey;
