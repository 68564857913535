import { Select, TextContent } from '@amzn/awsui-components-react';
import debounce from '@amzn/awsui-components-react/polaris/internal/debounce';
import Box from '@amzn/awsui-components-react/polaris/box';
import React from 'react';
import PropTypes from 'prop-types';
import { DEBOUNCE_DELAY } from '../../../../constants/update_selected_constants';

const ChooseSME = ({ getStatus, handleLoadItems, selectedSME, setSMEOption, getSMEs }) => {
  return (
    <Box>
      <TextContent>
        <p>Choose the SME</p>
      </TextContent>
      <Select
        selectedOption={selectedSME}
        onChange={({ detail }) => setSMEOption(detail.selectedOption)}
        statusType={getStatus()}
        onLoadItems={debounce(handleLoadItems, DEBOUNCE_DELAY)}
        loadingText="Loading SMEs"
        options={getSMEs()}
        filteringType="auto"
        selectedAriaLabel="Selected"
        errorText="Error fetching results."
      />
    </Box>
  );
};

ChooseSME.propTypes = {
  getStatus: PropTypes.func.isRequired,
  handleLoadItems: PropTypes.func.isRequired,
  selectedSME: PropTypes.object.isRequired,
  setSMEOption: PropTypes.func.isRequired,
  getSMEs: PropTypes.func.isRequired,
};

export default ChooseSME;
