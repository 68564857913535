import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';
import { GET_NOTIFICATION_FORM_DATA } from '../../../../common/config/api_endpoints';

export const GET_NOTIFICATION_FORM_DATA_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_NOTIFICATION_FORM_DATA_ACTION_TYPES',
);
export const getNotificationFormDataAction = createAPILifecycleActions(
  GET_NOTIFICATION_FORM_DATA_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NOTIFICATION_FORM_DATA_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_NOTIFICATION_FORM_DATA_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: JSON.parse(payload.body.data),
        error: null,
      };
    case GET_NOTIFICATION_FORM_DATA_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case GET_NOTIFICATION_FORM_DATA_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const getNotificationFormDataEpic = createAmplifyAPICallEpic({
  actionType: GET_NOTIFICATION_FORM_DATA_ACTION_TYPES.BEGIN,
  api: GET_NOTIFICATION_FORM_DATA,
  successCallback: response => [getNotificationFormDataAction.SUCCESS(response)],
  errorCallback: () => [getNotificationFormDataAction.FAILURE()],
});
