import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../redux/actions/action_utils';
import { TRIGGER_REPORT_REFRESH } from '../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../redux/epics/utils';
import { setReportRefreshStatusAction } from './GetReportRefreshStatus';

export const TRIGGER_REPORT_REFRESH_ACTION_TYPES = createAPILifecycleActionTypes(
  'TRIGGER_REPORT_REFRESH_ACTION_TYPES',
);
export const triggerReportRefreshAction = createAPILifecycleActions(
  TRIGGER_REPORT_REFRESH_ACTION_TYPES.NAME,
);

const initialState = {
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.BEGIN:
      return {
        ...state,
      };
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        data: payload,
        error: null,
      };
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.FAILURE:
      return {
        ...state,
        data: null,
        error: payload,
      };
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const triggerReportRefreshEpic = createAmplifyAPICallEpic({
  actionType: TRIGGER_REPORT_REFRESH_ACTION_TYPES.BEGIN,
  api: TRIGGER_REPORT_REFRESH,
  successCallback: response => [
    setReportRefreshStatusAction.BEGIN(),
    triggerReportRefreshAction.SUCCESS(response),
  ],
  errorCallback: () => [triggerReportRefreshAction.FAILURE()],
});
