import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { isNull } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import FileUpload from '../../../../common/components/file_uploader/FileUploader';

const AttachmentModal = ({
  onCancelHandler,
  isModalVisible,
  onConfirmHandler,
  fileState,
  setFileState,
}) => {
  return (
    <Modal
      onDismiss={onCancelHandler}
      visible={isModalVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onConfirmHandler}
              disabled={isNull(fileState.form.file)}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Add attachment"
    >
      <FileUpload setFileState={setFileState} state={fileState} acceptedFileTypes="" />
    </Modal>
  );
};

AttachmentModal.propTypes = {
  onCancelHandler: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onConfirmHandler: PropTypes.func.isRequired,
  fileState: PropTypes.object.isRequired,
  setFileState: PropTypes.func.isRequired,
};

export default AttachmentModal;
