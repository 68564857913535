import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../../common/components/table/constants';

import RecipientsAction from '../components/RecipientsAction';

const EmptyTableView = () => <p>No recipients.</p>;

const recipientTableConfig = {
  dataTrackingId: 'esKey',
  dataLoadingText: 'Loading recipients',
  emptyElement: EmptyTableView,
  pageSize: DEFAULT_TABLE_PAGE_SIZE,
  multiSelectConfig: { selectionType: 'multi' },
  headerConfig: {
    title: 'Recipients',
    Actions: RecipientsAction,
  },
  // TODO : This needs to be dynamic
  getDataRequestBody: () => {
    return {
      isReceiverTypeRecipient: true,
    };
  },
};
export default recipientTableConfig;
