import React from 'react';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { AllocationMatrix } from '../../../common/components/AllocationMatrix';

export function EmployeeActivityAllocationMatrix({ data }) {
  const activityAllocationData = get(data, 'userResponse.ActivityAllocation.data', {});
  const defaultActivityList = get(data, 'userResponse.Activity.data.defaultItems', []);
  const selectedActivityList = get(data, 'userResponse.Activity.data.selectedActivities', []);
  const totalActivitiesList = [...defaultActivityList, ...selectedActivityList];
  const activityMapping = {};
  const employeeMapping = {};

  totalActivitiesList.forEach(activity => {
    const activityId = get(activity, 'id', '');
    activityMapping[activityId] = get(activity, 'name', '');
  });
  const employeeData = get(data, 'employeeData', []);
  if (!isNull(employeeData)) {
    employeeData.forEach(employee => {
      const employeeId = get(employee, 'recordId', '');
      employeeMapping[employeeId] = get(employee, 'employeeName', '');
    });
  }

  return (
    <AllocationMatrix
      data={activityAllocationData}
      projectActivityNameMapping={activityMapping}
      entityNameMapping={employeeMapping}
    />
  );
}

EmployeeActivityAllocationMatrix.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeActivityAllocationMatrix;
