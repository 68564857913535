import { mergeMap } from 'rxjs/operators';
import { getDocumentDownloadPresignedURLAction } from '../actions/GetDocumentDownloadPresignedURL';

export default ({ actionType }) => {
  const epic = action$ =>
    action$.ofType(actionType).pipe(
      mergeMap(action => {
        const originalPayload = action.payload;
        const { presignedURLPayload } = originalPayload;
        return [
          getDocumentDownloadPresignedURLAction({
            ...originalPayload,
            body: { ...presignedURLPayload },
          }),
        ];
      }),
    );
  return epic;
};
