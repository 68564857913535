import { Auth } from 'aws-amplify';
import { set } from 'lodash';
import { from } from 'rxjs';
import { mergeMap, concatMap, catchError } from 'rxjs/operators';
import { VALARI_API_NAME } from '../../common/config/api_endpoints';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../../common/config/api_constants';
import { getStudyPeriod } from '../../common/constants/study_period';

async function addContextToPayload(API, apiEndpoint, payload, state) {
  let newPayload = payload;
  if (!newPayload) {
    newPayload = {};
  }
  const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
  set(newPayload, ['body', 'accessToken'], accessToken);
  set(newPayload, ['body', 'clientId'], DEFAULT_BUSINESS_ID);
  set(newPayload, ['body', 'country'], DEFAULT_COUNTRY);
  set(newPayload, ['body', 'studyPeriod'], getStudyPeriod(state));
  return API.post(VALARI_API_NAME, apiEndpoint, newPayload);
}

export const decoratePayload = async (API, api, originalPayload, store, payloadGenerator) => {
  const newPayload = payloadGenerator
    ? await payloadGenerator({ store, originalPayload })
    : originalPayload;
  return addContextToPayload(API, api, newPayload, store.value);
};

const ZERO_ACTIONS_STREAM = () => [];
const createAmplifyAPICallEpic = ({
  actionType,
  payloadGenerator,
  api,
  successCallback,
  errorCallback,
}) => {
  if (!actionType || !api) {
    return ZERO_ACTIONS_STREAM;
  }

  const epic = (action$, store, { API }) =>
    action$.ofType(actionType).pipe(
      mergeMap(action => {
        const originalPayload = action.payload;
        return from(decoratePayload(API, api, originalPayload, store, payloadGenerator)).pipe(
          concatMap(
            successCallback
              ? response => successCallback(response, store, action)
              : ZERO_ACTIONS_STREAM,
          ),
          catchError(
            errorCallback ? error => errorCallback(error, store, action) : ZERO_ACTIONS_STREAM,
          ),
        );
      }),
    );

  return epic;
};

export default createAmplifyAPICallEpic;
