import { combineReducers } from 'redux';
import searchNotifications from '../Notifications/components/recipients_tab/components/recipients_table/redux/index';
import notificationEventsOverview from '../NotificationEventsOverview/redux/index';
import configureNotification from '../configure_notification/redux/configure-notification-slice';
import deleteNotifications from '../Notifications/components/Overview/redux/deleteNotifications';
import notificationFormData from '../configure_notification/redux/notification-form-data';
import configureAttachmentState from '../configure_notification/redux/configureAttachmentState';
import createNotificationSchedule from '../Notifications/components/Overview/redux/createNotificationSchedule';
import getNotificationDetails from '../Notifications/components/Overview/redux/getNotificationDetails';
import cancelScheduledNotification from '../Notifications/components/Overview/redux/cancelScheduledNotification';
import configureTemplateState from '../configure_notification/redux/configureTemplateState';

export default combineReducers({
  notificationEventsOverview,
  searchNotifications,
  configureNotification,
  configureAttachmentState,
  notificationFormData,
  deleteNotifications,
  createNotificationSchedule,
  getNotificationDetails,
  cancelScheduledNotification,
  configureTemplateState,
});
