import { set } from 'lodash';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../../../../common/config/api_constants';

const addContextInPayloadObjects = (body, studyPeriod) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const payloadObject of body) {
    set(payloadObject, ['clientId'], DEFAULT_BUSINESS_ID);
    set(payloadObject, ['country'], DEFAULT_COUNTRY);
    set(payloadObject, ['studyPeriod'], studyPeriod);
  }
  return body;
};

export default addContextInPayloadObjects;
