import * as React from 'react';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { isObject, get, size } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import FileImportModal from '../../../../../../../common/components/file_import_modal/FileImportModal';

import UpdateCostCentersModal from './UpdateCostCenterModal';
import { updateCostCentersQNQAction } from '../redux/updateCostCentersQnq';
import { exportDataAction } from '../../../../../../../common/redux/export';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
} from '../../../../../../../common/config/api_constants';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../../../common/constants/study_period';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  SUPPORTED_IMPORT_TYPES,
} from '../../../../../../import_data/constants/import_types';
import { uploadFileToS3Action } from '../../../../../../../common/components/file_importer/redux/actions/UploadFileToS3';
import {
  getImportDropdownItems,
  getUploadToS3PayloadForTriggerImport,
  isImportInProgress,
} from '../../../../../../import_data/utils/import_utils';

const CostCenterActions = ({
  getDataBegin,
  items,
  setSelectedItems,
  query,
  exportDataBegin,
  studyPeriod,
  uploadFileToS3,
  config,
}) => {
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);
  const [isUpdateCostCentersModalVisible, setUpdateCostCentersModalVisibility] = React.useState(
    false,
  );
  const [fileImportType, setFileImportType] = React.useState(null);

  // eslint-disable-next-line
  const showFileImportModal = ({ detail }) => {
    setFileImportType(detail.id);
    setFileImportModalVisibility(true);
  };

  const onCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    // state will contain file details in props
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType,
      }),
    );
  };

  const showUpdateCostCentersModal = () => {
    setUpdateCostCentersModalVisibility(true);
  };

  const onCancelUpdateCostCentersModal = () => {
    setUpdateCostCentersModalVisibility(false);
  };

  const filterSelectedItems = (listOfItems, value, description) => {
    return listOfItems.map(record => {
      return {
        costCenterNumber: record.CostCenterNumber,
        companyOFACode: record.CompanyOFACode,
        costCenterQNQ: value,
        costCenterDescription: description,
      };
    });
  };

  const onConfirmUpdateCostCentersModal = updates => {
    if (isObject(updates)) {
      const { selectedOption, description } = updates;
      const { value } = selectedOption;
      getDataBegin({
        body: {
          businessId: DEFAULT_BUSINESS_ID,
          year: studyPeriod,
          costCentersUpdateList: filterSelectedItems(items, value, description),
        },
      });
    }
    setSelectedItems([]);
    setUpdateCostCentersModalVisibility(false);
  };

  const areAllCostCentersSame = () => {
    const costCenterTypes = new Set();
    items.map(({ CostCenterQNQ }) => costCenterTypes.add(CostCenterQNQ));
    if (size(costCenterTypes) === 1) return true;
    return false;
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'COST_CENTERS_EXTRACT',
        isJSON: false,
        query: {
          StudyPeriod: studyPeriod,
          Country: DEFAULT_COUNTRY,
          BusinessId: DEFAULT_BUSINESS_ID,
          query,
        },
      },
    });
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <ButtonDropdown
        items={getImportDropdownItems(SUPPORTED_IMPORT_TYPES.QNQ_CC, config)}
        onItemClick={showFileImportModal}
        disabled={isActionDisabledForStudyPeriod(studyPeriod) || isImportInProgress(config)}
      >
        Import
      </ButtonDropdown>
      <Button onClick={onExportHandler}>Export</Button>
      <Button
        disabled={!areAllCostCentersSame() || isActionDisabledForStudyPeriod(studyPeriod)}
        onClick={showUpdateCostCentersModal}
      >
        Update
      </Button>

      <UpdateCostCentersModal
        items={items}
        isVisible={isUpdateCostCentersModalVisible}
        onDismiss={onCancelUpdateCostCentersModal}
        onConfirm={onConfirmUpdateCostCentersModal}
        onCancel={onCancelUpdateCostCentersModal}
      />
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={onCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={onCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};

CostCenterActions.propTypes = {
  items: PropTypes.array.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isFetchingData: get(state, 'entities.qnq.updateCostCenters.isFetching', false) || false,
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: updateCostCentersQNQAction.BEGIN,
      clearData: updateCostCentersQNQAction.CLEAR,
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CostCenterActions);
