import { createAction } from 'redux-actions';

export const createAPILifecycleActionTypes = apiActionType => {
  if (!apiActionType) {
    return null;
  }

  return {
    NAME: apiActionType,
    BEGIN: `${apiActionType}_BEGIN`,
    SUCCESS: `${apiActionType}_SUCCESS`,
    FAILURE: `${apiActionType}_FAILURE`,
    CLEAR: `${apiActionType}_CLEAR`,
  };
};

export const createAPILifecycleActions = apiActionType => {
  if (!apiActionType) {
    return null;
  }

  return {
    BEGIN: createAction(`${apiActionType}_BEGIN`),
    SUCCESS: createAction(`${apiActionType}_SUCCESS`),
    FAILURE: createAction(`${apiActionType}_FAILURE`),
    CLEAR: createAction(`${apiActionType}_CLEAR`),
  };
};
