import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../constants/route_paths';
import { STUDY_PERIOD } from '../../../../constants/study_period';

export const SIGN_OUT_HREF = '#/signout';

export const getNavBarHeaderConfig = studyPeriod => {
  return {
    text: 'Radical',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod),
  };
};

const getItemsForStudyPeriod = studyPeriod => [
  {
    type: 'link',
    text: 'QNQ',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.QNQ_PAGE, studyPeriod),
  },
  {
    type: 'link-group',
    text: 'Population',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.POPULATION_PAGE, studyPeriod),
    items: [
      {
        type: 'link',
        text: 'Grouping',
        href: getRoutePathWithStudyPeriod(ROUTE_PATH.GROUPING_PAGE, studyPeriod),
      },
    ],
  },
  {
    type: 'link',
    text: 'Surveys',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriod),
  },
  {
    type: 'link',
    text: 'QRE',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.QRE_PAGE, studyPeriod),
  },
  {
    type: 'link',
    text: 'Notification',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_PAGE, studyPeriod),
  },
  {
    type: 'link',
    text: 'Analytics',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.ANALYTICS_PAGE, studyPeriod),
  },
  // { type: 'link', text: 'Audit log' },
  // { type: 'divider' },
  // { type: 'link', text: 'Past study periods' },
  // { type: 'divider' },
];

function getNavbarItemsForStudyPeriod(studyPeriod) {
  return {
    type: 'expandable-link-group',
    href: getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod),
    text: `Study Period ${studyPeriod}`,
    items: getItemsForStudyPeriod(studyPeriod),
  };
}

export const NAVBAR_ITEMS_CONFIG = [
  getNavbarItemsForStudyPeriod(STUDY_PERIOD[2023]),
  { type: 'divider' },
  getNavbarItemsForStudyPeriod(STUDY_PERIOD[2022]),
  { type: 'divider' },
  getNavbarItemsForStudyPeriod(STUDY_PERIOD[2021]),
  // {
  //   type: 'expandable-link-group',
  //   text: 'Templates',
  //   items: [
  //     {
  //       type: 'link',
  //       text: 'Survey',
  //     },
  //     {
  //       type: 'link',
  //       text: 'Notification',
  //     },
  //     { type: 'divider' },
  //     {
  //       type: 'link',
  //       text: 'User Permission',
  //     },
  //   ],
  // },
  { type: 'divider' },
  {
    type: 'link',
    text: 'Sign out',
    href: SIGN_OUT_HREF,
  },
];
