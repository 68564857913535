import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
  },
  name: {
    paddingTop: 10,
  },
  description: {
    paddingTop: 3,
    paddingLeft: 10,
  },
});

export function ProjectList({ data }) {
  const { section, name, description } = styles;
  const selectedProjectList = get(data, 'userResponse.Project.data.selectedProjects', []);
  const { searchProjectsResponse } = data;

  const projects = selectedProjectList.map((selectedProject, index) => {
    const searchedProject = get(searchProjectsResponse, [selectedProject.id], {});
    return (
      <View>
        <Text style={name}>
          {index + 1}.{' '}
          {isEmpty(searchedProject) ? selectedProject.name : searchedProject.projectName}
        </Text>
        <Text style={description}>
          {isEmpty(searchedProject)
            ? selectedProject.description
            : searchedProject.projectDescription}
        </Text>
      </View>
    );
  });

  return <View style={section}>{projects}</View>;
}

ProjectList.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProjectList;
