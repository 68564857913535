import ButtonDropdown from '@amzn/awsui-components-react/polaris/button-dropdown';
import PropTypes from 'prop-types';
import * as React from 'react';
import {
  getAggregationSelectorItems,
  getAggregationSelectorText,
} from '../utils/aggregation_utils';

const AggregationSelector = ({ selectedAggId, aggItems, changeAggregation }) => {
  return (
    <ButtonDropdown
      items={getAggregationSelectorItems(aggItems)}
      onItemClick={({ detail: { id } }) => changeAggregation(id)}
    >
      {getAggregationSelectorText(selectedAggId, aggItems)}
    </ButtonDropdown>
  );
};

AggregationSelector.propTypes = {
  selectedAggId: PropTypes.string.isRequired,
  aggItems: PropTypes.array.isRequired,
  changeAggregation: PropTypes.func.isRequired,
};

export default AggregationSelector;
