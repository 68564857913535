import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get, map, size } from 'lodash';
import isEqual from 'lodash/isEqual';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
  ITEM_TYPE_LABOR,
} from '../../../../../../../common/config/api_constants';
import UpdateAllModal from '../../../../common/components/update_all_modal/UpdateAllModal';
import UpdateSelectedModal from '../../../../common/components/update_selected_modal/UpdateSelectedModal';
import { overrideLaborGroupingAction } from '../redux/overrideLaborGrouping';
import { updateLaborGroupingAction } from '../redux/updateLaborGrouping';
import { exportDataAction } from '../../../../../../../common/redux/export';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../../../common/constants/study_period';
import {
  getUploadToS3PayloadForTriggerImport,
  isImportDisabled,
  isImportInProgress,
} from '../../../../../../import_data/utils/import_utils';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  IMPORT_DISABLE_POSSIBLE,
  SUPPORTED_IMPORT_TYPES,
} from '../../../../../../import_data/constants/import_types';
import FileImportModal from '../../../../../../../common/components/file_import_modal/FileImportModal';
import { uploadFileToS3Action } from '../../../../../../../common/components/file_importer/redux/actions/UploadFileToS3';

const LaborActions = ({
  updateAllBegin,
  items,
  updateSelectedBegin,
  exportDataBegin,
  groupingId,
  lastChangedOn,
  setSelectedItems,
  query,
  studyPeriod,
  uploadFileToS3,
  config,
}) => {
  // items constains the list of selected items
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);
  const [isUpdateAllModalVisible, setUpdateAllModalVisibility] = React.useState(false);
  const [isUpdateSelectedModalVisible, setUpdateSelectedModalVisibility] = React.useState(false);

  const showFileImportModal = () => {
    setFileImportModalVisibility(true);
  };

  const onCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    // state will contain file details in props
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType: SUPPORTED_IMPORT_TYPES.LABOR_GROUPING[0],
      }),
    );
  };

  const showUpdateAllModal = () => {
    setUpdateAllModalVisibility(true);
  };

  const onCancelUpdateAllModal = () => {
    // state contains the file details
    setUpdateAllModalVisibility(false);
  };

  const onConfirmUpdateAllModal = async () => {
    // state contains the file details
    updateAllBegin({
      body: {
        itemType: ITEM_TYPE_LABOR,
      },
    });
    setUpdateAllModalVisibility(false);
  };

  const showUpdateSelectedModal = () => {
    setUpdateSelectedModalVisibility(true);
  };
  const onCancelUpdateSelectedModal = () => {
    // state contains the file details
    setSelectedItems([]);
    setUpdateSelectedModalVisibility(false);
  };
  const getReasonPath = selectedQualification => {
    if (isEqual(selectedQualification, 'Q')) {
      return 'selectedReasonForQ.label';
    }
    return 'selectedReasonForNQ.label';
  };
  const filterSelectedItems = updates => {
    return map(items, record => {
      return {
        subordinateRecordId: get(record, 'partitionKey', null),
        updatedOn: get(record, 'updatedOn', null),
        oldQualificationStatus: get(record, 'employeeQNQ', null),
        newQualificationStatus: get(updates, 'selectedQualification', null),
        oldSmeRecordId: get(record, 'smeRecordId', null),
        newSmeRecordId: get(updates, 'selectedSME.recordId', null),
        reason: get(updates, getReasonPath(get(updates, 'selectedQualification', null)), null),
        surveyId: get(updates, ['selectedSMESurvey', 'surveyId'], null),
        operationSource: 'UI',
        applicableEntitySection: get(updates, ['selectedPopulation', 'label'], null),
      };
    });
  };

  const onConfirmUpdateSelectedModal = updates => {
    updateSelectedBegin({
      body: {
        itemType: ITEM_TYPE_LABOR,
        groupingId,
        lastChangedOn,
        subordinates: filterSelectedItems(updates),
      },
    });
    setSelectedItems([]);
    setUpdateSelectedModalVisibility(false);
  };

  const showUpdateSelected = () => {
    const laborTypes = new Set();
    items.map(({ employeeQNQ }) => laborTypes.add(employeeQNQ));
    if (size(laborTypes) === 1) return false;
    return true;
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'GROUPING_LABOR_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          regulatoryRegion: DEFAULT_COUNTRY,
          itemType: ITEM_TYPE_LABOR,
          clientId: DEFAULT_BUSINESS_ID,
          sortKey: groupingId,
          query,
        },
      },
    });
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={onExportHandler}>Export</Button>
      <Button
        onClick={showFileImportModal}
        disabled={
          isActionDisabledForStudyPeriod(studyPeriod) ||
          isImportInProgress(config) ||
          (isImportDisabled(config) &&
            IMPORT_DISABLE_POSSIBLE[SUPPORTED_IMPORT_TYPES.LABOR_GROUPING[0]])
        }
      >
        Import
      </Button>
      <Button
        onClick={showUpdateSelectedModal}
        disabled={
          showUpdateSelected() ||
          isActionDisabledForStudyPeriod(studyPeriod) ||
          isImportInProgress(config)
        }
      >
        Update selected
      </Button>
      <Button
        onClick={showUpdateAllModal}
        disabled={isActionDisabledForStudyPeriod(studyPeriod) || isImportInProgress(config)}
      >
        Update all
      </Button>

      <UpdateAllModal
        isVisible={isUpdateAllModalVisible}
        onDismiss={onCancelUpdateAllModal}
        onConfirm={onConfirmUpdateAllModal}
        onCancel={onCancelUpdateAllModal}
      />
      <UpdateSelectedModal
        itemType={ITEM_TYPE_LABOR}
        entityType="Employee"
        selectedEmployees={size(items)}
        isVisible={isUpdateSelectedModalVisible}
        onDismiss={onCancelUpdateSelectedModal}
        onConfirm={onConfirmUpdateSelectedModal}
        onCancel={onCancelUpdateSelectedModal}
      />
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={onCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={onCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};

LaborActions.propTypes = {
  updateAllBegin: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  updateSelectedBegin: PropTypes.func.isRequired,
  groupingId: PropTypes.string,
  lastChangedOn: PropTypes.string,
  setSelectedItems: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

LaborActions.defaultProps = {
  groupingId: null,
  lastChangedOn: null,
};

const mapStateToProps = state => {
  return {
    groupingId: get(state, 'entities.grouping.labor.data.groupingId', null) || null,
    lastChangedOn: get(state, 'entities.grouping.labor.data.lastChangedOn', null) || null,
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAllBegin: updateLaborGroupingAction.BEGIN,
      updateSelectedBegin: overrideLaborGroupingAction.BEGIN,
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(LaborActions);
