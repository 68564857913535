import { Box, Header, SpaceBetween, Tabs, Button } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import RecipientsTab from './components/recipients_tab/RecipientsTab';
import NotificationsOverview from './components/Overview/NotificationsOverview';
import Page from '../../../common/components/with_page/Page';
import { RESOURCE } from '../../auth/constants/rbac_constants';
import { BRC_ITEMS_PAGE } from '../../../common/components/with_page/components/bread_crumb/constants';
import CCTab from './components/cc_tab/CCTab';
import { getStudyPeriod } from '../../../common/constants/study_period';
import { getNotificationStatusType } from './common/util/utils';
import { NOTIFICATION_STATUS_TYPES } from './common/constants/constants';
import NotificationsDeleteModal from './components/Overview/NotificationsDeleteModal';
import { deleteNotificationAction } from './components/Overview/redux/deleteNotifications';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../common/constants/route_paths';
import { createNotificationScheduleAction } from './components/Overview/redux/createNotificationSchedule';
import {
  getNotificationDetailsAction,
  notificationDetailActions,
} from './components/Overview/redux/getNotificationDetails';
import { cancelScheduledNotificationAction } from './components/Overview/redux/cancelScheduledNotification';
import NotificationContentTab from './components/notification_content_tab/NotificationContentTab';

const NotificationsPage = ({
  history,
  studyPeriod,
  deleteNotificationBegin,
  navigateTo,
  createNotificationScheduledBegin,
  getNotificationDetailsBegin,
  notificationDetailsData,
  setNotificationStatus,
  cancelScheduledNotification,
}) => {
  const notificationId = get(history, 'location.state', '') || '';
  useEffect(() => {
    getNotificationDetailsBegin({
      body: {
        notificationId,
      },
    });
  }, [getNotificationDetailsBegin, notificationId]);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };
  const onCancelDeleteEvent = () => {
    setIsDeleteModalVisible(false);
  };
  const onConfirmDeleteEvent = () => {
    deleteNotificationBegin({
      body: {
        notificationId,
      },
      onSuccess: () => {
        navigateTo({
          pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_PAGE, studyPeriod),
        });
      },
    });
  };
  const confirmScheduledDeliveryHandler = () => {
    createNotificationScheduledBegin({
      body: {
        notificationId,
      },
      onSuccess: updatedStatus => {
        setNotificationStatus(updatedStatus);
      },
    });
  };
  const editNotificationHandler = () => {
    navigateTo({
      pathname: getRoutePathWithStudyPeriod(ROUTE_PATH.CREATE_NOTIFICATION, studyPeriod),
      state: notificationDetailsData,
    });
  };
  const cancelScheduledHandler = () => {
    cancelScheduledNotification({
      body: {
        notificationId,
      },
      onSuccess: updatedStatus => {
        setNotificationStatus(updatedStatus);
      },
    });
  };
  const getNotificationActions = () => {
    const status = get(notificationDetailsData, 'status', 'UNKNOWN');
    const statusType = getNotificationStatusType(status);
    if (statusType === NOTIFICATION_STATUS_TYPES.IN_EDIT) {
      return (
        <SpaceBetween size="xs" direction="horizontal">
          <Button onClick={showDeleteModal}>Delete</Button>
          <Button onClick={editNotificationHandler}>Edit</Button>
          <Button variant="primary" onClick={confirmScheduledDeliveryHandler}>
            Confirm scheduled delivery
          </Button>
        </SpaceBetween>
      );
    }
    if (
      statusType === NOTIFICATION_STATUS_TYPES.READY ||
      statusType === NOTIFICATION_STATUS_TYPES.IN_PROGRESS
    ) {
      return <Button onClick={cancelScheduledHandler}>Cancel scheduled delivery</Button>;
    }
    if (
      statusType === NOTIFICATION_STATUS_TYPES.X_PERCENT_DELIVERED &&
      status !== '100% delivered'
    ) {
      return <Button onClick={cancelScheduledHandler}>Cancel ongoing delivery</Button>;
    }
    return <div></div>;
  };
  const content = (
    <SpaceBetween size="l">
      <Header variant="h1" actions={getNotificationActions()}>
        {get(notificationDetailsData, 'notificationName', '')}
      </Header>
      <div>
        <NotificationsOverview details={notificationDetailsData} />
        <NotificationsDeleteModal
          onCancelHandler={onCancelDeleteEvent}
          onConfirmHandler={onConfirmDeleteEvent}
          isModalVisible={isDeleteModalVisible}
          notificationName={get(notificationDetailsData, 'notificationName', '')}
        />
      </div>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'Recipients',
              id: 'first',
              content: <RecipientsTab notificationId={notificationId} />,
            },
            {
              label: 'CC',
              id: 'second',
              content: <CCTab notificationId={notificationId} />,
            },
            {
              label: 'Notification content',
              id: 'third',
              content: <NotificationContentTab templateId={notificationDetailsData.templateId} />,
            },
          ]}
        />
      </Box>
    </SpaceBetween>
  );
  return (
    <Page
      PageId={RESOURCE.PAGE_NOTIFICATIONS}
      isPageAccessControlled
      PageContent={content}
      breadCrumbItems={[
        ...BRC_ITEMS_PAGE.NOTIFICATION_DETAILS(studyPeriod),
        { text: get(notificationDetailsData, 'notificationName', '') },
      ]}
    />
  );
};

NotificationsPage.propTypes = {
  history: PropTypes.object.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  deleteNotificationBegin: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  createNotificationScheduledBegin: PropTypes.func.isRequired,
  getNotificationDetailsBegin: PropTypes.func.isRequired,
  notificationDetailsData: PropTypes.object.isRequired,
  setNotificationStatus: PropTypes.func.isRequired,
  cancelScheduledNotification: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
  notificationDetailsData: get(
    state,
    'entities.notifications.getNotificationDetails.data.notification',
    {},
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      deleteNotificationBegin: deleteNotificationAction.BEGIN,
      navigateTo: payload => push(payload),
      createNotificationScheduledBegin: createNotificationScheduleAction.BEGIN,
      getNotificationDetailsBegin: getNotificationDetailsAction.BEGIN,
      setNotificationStatus: notificationDetailActions.SET_NOTIFICATION_STATUS,
      cancelScheduledNotification: cancelScheduledNotificationAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsPage);
