import {
  colorChartsBlue1300,
  colorChartsGreen300,
  colorChartsRed300,
  colorChartsYellow300,
} from '@amzn/awsui-components-react/polaris/internal/generated/styles/tokens';

const QRE_SUM_AGG_TYPES = {
  LABOR_PRE_80_RULE: {
    label: 'Labor pre-80% rule',
    key: 'sumByLaborFederalActivityPre80',
    color: colorChartsRed300,
  },
  LABOR_ADDED_BENEFIT_RULE: {
    label: '80% Added Benefit',
    key: 'sumByAdditionalBenefits',
    color: colorChartsBlue1300,
  },
  CONTRACT: {
    label: 'Contract',
    key: 'sumByContractFederal',
    color: colorChartsGreen300,
  },
  SUPPLIES: {
    label: 'Supplies',
    key: 'sumBySuppliesFederal',
    color: colorChartsYellow300,
  },
};

export const QRE_SUMMARY_ATTRIBUTES = [
  'sumByLaborFederalActivityPre80',
  'sumByAdditionalBenefits',
  'sumByContractFederal',
  'sumBySuppliesFederal',
];

export default QRE_SUM_AGG_TYPES;
