import { get, isEmpty, isNull } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../../../../common/components/table/components/Table';
import { setPageNotification } from '../../../../../../../common/components/with_page/redux/reducer';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../../import_data/constants/import_types';
import {
  getImportFailureNotification,
  getImportTypeMetadataForHeader,
  processUserLevelImportMetadata,
} from '../../../../../../import_data/utils/import_utils';
import employeeTableConfig from '../constants/employee_table_config';
import { searchLaborPopulationActions } from '../redux/population';
import { getImportMetadataAction } from '../../../../../../import_data/redux/GetImportMetadata';

const EmployeeTable = props => {
  const { importMetadata, pushNotification } = props;
  const supportedImportTypes = SUPPORTED_IMPORT_TYPES.POPULATION_LABOR;
  const [latestImportTypeMetadata, setLatestImportTypeMetadata] = React.useState(null);

  useEffect(() => {
    if (!isEmpty(importMetadata)) {
      const importTypeMetadata = getImportTypeMetadataForHeader(
        importMetadata,
        supportedImportTypes,
      );
      setLatestImportTypeMetadata(importTypeMetadata);

      const userLevelImportMetadata = processUserLevelImportMetadata(importMetadata);
      if (!isNull(userLevelImportMetadata)) {
        pushNotification(
          getImportFailureNotification({
            tableIdentifier: 'EmployeeTable',
            ...userLevelImportMetadata,
            supportedImportTypes,
          }),
        );
      }
    }
  }, [importMetadata, supportedImportTypes, pushNotification]);

  return (
    <Table
      {...props}
      config={employeeTableConfig({ importTypeMetadata: latestImportTypeMetadata })}
    />
  );
};

EmployeeTable.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  totalResultCount: PropTypes.number.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  importMetadata: PropTypes.array.isRequired,
  pushNotification: PropTypes.func.isRequired,
  getImportDataBegin: PropTypes.func.isRequired,
  getImportDataClear: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  schema: get(state, 'entities.schemas.data.pages.population.labor', []) || [],
  data: get(state, 'entities.population.labor.population.data', {}) || {},
  totalResultCount:
    get(state, 'entities.population.labor.population.data.totalResultCount', 0) || 0,
  isFetchingData: get(state, 'entities.population.labor.population.isFetching', false) || false,
  importMetadata: get(state, 'entities.getImportMetadata.data.body.importMetadata', []) || [],
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchLaborPopulationActions.BEGIN,
      clearData: searchLaborPopulationActions.CLEAR,
      pushNotification: setPageNotification,
      getImportDataBegin: getImportMetadataAction.BEGIN,
      getImportDataClear: getImportMetadataAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeTable);
