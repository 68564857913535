import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { BreadcrumbGroup } from '@amzn/awsui-components-react/polaris';

const BreadCrumb = props => {
  const onClickHandler = event => {
    event.preventDefault();

    if (event.detail.href && props) {
      const { history } = props;
      const { href } = event.detail;
      if (history) {
        history.push(href);
      }
    }
  };

  const { items } = props;
  return items ? <BreadcrumbGroup onClick={onClickHandler} items={items} /> : null;
};

BreadCrumb.propTypes = {
  history: PropTypes.object.isRequired,
  items: PropTypes.array,
};

BreadCrumb.defaultProps = {
  items: [],
};

export default withRouter(BreadCrumb);
