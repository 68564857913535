import get from 'lodash/get';
import isObject from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import * as React from 'react';
import { Grid } from '@amzn/awsui-components-react';
import ValueWithLabel from '../../../../../../common/components/value_with_label/ValueWithLabel';
import QRE_SUM_AGG_TYPES from '../../constants/qreTypes';

const getFormattedOverviewCount = (viewType, qreTypeQRE, totalQRE) => {
  if (isEqual(totalQRE, 0)) {
    return '0%';
  }

  if (isEqual(viewType, 'percentage')) {
    return `${((qreTypeQRE / totalQRE) * 100).toFixed(0)}%`;
  }
  return qreTypeQRE.toLocaleString('en-US', { maximumFractionDigits: 2 });
};

const getOverview = (mergedAggByFederalQRE, viewType) => {
  if (isObject(mergedAggByFederalQRE) || isEmpty(mergedAggByFederalQRE)) {
    return null;
  }

  const totalQRE = reduce(mergedAggByFederalQRE, (accumulator, qre) => accumulator + qre);

  return (
    <Grid
      gridDefinition={[
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
      ]}
    >
      {map(QRE_SUM_AGG_TYPES, ({ label, key }) => {
        return (
          <div className="right-divider" key={label}>
            <ValueWithLabel key={label} label={label}>
              {getFormattedOverviewCount(viewType, get(mergedAggByFederalQRE, [key], 0), totalQRE)}
            </ValueWithLabel>
          </div>
        );
      })}
      <ValueWithLabel key="Total" label="Total">
        {getFormattedOverviewCount(viewType, totalQRE, totalQRE)}
      </ValueWithLabel>
    </Grid>
  );
};

export default getOverview;
