import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import {
  Table,
  TableCell,
  TableHeader,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import PropTypes from 'prop-types';
import { isEmpty, isNull } from 'lodash';

const styles = StyleSheet.create({
  table: {
    paddingLeft: 3,
    paddingTop: 6,
  },
  tableHeader: {
    backgroundColor: '#C8C8C8',
    fontSize: 11,
    paddingLeft: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableCell: {
    fontSize: 11,
    paddingLeft: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
});

export function SurveyEmployeeListTable({ employeeData }) {
  const { table, tableHeader, tableCell } = styles;
  if (!isNull(employeeData) && !isEmpty(employeeData)) {
    employeeData.sort((employee1, employee2) =>
      employee1.employeeName < employee2.employeeName ? -1 : 1,
    );
    return (
      <View style={table}>
        <Table data={employeeData}>
          <TableHeader>
            <TableCell style={tableHeader}>Employee Name</TableCell>
            <TableCell style={tableHeader}>Job Title</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell style={tableCell} getContent={r => r.employeeName} />
            <DataTableCell style={tableCell} getContent={r => r.jobTitle} />
          </TableBody>
        </Table>
      </View>
    );
  }

  return <View />;
}

SurveyEmployeeListTable.propTypes = {
  employeeData: PropTypes.object.isRequired,
};

export default SurveyEmployeeListTable;
