import { createAction } from 'redux-actions';
import createPutFileInS3Epic from '../epics/putFileInS3Util';

const PUT_FILE_IN_S3_ACTION_NAME = 'PUT_FILE_IN_S3_ACTION';
export const putFileInS3Action = createAction(PUT_FILE_IN_S3_ACTION_NAME);

const successCallback = (response, store, action) => {
  const { onUploadSuccess } = action.payload;
  const { action: onUploadSuccessAction, payload: onUploadSuccessPayload } = onUploadSuccess;
  return [onUploadSuccessAction(onUploadSuccessPayload)];
};

const errorCallback = (response, store, action) => {
  const { onUploadFailure } = action.payload;
  const { action: onUploadFailureAction } = onUploadFailure;
  return [onUploadFailureAction()];
};

export const putFileInS3Epic = createPutFileInS3Epic({
  actionType: PUT_FILE_IN_S3_ACTION_NAME,
  successCallback,
  errorCallback,
});
