import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid, Select } from '@amzn/awsui-components-react';
import { sortedUniq, map, get, values } from 'lodash';
import alertText from './constants/deactivate_modal_constants';

const DeactivateModal = props => {
  const { isVisible, onDismiss, onConfirm, onCancel, records, deactivationReasons } = props;
  const getSMEs = () => {
    return sortedUniq(map(records, ({ userName }) => userName)).join(', ');
  };
  const initialState = {
    selectedReason: null,
    isConfirmDisabled: true,
  };
  const [state, setState] = useState(initialState);
  const clearSelectedReason = () => {
    setState({ ...state, selectedReason: null, isConfirmDisabled: true });
  };
  const DEACTIVATION_REASONS = map(deactivationReasons, deactivationReason => {
    return { label: deactivationReason };
  });
  const onConfirmHandler = () => {
    onConfirm(records, state.selectedReason);
    clearSelectedReason();
  };
  const onCancelHandler = () => {
    onCancel();
    clearSelectedReason();
  };
  const onDismissHandler = () => {
    onDismiss();
    clearSelectedReason();
  };

  const setSelectedReason = selectedReason => {
    setState({ ...state, selectedReason, isConfirmDisabled: false });
  };
  return (
    <Modal
      onDismiss={onDismissHandler}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler} disabled={state.isConfirmDisabled}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Deactivate"
    >
      <SpaceBetween size="l">
        <Box variant="p">
          Are you sure you want to deactivate survey(s) of the following SME(s)?
          <Box variant="p">{getSMEs()}</Box>
        </Box>
        <div>
          <Grid gridDefinition={[{ colspan: 12 }]}>
            <Box>
              Choose the reason
              <Select
                placeholder="Select One"
                selectedOption={state.selectedReason}
                onChange={({ detail }) => setSelectedReason(detail.selectedOption)}
                options={values(DEACTIVATION_REASONS)}
                selectedAriaLabel="Selected"
              />
            </Box>
          </Grid>
        </div>
        <Alert type="warning">{alertText(get(records, [0, 'surveyType'], ''))}</Alert>
      </SpaceBetween>
    </Modal>
  );
};

DeactivateModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  deactivationReasons: PropTypes.array.isRequired,
};

export default DeactivateModal;
