import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Select } from '@amzn/awsui-components-react';
import { filter, values, get } from 'lodash';
import size from 'lodash/size';

const UpdateCostCentersModal = props => {
  const { isVisible, onDismiss, onConfirm, onCancel, items } = props;

  const COST_CENTER_OPTIONS = {
    QUALIFIED: { label: 'Qualified', value: 'Q' },
    NOT_QUALIFIED: { label: 'Non Qualified', value: 'NQ' },
  };

  const initialState = {
    selectedOption: COST_CENTER_OPTIONS.QUALIFIED,
    description: '',
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    setState({
      description: get(items, [0, 'CostCenterDescription'], ''),
      selectedOption: filter(
        COST_CENTER_OPTIONS,
        ({ value }) => value === get(items, [0, 'CostCenterQNQ']),
      )[0],
    });
    // eslint-disable-next-line
  }, [items]);

  const setSelectedOption = selectedOption => {
    setState({ ...state, selectedOption });
  };

  const setDescription = description => {
    setState({ ...state, description });
  };

  const onCancelHandler = () => {
    onCancel();
    setState(initialState);
  };

  const onConfirmHandler = () => {
    onConfirm(state);
    setState(initialState);
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Update Cost Centers"
    >
      <SpaceBetween size="l">
        <Box variant="p">
          The information of the {size(items)} selected Cost centers will be updated.
        </Box>
        <Grid gridDefinition={[{ colspan: 6 }]}>
          <Box>
            QNQ
            <Select
              selectedOption={state.selectedOption}
              onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
              options={values(COST_CENTER_OPTIONS)}
              selectedAriaLabel="Selected"
            />
          </Box>
        </Grid>
        <Grid gridDefinition={[{ colspan: 6 }]}>
          <Box>
            Description
            <Input
              onChange={({ detail }) => setDescription(detail.value)}
              value={state.description}
              inputMode="text"
            />
          </Box>
        </Grid>
      </SpaceBetween>
    </Modal>
  );
};

UpdateCostCentersModal.propTypes = {
  items: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default UpdateCostCentersModal;
