import { createAction } from 'redux-actions';
import { get } from 'lodash';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../../redux/actions/action_utils';
import { GET_NOTIFICATION_DETAILS } from '../../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../../redux/epics/utils';

export const GET_NOTIFICATION_DETAILS_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_NOTIFICATION_DETAILS_ACTION_TYPES',
);
export const getNotificationDetailsAction = createAPILifecycleActions(
  GET_NOTIFICATION_DETAILS_ACTION_TYPES.NAME,
);

export const NOTIFICATION_DETAIL_ACTION_TYPES = {
  NAME: 'NOTIFICATION_DETAIL_ACTION_TYPES',
  SET_NOTIFICATION_STATUS: 'SET_NOTIFICATION_STATUS_IN_NOTIFICATION_DETAIL',
};

export const notificationDetailActions = {
  SET_NOTIFICATION_STATUS: createAction(NOTIFICATION_DETAIL_ACTION_TYPES.SET_NOTIFICATION_STATUS),
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload.body,
        error: null,
      };
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.CLEAR:
      return initialState;
    case NOTIFICATION_DETAIL_ACTION_TYPES.SET_NOTIFICATION_STATUS: {
      const prevData = state.data;
      const notification = get(prevData, 'notification', {});
      return {
        ...state,
        data: {
          ...prevData,
          notification: {
            ...notification,
            status: payload,
          },
        },
      };
    }
    default:
      return state;
  }
};

export const getNotificationDetailsEpic = createAmplifyAPICallEpic({
  actionType: GET_NOTIFICATION_DETAILS_ACTION_TYPES.BEGIN,
  api: GET_NOTIFICATION_DETAILS,
  successCallback: response => [getNotificationDetailsAction.SUCCESS(response)],
  errorCallback: () => [getNotificationDetailsAction.FAILURE()],
});
