import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
  instructionText: {
    color: '#006080',
  },
});

export function EmployeeProjectMatrixSectionDescription() {
  const { section } = styles;

  return (
    <View style={section}>
      <Text>
        Enter the percentage of time each employee spent on the project(s). You must allocate 100%
        of each employee’s time for the survey to be considered complete. Alternatively, download
        the matrix, fill it out offline and upload back. Do not change the format of downloaded
        file.
      </Text>
    </View>
  );
}

export default EmployeeProjectMatrixSectionDescription;
