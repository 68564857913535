export const QUALIFICATIONS = {
  QUALIFIED: { label: 'Qualified', value: 'Q' },
  NOT_QUALIFIED: { label: 'Non-qualified', value: 'NQ' },
};

export const SEARCH_BY_VALUES = [{ value: 'employeeAlias', label: 'Alias' }];

export const SURVEY_STATUS_SUBMITTED = 'Submitted';

export const SURVEY_STATUS_DEACTIVATED = 'Deactivated';

export const DEBOUNCE_DELAY = 1000;
