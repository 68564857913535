import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { UPDATE_QRES_ENDPOINT } from '../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import { configureRefreshStateAction } from '../../../common/redux/configureRefreshState';
import { UPDATE_QRE_DATA_REFRESH_DELAY_TIME_IN_MS } from '../../../common/components/table/constants';

export const UPDATE_QRES_ACTION_TYPES = createAPILifecycleActionTypes('UPDATE_QRES_ACTION_TYPES');
export const updateQREsActions = createAPILifecycleActions(UPDATE_QRES_ACTION_TYPES.NAME);

const initialState = {
  isFetching: false,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_QRES_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_QRES_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: null,
      };
    case UPDATE_QRES_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload,
      };
    default:
      return state;
  }
};

export const updateQREsEpic = createAmplifyAPICallEpic({
  actionType: UPDATE_QRES_ACTION_TYPES.BEGIN,
  api: UPDATE_QRES_ENDPOINT,
  successCallback: response => [
    updateQREsActions.SUCCESS(response.body),
    configureRefreshStateAction.BEGIN(UPDATE_QRE_DATA_REFRESH_DELAY_TIME_IN_MS),
  ],
  errorCallback: () => [updateQREsActions.FAILURE()],
});
