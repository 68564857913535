import { Link, SpaceBetween } from '@amzn/awsui-components-react';
import { get, map, isNull, isEmpty } from 'lodash';
import React from 'react';
import { getSerializedData } from './table_redux_payload_utils';
import {
  getDateInMMDDYYYYWithoutBrowserTimezoneEffect,
  getDateInMMDDYYYYHHMMSSWithBrowserTimezoneEffect,
  getDateInMMDDYYYYWithBrowserTimezoneEffect,
} from '../../../utils/date_time_util';
import { downloadFileFromURL } from '../../../utils/api_util';

const downloadFile = async (event, presignedUrl, fileName) => {
  event.preventDefault();
  downloadFileFromURL({
    presignedURL: presignedUrl,
    fileName,
  });
};

export const getColumnDefinitions = (schema, studyPeriod) => {
  return map(schema, ({ key: id, displayName: header }) => {
    let cell;
    if (id === 'employeeAlias') {
      cell = e => {
        const value = get(e, [id], '');
        return (
          <Link external href={`https://phonetool.amazon.com/users/${value}`}>
            {value}
          </Link>
        );
      };
    } else if (id === 'terminationDate' || id === 'lastHireDate' || id === 'monthEndDate') {
      cell = e => {
        try {
          return getDateInMMDDYYYYWithoutBrowserTimezoneEffect(e[id]);
        } catch (exception) {
          return null;
        }
      };
    } else if (
      id === 'releaseDate' ||
      id === 'surveyStartDate' ||
      id === 'endDate' ||
      id === 'surveyDueDate'
    ) {
      cell = e => {
        try {
          if (studyPeriod > 2022) {
            return getDateInMMDDYYYYWithBrowserTimezoneEffect(e[id]);
          }
          return getDateInMMDDYYYYWithoutBrowserTimezoneEffect(e[id]);
        } catch (exception) {
          return null;
        }
      };
    } else if (id === 'createdOn' || id === 'surveySubmittedDate' || id === 'scheduleDate') {
      cell = e => {
        try {
          return getDateInMMDDYYYYWithBrowserTimezoneEffect(e[id]);
        } catch (exception) {
          return null;
        }
      };
    } else if (
      id === 'updatedDate' ||
      id === 'hrDataAsOfDate' ||
      id === 'payrollDataAsOfDate' ||
      id === 'updatedOn'
    ) {
      cell = e => {
        try {
          return getDateInMMDDYYYYHHMMSSWithBrowserTimezoneEffect(e[id]);
        } catch (exception) {
          return null;
        }
      };
    } else if (id === 'serializedSecondaryAssigneeAliases') {
      cell = e => {
        return (
          <SpaceBetween size="xs" direction="horizontal">
            {' '}
            {map(getSerializedData(get(e, [id])), value => {
              return (
                <Link external href={`https://phonetool.amazon.com/users/${value}`}>
                  {value}
                </Link>
              );
            })}
          </SpaceBetween>
        );
      };
    } else if (id === 'projectDocumentName') {
      cell = e => {
        const value = get(e, [id], '');
        const presignedUrl = get(e, 'downloadPresignedUrl', '');
        if (isNull(value) || isEmpty(value)) {
          return '';
        }
        return (
          // eslint-disable-next-line
          <a href="#" onClick={event => downloadFile(event, presignedUrl, value)}>
            {value}
          </a>
        );
      };
    } else {
      cell = e => e[id];
    }

    return { id, header, cell };
  });
};

// TODO : Remove when there are more than 1 exports from this file
export const dummy = null;
