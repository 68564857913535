import { Table as PolarisTable } from '@amzn/awsui-components-react';
import { filter, get, has, isEmpty, map, omitBy, size, isNaN } from 'lodash';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSchemaActions } from '../../../../redux/reducers/entities/schemas';
import { DEFAULT_TABLE_PAGE_SIZE } from '../constants';
import { getColumnDefinitions } from '../utils';
import TEmptyView from './TEmptyView';
import TFilter, { getQueryString } from './TFilter';
import THeader from './THeader';
import TPagination from './TPagination';
import StudyPeriodComponent from '../../study_period/StudyPeriodComponent';
import { getStudyPeriod } from '../../../constants/study_period';
import { getReportRefreshStatusAction } from '../../../../app/analytics/components/report_table/redux/GetReportRefreshStatus';
import { getReportRefreshStatusRequest } from '../../../../app/analytics/constants/api_constants';
import { REPORT_TRACKING_ID } from '../../../../app/analytics/components/report_table/constants/report_table_config';
import addContextInPayloadObjects from '../../../../app/analytics/components/utils/ApiUtils';
import { configureRefreshStateAction } from '../../../redux/configureRefreshState';

class Table extends StudyPeriodComponent {
  static EMPTY_QUERY = {
    tokens: [],
    operation: 'and',
  };

  state = {
    collectionPreferences: null,
    currentPageIndex: 1,
    query: Table.EMPTY_QUERY,
    selectedItems: [],
  };

  timeoutId = null;

  componentDidMount() {
    const { schema, getSchemasBegin } = this.props;
    if (!schema || isEmpty(schema)) {
      getSchemasBegin();
    }
    this.fetchData();
  }

  componentDidUpdate(prevProps, prevState) {
    super.componentDidUpdate(prevProps);
    const { collectionPreferences: oldCPs } = prevState;
    const { schema: newSchema } = this.props;
    if (!oldCPs && !isEmpty(newSchema)) {
      this.initializeCollectionPreferences();
    }
    const prevIsRefreshingData = get(prevProps, 'isRefreshingData', false);
    const { isRefreshingData, clearRefreshState, refreshDelayTime } = this.props;
    if (prevIsRefreshingData === false && isRefreshingData === true) {
      this.timeoutId = setTimeout(() => {
        clearRefreshState();
        this.refreshData();
      }, refreshDelayTime);
    }
  }

  componentWillUnmount() {
    const { clearRefreshState } = this.props;
    clearRefreshState();
    clearTimeout(this.timeoutId);
    this.clearData();
  }

  fetchData() {
    this.fetchDataWithQuery(Table.EMPTY_QUERY);
  }

  fetchDataWithQuery(query) {
    const currentPageIndex = 1;
    this.setState({ currentPageIndex });
    this.fetchPage(currentPageIndex, getQueryString(query));
  }

  clearData() {
    this.setState({ query: Table.EMPTY_QUERY });
    const { clearData: clearDataProp, getImportDataClear } = this.props;
    if (getImportDataClear) {
      getImportDataClear();
    }
    clearDataProp();
  }

  fetchPage = (pageIndex, queryString) => {
    this.setSelectedItems([]);
    const {
      getDataBegin,
      getImportDataBegin,
      config: { pageSize, getDataRequestBody, getImportDataRequestBody },
    } = this.props;
    getDataBegin({
      body: {
        ...getDataRequestBody(),
        pageSize,
        pageNumber: pageIndex,
        query: queryString,
      },
    });

    if (getImportDataBegin && getImportDataRequestBody) {
      getImportDataBegin({
        ...getImportDataRequestBody(),
      });
    }

    const {
      config: { dataTrackingId },
      getReportRefreshStatusBegin,
      studyPeriod,
    } = this.props;
    if (isEqual(dataTrackingId, REPORT_TRACKING_ID)) {
      getReportRefreshStatusBegin({
        body: {
          reports: addContextInPayloadObjects(getReportRefreshStatusRequest(), studyPeriod),
        },
      });
    }
  };

  refreshData = () => {
    const { clearData: clearDataProp, getImportDataClear } = this.props;

    if (getImportDataClear) {
      getImportDataClear();
    }

    clearDataProp();

    const { query } = this.state;
    this.fetchDataWithQuery(query);
  };

  setSelectedItems = itemsList => {
    this.setState({ selectedItems: itemsList });
  };

  setCollectionPreferences = collectionPreferences => {
    this.setState({ collectionPreferences });
  };

  setCurrentPageIndex = currentPageIndex => {
    const { query } = this.state;
    const { data } = this.props;
    this.setState({ currentPageIndex });
    this.setSelectedItems([]);
    if (!has(data, currentPageIndex)) {
      this.fetchPage(currentPageIndex, getQueryString(query));
    }
  };

  setQuery = query => {
    const { clearData: clearDataProp } = this.props;
    clearDataProp();

    this.setState({ query }, () => {
      this.fetchDataWithQuery(query);
    });
  };

  getItemsForPage = pageIndex => {
    return get(this.props, ['data', pageIndex], []);
  };

  getVisibleColumns = () => {
    return get(this.state, 'collectionPreferences.visibleContent', []);
  };

  isCurrentPageLast = () => {
    const { currentPageIndex } = this.state;
    const currentPageSize = size(this.getItemsForPage(currentPageIndex));
    return currentPageSize < DEFAULT_TABLE_PAGE_SIZE;
  };

  isTableLoading = () => {
    const { isFetchingSchemas, isFetchingData, isRefreshingData } = this.props;
    return isFetchingSchemas || isFetchingData || isRefreshingData;
  };

  getHeader = () => {
    const { selectedItems, collectionPreferences, query } = this.state;
    const { isFetchingSchemas, isFetchingData, isRefreshingData } = this.props;
    let totalItems = get(this.props, ['totalResultCount'], 0);
    if (isFetchingSchemas || isFetchingData || isRefreshingData) {
      totalItems = 0;
    }
    const headerConfig = get(this.props, ['config', 'headerConfig'], '');
    return (
      <THeader
        refreshData={this.refreshData}
        count={totalItems}
        config={headerConfig}
        query={getQueryString(query)}
        selectedItems={selectedItems}
        setSelectedItems={this.setSelectedItems}
        schema={get(this.props, 'schema', [])}
        collectionPreferences={collectionPreferences || {}}
        setCollectionPreferences={this.setCollectionPreferences}
      />
    );
  };

  getFilter = () => {
    const schema = get(this.props, 'schema', []);
    const { query } = this.state;

    return <TFilter schema={schema} query={query} setQuery={this.setQuery} />;
  };

  getPagination = () => {
    const { currentPageIndex } = this.state;
    const totalPagesLoaded = size(
      omitBy(get(this.props, ['data']), (value, key) => {
        return isNaN(Number(key));
      }),
    );
    return (
      <TPagination
        currentPageIndex={currentPageIndex}
        setCurrentPageIndex={this.setCurrentPageIndex}
        pagesCount={totalPagesLoaded}
        openEnd={!this.isCurrentPageLast()}
      />
    );
  };

  initializeCollectionPreferences() {
    const collectionPreferences = {
      visibleContent: map(
        filter(get(this.props, 'schema', []), ['defaultVisibility', true]),
        ({ key }) => key,
      ),
    };
    this.setCollectionPreferences(collectionPreferences);
  }

  render() {
    const { currentPageIndex, selectedItems } = this.state;
    const {
      config: { dataTrackingId, dataLoadingText, emptyElement, multiSelectConfig },
    } = this.props;
    const schema = get(this.props, 'schema', []);
    const studyPeriod = get(this.props, 'studyPeriod', '');

    return (
      <PolarisTable
        header={this.getHeader()}
        filter={this.getFilter()}
        pagination={this.getPagination()}
        columnDefinitions={getColumnDefinitions(schema, studyPeriod)}
        visibleColumns={this.getVisibleColumns()}
        trackBy={dataTrackingId}
        items={this.getItemsForPage(currentPageIndex)}
        loadingText={dataLoadingText}
        loading={this.isTableLoading()}
        empty={<TEmptyView EmptyElement={emptyElement} />}
        {...multiSelectConfig}
        selectedItems={selectedItems}
        onSelectionChange={({ detail: { selectedItems: selection } }) => {
          this.setSelectedItems(selection);
        }}
      />
    );
  }
}

Table.propTypes = {
  config: PropTypes.object.isRequired,
  schema: PropTypes.array.isRequired,
  isFetchingSchemas: PropTypes.bool.isRequired,
  getSchemasBegin: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  getReportRefreshStatusBegin: PropTypes.func.isRequired,
  isRefreshingData: PropTypes.bool.isRequired,
  clearRefreshState: PropTypes.func.isRequired,
  refreshDelayTime: PropTypes.number.isRequired,
  getImportDataBegin: PropTypes.func.isRequired,
  getImportDataClear: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  isFetchingSchemas: get(state, 'entities.schemas.isFetching', false) || false,
  studyPeriod: getStudyPeriod(state),
  isRefreshingData: get(state, 'entities.configureRefreshState.isRefreshingData', false),
  refreshDelayTime: get(state, 'entities.configureRefreshState.refreshDelayTime', 0),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSchemasBegin: getSchemaActions.BEGIN,
      getReportRefreshStatusBegin: getReportRefreshStatusAction.BEGIN,
      clearRefreshState: configureRefreshStateAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(Table);
