import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';
import PropTypes from 'prop-types';
import { Spinner, StatusIndicator } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import joinAddressList from './util/join_address_list';

const ViewEmailModal = props => {
  const { state, onDismiss } = props;
  const { isViewEmailVisible, isFetching } = state;
  const { toAddress, subject, body, ccAddresses, status } = get(state, 'viewEmail', {});
  const to = joinAddressList(toAddress);
  const cc = joinAddressList(ccAddresses);

  const modalContent = () => {
    if (isFetching) return <Spinner size="large" />;
    if (status !== 200)
      return (
        <StatusIndicator type="error">
          Email is not available for selected notification.
        </StatusIndicator>
      );
    return (
      <SpaceBetween size="l">
        <div>
          <p>{`Subject: ${subject}`}</p>
          <p>{`To: ${to}`}</p>
          <p>{`CC: ${cc}`}</p>
        </div>
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: body }}
        ></div>
      </SpaceBetween>
    );
  };

  return (
    <Modal
      visible={isViewEmailVisible}
      closeAriaLabel="Close modal"
      size="medium"
      onDismiss={onDismiss}
      header="View Email"
    >
      {modalContent()}
    </Modal>
  );
};

ViewEmailModal.propTypes = {
  state: PropTypes.object.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

export default ViewEmailModal;
