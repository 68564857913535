import { Box, Container, Header, Spinner } from '@amzn/awsui-components-react';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import '../css/style.css';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { get, isEmpty, isNull } from 'lodash';
import { getFileFromS3Action } from '../../../../common/components/get_file_from_s3/redux/actions/GetFileFromS3';
import getPayloadForTemplateDownload from '../../utils/template-utils';

const TemplatePreviewComponent = ({ selectedTemplate, getFileFromS3, templateData }) => {
  const templateEmptyView = (
    <div className="template-empty-div">To preview, select a template first</div>
  );
  const [templateVisibleData, setTemplateVisibleData] = useState(templateEmptyView);
  useEffect(() => {
    if (!isNull(selectedTemplate.value)) {
      setTemplateVisibleData(
        <div className="template-empty-div">
          <Spinner size="big" />
        </div>,
      );
      getFileFromS3(getPayloadForTemplateDownload(selectedTemplate.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTemplate]);
  useEffect(() => {
    if (!isEmpty(templateData)) {
      setTemplateVisibleData(
        <div
          className="template-div"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: templateData.body }}
        ></div>,
      );
    }
  }, [templateData]);
  return (
    <Container header={<Header variant="h2">Selected template preview</Header>}>
      <Box display="block">{templateVisibleData}</Box>
    </Container>
  );
};

TemplatePreviewComponent.propTypes = {
  selectedTemplate: PropTypes.object.isRequired,
  getFileFromS3: PropTypes.func.isRequired,
  templateData: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  templateData: get(state, 'entities.notifications.configureTemplateState.template', {}),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getFileFromS3: getFileFromS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(TemplatePreviewComponent);
