import { combineReducers } from 'redux';
import labor from '../components/labor_tab/components/qre_table/redux/laborQREs';
import updateQREs from './updateQREs';
import contract from '../components/contract_tab/components/qre_table/redux/contractQREs';
import supplies from '../components/supplies_tab/components/qre_table/redux/suppliesQREs';

export default combineReducers({
  labor,
  contract,
  supplies,
  updateQREs,
});
