import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, size } from 'lodash';
import { useState } from 'react';
import axios from 'axios';
import { exportDataAction } from '../../../../../../../../common/redux/export';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
} from '../../../../../../../../common/config/api_constants';
import {
  VALARI_API_NAME,
  VIEW_EMAIL_ENDPOINT,
} from '../../../../../../../../common/config/api_endpoints';
import postWithContext from '../../../../../../../../common/utils/api_util';
import ViewEmailModal from '../../../../../common/ViewEmailModal';
import { getStudyPeriod } from '../../../../../../../../common/constants/study_period';

const RecipientActions = ({
  exportDataBegin,
  query,
  config,
  items,
  setSelectedItems,
  studyPeriod,
}) => {
  const { notificationId } = config;
  const initialState = {
    isViewEmailVisible: false,
    viewEmail: {
      body: '',
      ccAddresses: [],
      subject: '',
      toAddress: [],
      fromAddress: '',
      status: 200,
    },
    isFetching: false,
  };
  const [recipientsActionsState, setRecipientsActionsStateActionsState] = useState(initialState);
  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'NOTIFICATIONS_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          country: DEFAULT_COUNTRY,
          clientId: DEFAULT_BUSINESS_ID,
          notificationId,
          query,
        },
      },
    });
  };
  const isViewEmailDisabled = () => {
    return get(items, [0, 'status']) !== 'Delivered' || size(items) !== 1;
  };

  const viewEmailHandler = () => {
    setRecipientsActionsStateActionsState({
      ...recipientsActionsState,
      isFetching: true,
      isViewEmailVisible: true,
    });
    const { partitionKey, sortKey } = get(items, [0], {});
    postWithContext(VALARI_API_NAME, VIEW_EMAIL_ENDPOINT, {
      body: {
        entityType: 'SURVEY_NOTIFICATION',
        entityFileType: 'EMAIL',
        entityIdMap: { partitionKey, sortKey },
        studyPeriod,
      },
    }).then(async r => {
      if (get(r, 'status') !== 200) {
        setRecipientsActionsStateActionsState({
          isFetching: false,
          isViewEmailVisible: true,
          viewEmail: { ...recipientsActionsState.viewEmail, status: get(r, 'status') },
        });
        return;
      }
      const { presignedUrl } = JSON.parse(get(r, 'body', '{}'));
      try {
        const response = await axios.get(presignedUrl);
        const { status } = response;
        const { fromAddress, toAddresses, subject, body, ccAddresses } = get(response, 'data');
        setRecipientsActionsStateActionsState({
          isFetching: false,
          isViewEmailVisible: true,
          viewEmail: { fromAddress, toAddress: toAddresses, subject, body, ccAddresses, status },
        });
      } catch (e) {
        setRecipientsActionsStateActionsState({
          isFetching: false,
          isViewEmailVisible: true,
          viewEmail: { ...recipientsActionsState.viewEmail, status: 400 },
        });
      }
    });
  };

  const viewEmailDismissHandler = () => {
    setRecipientsActionsStateActionsState(initialState);
    setSelectedItems([]);
  };
  return (
    <SpaceBetween size="s" direction="horizontal">
      <Button onClick={onExportHandler}>Export</Button>
      <Button iconAlign="right" disabled={isViewEmailDisabled()} onClick={viewEmailHandler}>
        View Email
      </Button>
      <ViewEmailModal state={recipientsActionsState} onDismiss={viewEmailDismissHandler} />
    </SpaceBetween>
  );
};

RecipientActions.propTypes = {
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  items: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportDataBegin: exportDataAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(RecipientActions);
