import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
  },
  name: {
    paddingTop: 10,
  },
});

export function SurveyAttestationSectionList({ data }) {
  const { section, name } = styles;
  const selectedSurveyAttestationsList = get(data, 'userResponse.Submit.data', []);
  const commentedValue = get(data, 'userResponse.Submit.textbox', '');
  const surveyAttestations = selectedSurveyAttestationsList.map((optionValue, index) => {
    return (
      <View>
        <Text style={name}>
          {index + 1}. {optionValue}
        </Text>
      </View>
    );
  });

  return (
    <View style={section}>
      {surveyAttestations}
      <Text style={name}>{commentedValue}</Text>
    </View>
  );
}

SurveyAttestationSectionList.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyAttestationSectionList;
