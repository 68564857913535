const STUDY_SUMMARY_TYPES = {
  SURVEYS: {
    key: 'SURVEYS',
  },
  QRE: {
    key: 'QRE',
  },
};

export default STUDY_SUMMARY_TYPES;
