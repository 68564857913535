import {
  Box,
  Button,
  ExpandableSection,
  Grid,
  Modal,
  SpaceBetween,
  Toggle,
} from '@amzn/awsui-components-react';
import { cloneDeep, filter, get, groupBy, includes, map, remove } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import ToggleInput from '../../toogle_input/ToggleInput';

const TPreferences = ({ schema, collectionPreferences, setCollectionPreferences }) => {
  const [isModalVisible, setModalVisibility] = React.useState(false);
  const [localCPs, setLocalCPs] = React.useState(null);
  const [areAllColumnsSelected, setAllColumnsSelected] = React.useState(false);

  useEffect(() => {
    setLocalCPs(cloneDeep(collectionPreferences));
  }, [collectionPreferences]);

  const calculateAreAllColumnsSelected = () => {
    return get(collectionPreferences, ['visibleContent', 'length']) === get(schema, ['length']);
  };

  const showModal = () => {
    setAllColumnsSelected(calculateAreAllColumnsSelected());
    setModalVisibility(true);
  };

  const onModalCancel = () => {
    setLocalCPs(cloneDeep(collectionPreferences));
    setModalVisibility(false);
  };

  const onModalConfirm = () => {
    setModalVisibility(false);
    setCollectionPreferences(localCPs);
  };

  const isColumnVisible = columnKey => {
    const visibleContent = get(localCPs, ['visibleContent'], []);
    return includes(visibleContent, columnKey);
  };

  const toggleColumnVisibility = columnKey => {
    const visibleContent = get(localCPs, ['visibleContent'], []);

    if (isColumnVisible(columnKey)) {
      remove(visibleContent, key => columnKey === key);
    } else {
      visibleContent.push(columnKey);
    }

    setLocalCPs({ visibleContent });
  };

  const getGroupedSchema = () => {
    return groupBy(
      map(schema, column => {
        const { groupName } = column;
        return groupName ? column : { ...column, groupName: 'Miscellaneous' };
      }),
      ({ groupName }) => groupName,
    );
  };

  const getColumnToggleInput = (key, displayName) => {
    return (
      <div key={key}>
        <ToggleInput
          id={key}
          label={displayName}
          setChecked={toggleColumnVisibility}
          checked={isColumnVisible(key)}
          disabled={areAllColumnsSelected}
        />
        <div className="bottom-divider" />
      </div>
    );
  };

  const getColumnToggleInputs = () => {
    if (!localCPs) {
      return null;
    }

    return map(getGroupedSchema(), (groups, groupName) => {
      return (
        <ExpandableSection key={groupName} header={groupName}>
          {map(groups, ({ key, displayName }) => getColumnToggleInput(key, displayName))}
        </ExpandableSection>
      );
    });
  };

  const setSelectAllToggleInput = isChecked => {
    if (isChecked) {
      setLocalCPs({ visibleContent: map(schema, ({ key }) => key) });
    } else {
      setLocalCPs({
        visibleContent: map(
          filter(schema, ({ defaultVisibility }) => defaultVisibility),
          ({ key }) => key,
        ),
      });
    }

    setAllColumnsSelected(isChecked);
  };

  const getSelectAllToggleInput = () => {
    return (
      <SpaceBetween direction="horizontal" size="m" id="selectAllToggle">
        <Toggle
          key="toggle"
          onChange={({ detail }) => setSelectAllToggleInput(detail.checked)}
          checked={areAllColumnsSelected}
        />
        <span key="all">Select all</span>
      </SpaceBetween>
    );
  };

  return (
    <div>
      <Button iconName="settings" variant="icon" onClick={() => showModal()}>
        Open preferences
      </Button>
      <Modal
        onDismiss={onModalCancel}
        visible={isModalVisible}
        closeAriaLabel="Close modal"
        size="large"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={onModalCancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={onModalConfirm}>
                Confirm
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Preferences"
      >
        <div>
          <Box margin={{ bottom: 'l' }} fontWeight="bold">
            Select visible content
          </Box>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <div>{getSelectAllToggleInput()}</div>
            <div className="left-divider">
              <Box padding={{ left: 'l' }}>{getColumnToggleInputs()}</Box>
            </div>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

TPreferences.propTypes = {
  schema: PropTypes.array.isRequired,
  collectionPreferences: PropTypes.object.isRequired,
  setCollectionPreferences: PropTypes.func.isRequired,
};

export default TPreferences;
