import { StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  text: {
    paddingTop: 16,
    paddingBottom: 6,
    paddingLeft: 3,
    color: '#006080',
    fontSize: 13,
    fontStyle: 'bold',
    fontFamily: 'Helvetica-Bold',
  },
});

export function Heading({ name }) {
  const { text } = styles;
  return (
    <View>
      <Text style={text}>{name}</Text>
    </View>
  );
}

Heading.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Heading;
