import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { isEmpty } from 'lodash/lang';
import getProjectMapping from '../../../utils/ProjectUtils';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
  },
  name: {
    paddingTop: 10,
  },
  description: {
    paddingTop: 3,
    paddingLeft: 10,
  },
  hyperLinkText: {
    color: '#007399',
  },
});

export function DocumentationNotes({ data }) {
  const { section, name, description, hyperLinkText } = styles;
  const { searchProjectsResponse } = data;
  const selectedProjects = get(data, 'userResponse.Project.data.selectedProjects', []);
  const projectMapping = getProjectMapping(selectedProjects, searchProjectsResponse);
  const documentList = [];

  const documentData = get(data, 'projectDocumentation', {});
  // eslint-disable-next-line no-restricted-syntax
  for (const projectId in documentData) {
    if (!isEmpty(documentData[projectId])) {
      documentData[projectId].forEach(document => {
        documentList.push({
          documentType: get(document, 'documentSubCategory', ''),
          projectName: projectMapping[projectId],
          documentName: get(document, 'documentFileName', ''),
          documentLink: get(document, 'documentLink', ''),
          documentDecr: get(document, 'documentDescription', ''),
          presignedURL: get(document, 'presignedURL', ''),
        });
      });
    }
  }

  const getHyperLink = (placeHolder, link) => {
    return (
      <Text style={hyperLinkText} src={link}>
        {placeHolder}
      </Text>
    );
  };

  const documents = documentList.map((document, index) => {
    return (
      <View>
        <Text style={name}>
          {index + 1}. {document.documentType}
        </Text>
        <Text style={description}>Project Name - {document.projectName}</Text>
        <Text style={description}>
          Document Link -{' '}
          {!isEmpty(document.documentName)
            ? getHyperLink(document.documentName, document.presignedURL)
            : getHyperLink(document.documentLink, document.documentLink)}
        </Text>
        <Text style={description}>
          {!isEmpty(document.documentDecr) && (
            <Text>Document Description - {document.documentDecr}</Text>
          )}
        </Text>
      </View>
    );
  });

  return <View style={section}>{documents}</View>;
}

DocumentationNotes.propTypes = {
  data: PropTypes.object.isRequired,
};

export default DocumentationNotes;
