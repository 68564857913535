import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import RecipientsTable from './components/recipients_table/components/RecipientsTable';

const RecipientsTab = ({ notificationId }) => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <RecipientsTable notificationId={notificationId} />
      </div>
    </Grid>
  );
};
RecipientsTab.propTypes = {
  notificationId: PropTypes.string.isRequired,
};

export default RecipientsTab;
