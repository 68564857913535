export const SURVEY_NAMES = {
  LABOR: 'Labor survey',
  CONTRACT: 'Contract research survey',
  BOTH_LABOR_AND_CONTRACT: 'Labor survey & contract research survey',
};

export const NOTIFICATION_STATUS_TYPES = {
  IN_EDIT: 'In edit',
  READY: 'Ready',
  CANCELLATION_IN_PROGRESS: 'Cancellation in progress',
  Cancelled: 'Cancelled',
  CANCELLED_X_DELIVERED: 'Cancelled, x delivered',
  X_PERCENT_DELIVERED: 'x% delivered',
  IN_PROGRESS: 'In progress',
  UNKNOWN: 'Unknown',
};

export const X_PERCENT_DELIVERED_REGEX = /\d+% delivered/;

export const CANCELLED_X_DELIVERED_REGEX = /Cancelled, \d+ delivered/;
