import Alert from '@amzn/awsui-components-react/polaris/alert';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { getStudyPeriod } from '../../common/constants/study_period';

const NotFoundContent = ({ studyPeriod }) => {
  return (
    <Page
      PageId="NotFound"
      isPageAccessControlled={false}
      PageContent={
        <Alert type="error" header="Page does not exists!">
          Please use side navigation to go back to valid pages.
        </Alert>
      }
      breadCrumbItems={BRC_ITEMS_PAGE.NOT_FOUND(studyPeriod)}
    />
  );
};

NotFoundContent.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(NotFoundContent);
