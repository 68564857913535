import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import STUDY_SUMMARY_TYPES from '../../constants/studySummaries';
import { getStudySummaryActions } from '../../redux';
import OverallQRE from './components/overall_qre/OverallQRE';
import QREDetails from './components/qre_details/QREDetails';
import SurveyStatusSelector, {
  QRE_SURVEY_STATUSES,
} from './components/survey_status_selector/SurveyStatusSelector';
import StudyPeriodComponent from '../../../../common/components/study_period/StudyPeriodComponent';
import { getStudyPeriod } from '../../../../common/constants/study_period';

class QRETab extends StudyPeriodComponent {
  state = {
    selectedStatuses: [QRE_SURVEY_STATUSES.COMPLETED.key, QRE_SURVEY_STATUSES.IN_PROGRESS.key],
  };

  componentDidMount() {
    const { qreStudySummary } = this.props;
    if (!qreStudySummary || isEmpty(qreStudySummary)) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  fetchData() {
    const { getStudySummaryBegin } = this.props;
    getStudySummaryBegin({
      body: {
        summaryType: STUDY_SUMMARY_TYPES.QRE.key,
      },
    });
  }

  clearData() {
    const { clearQREStudySummary } = this.props;
    clearQREStudySummary();
  }

  setSelectedStatuses = selectedStatuses => {
    this.setState({ selectedStatuses });
  };

  render() {
    const { selectedStatuses } = this.state;
    const { qreStudySummary, isQREStudySummaryLoading } = this.props;
    return (
      <SpaceBetween direction="vertical" size="l">
        <SurveyStatusSelector
          selectedStatuses={selectedStatuses}
          setSelectedStatuses={this.setSelectedStatuses}
        />
        <OverallQRE
          qreStudySummary={qreStudySummary}
          isQREStudySummaryLoading={isQREStudySummaryLoading}
          selectedStatuses={selectedStatuses}
        />
        <QREDetails
          qreStudySummary={qreStudySummary}
          isQREStudySummaryLoading={isQREStudySummaryLoading}
          selectedStatuses={selectedStatuses}
        />
      </SpaceBetween>
    );
  }
}

const mapStateToProps = state => ({
  qreStudySummary:
    get(state, `entities.studySummary.data.${STUDY_SUMMARY_TYPES.QRE.key}`, {}) || {},
  isQREStudySummaryLoading: get(state, 'entities.studySummary.isFetching', false) || false,
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getStudySummaryBegin: getStudySummaryActions.BEGIN,
      clearQREStudySummary: () => getStudySummaryActions.CLEAR(STUDY_SUMMARY_TYPES.QRE.key),
    },
    dispatch,
  );

QRETab.propTypes = {
  qreStudySummary: PropTypes.object,
  isQREStudySummaryLoading: PropTypes.bool,
  getStudySummaryBegin: PropTypes.func.isRequired,
  clearQREStudySummary: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

QRETab.defaultProps = {
  qreStudySummary: {},
  isQREStudySummaryLoading: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(QRETab);
