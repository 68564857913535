import { get, isEmpty } from 'lodash';
import { isEqual } from 'lodash/lang';
import { GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES } from '../../constants/report_types';
import { REPORT_REFRESH_STATUS } from '../../constants/api_constants';

export const isReportIdDoesNotSame = (reportRefreshStatusData, reportData, reportType) => {
  const reportId = get(reportData, [reportType, 'reportId'], '');
  const reportRefreshId = get(
    reportRefreshStatusData,
    [GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES[reportType], 'reportId'],
    '',
  );
  if (!isEmpty(reportId) && !isEmpty(reportRefreshId) && reportRefreshId !== reportId) {
    return true;
  }
  return false;
};

export const getUpdateStatus = (reportRefreshStatusData, reportData, reportType) => {
  let updateStatus = get(
    reportRefreshStatusData,
    [GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES[reportType], 'reportRefreshStatus'],
    REPORT_REFRESH_STATUS.Unknown,
  );
  if (
    isReportIdDoesNotSame(reportRefreshStatusData, reportData, reportType) &&
    !isEqual(updateStatus, REPORT_REFRESH_STATUS.Failed)
  ) {
    updateStatus = REPORT_REFRESH_STATUS.InProgress;
  }
  return updateStatus;
};

export const isAnyReportInProgress = reportRefreshStatusData => {
  if (reportRefreshStatusData) {
    // eslint-disable-next-line no-restricted-syntax,guard-for-in
    for (const report in reportRefreshStatusData) {
      const status = get(reportRefreshStatusData, [report, 'reportRefreshStatus'], '');
      if (status === REPORT_REFRESH_STATUS.InProgress) return true;
    }
    return false;
  }
  return false;
};
