import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import CostCentersTable from './costs_table/components/CostCentersTable';

const CostCentersTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <CostCentersTable />
      </div>
    </Grid>
  );
};

export default CostCentersTab;
