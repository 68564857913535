import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { EmployeeProjectMatrixSectionDescription } from './components/EmployeeProjectMatrixSectionDescription';
import { EmployeeProjectAllocationMatrix } from './components/EmployeeProjectAllocationMatrix';

export function EmployeeProjectMatrixSection({ data }) {
  return (
    <View>
      <Heading name="Project Allocation" />
      <EmployeeProjectMatrixSectionDescription />
      <EmployeeProjectAllocationMatrix data={data} />
    </View>
  );
}

EmployeeProjectMatrixSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeProjectMatrixSection;
