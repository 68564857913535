import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import {
  Table,
  TableCell,
  TableHeader,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import PropTypes from 'prop-types';
import { isEmpty, isNull } from 'lodash';

const styles = StyleSheet.create({
  table: {
    paddingLeft: 3,
    paddingTop: 6,
  },
  tableHeader: {
    backgroundColor: '#C8C8C8',
    fontSize: 11,
    paddingLeft: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableCell: {
    fontSize: 11,
    paddingLeft: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
});

export function SurveyVendorListTable({ vendorData }) {
  const { table, tableHeader, tableCell } = styles;
  if (!isNull(vendorData) && !isEmpty(vendorData)) {
    return (
      <View style={table}>
        <Table data={vendorData}>
          <TableHeader>
            <TableCell style={tableHeader}>Supplier Name</TableCell>
            <TableCell style={tableHeader}>Vendor Name</TableCell>
            <TableCell style={tableHeader}>Cost Center Number</TableCell>
            <TableCell style={tableHeader}>Amount</TableCell>
          </TableHeader>
          <TableBody>
            <DataTableCell style={tableCell} getContent={r => r.supplierName} />
            <DataTableCell style={tableCell} getContent={r => r.vendorName} />
            <DataTableCell style={tableCell} getContent={r => r.costCenterNumber} />
            <DataTableCell style={tableCell} getContent={r => r.amount} />
          </TableBody>
        </Table>
      </View>
    );
  }

  return <View />;
}

SurveyVendorListTable.propTypes = {
  vendorData: PropTypes.object.isRequired,
};

export default SurveyVendorListTable;
