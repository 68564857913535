import PropTypes from 'prop-types';
import * as React from 'react';
import { Checkbox, Container, Header } from '@amzn/awsui-components-react';
import map from 'lodash/map';
import ColumnLayout from '@amzn/awsui-components-react/polaris/column-layout';
import { includes } from 'lodash';
import { without } from 'lodash/array';
import union from 'lodash/union';

export const QRE_SURVEY_STATUSES = {
  COMPLETED: {
    label: 'Completed surveys',
    key: 'Submitted',
  },
  IN_PROGRESS: {
    label: 'In progress surveys',
    key: 'InProgress',
  },
};

const SurveyStatusSelector = ({ selectedStatuses, setSelectedStatuses }) => {
  const addSelectedStatus = surveyStatus => {
    setSelectedStatuses(union(selectedStatuses, [surveyStatus]));
  };

  const removeSelectedStatus = surveyStatus => {
    setSelectedStatuses(without(selectedStatuses, surveyStatus));
  };

  return (
    <Container header={<Header variant="h2">Show QRE of</Header>}>
      <ColumnLayout columns={2}>
        {map(QRE_SURVEY_STATUSES, ({ label, key }) => {
          return (
            <Checkbox
              key={key}
              onChange={({ detail: { checked } }) =>
                checked ? addSelectedStatus(key) : removeSelectedStatus(key)
              }
              checked={includes(selectedStatuses, key)}
            >
              {label}
            </Checkbox>
          );
        })}
      </ColumnLayout>
    </Container>
  );
};

SurveyStatusSelector.propTypes = {
  selectedStatuses: PropTypes.array,
  setSelectedStatuses: PropTypes.func.isRequired,
};

SurveyStatusSelector.defaultProps = {
  selectedStatuses: [],
};
export default SurveyStatusSelector;
