import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@amzn/awsui-components-react';
import { filter, get, map, sortedUniq } from 'lodash';
import { connect } from 'react-redux';
import size from 'lodash/size';
import { getTemplates } from '../utils/SurveyUtils';

const UpdateSurveyTemplateModal = props => {
  const { isVisible, onDismiss, onConfirm, onCancel, records, data } = props;

  const TEMPLATES = getTemplates(data, size(records) > 0 ? get(records[0], 'surveyType') : '');
  const getSMEs = () => {
    return sortedUniq(map(records, ({ userName }) => userName)).join(', ');
  };
  const [state, setState] = useState({});

  useEffect(() => {
    setState(
      size(TEMPLATES) > 0
        ? filter(TEMPLATES, ({ value }) => value === get(records[0], 'templateDocumentId'))[0]
        : {},
    );
    // eslint-disable-next-line
  }, [records]);

  const onConfirmHandler = () => {
    onConfirm(records, get(state, 'label'));
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Update survey template"
    >
      <SpaceBetween size="l">
        <Box variant="p">
          Update the survey template used for survey(s) of the following SME(s)
          <Box variant="p">{getSMEs()}</Box>
        </Box>
        <SpaceBetween size="xs">
          <Box>Survey Template</Box>
          <Select
            options={TEMPLATES}
            onChange={({ detail }) => {
              setState(filter(TEMPLATES, ({ value }) => value === detail.selectedOption.value)[0]);
            }}
            selectedOption={state}
          />
        </SpaceBetween>
      </SpaceBetween>
    </Modal>
  );
};

UpdateSurveyTemplateModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  data: get(state, 'entities.schemas.data.metaData', []) || [],
});

export default connect(mapStateToProps)(UpdateSurveyTemplateModal);
