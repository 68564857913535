export const QRE_CLASSIFICATION = {
  LABOR: 'Labor',
  CONTRACT: 'Contract',
  SUPPLIES: 'Supplies',
};

export const getQREClassificationType = qreType => {
  switch (qreType) {
    case 'Contract':
      return QRE_CLASSIFICATION.CONTRACT;
    case 'Labor':
    default:
      return QRE_CLASSIFICATION.LABOR;
  }
};

export const QRE_JURISDICTIONS = {
  FEDERAL: 'federal',
  STATE: 'state',
};

export const QRE_TYPES = {
  LABOR_FEDERAL_ACTIVITY_PRE_80_RULE: {
    key: 'LaborFederalActivityPre80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },
  LABOR_FEDERAL_ACTIVITY_POST_80_RULE: {
    key: 'LaborFederalActivityPost80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },
  LABOR_FEDERAL_PROJECT_PRE_80_RULE: {
    key: 'LaborFederalProjectPre80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },
  LABOR_FEDERAL_PROJECT_POST_80_RULE: {
    key: 'LaborFederalProjectPost80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },

  LABOR_STATE_ACTIVITY_PRE_80_RULE: {
    key: 'LaborStateActivityPre80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.STATE,
  },
  LABOR_STATE_ACTIVITY_POST_80_RULE: {
    key: 'LaborStateActivityPost80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.STATE,
  },
  LABOR_STATE_PROJECT_PRE_80_RULE: {
    key: 'LaborStateProjectPre80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.STATE,
  },
  LABOR_STATE_PROJECT_POST_80_RULE: {
    key: 'LaborStateProjectPost80',
    classification: QRE_CLASSIFICATION.LABOR,
    jurisdiction: QRE_JURISDICTIONS.STATE,
  },

  CONTRACT_FEDERAL_ACTIVITY: {
    key: 'ContractFederalActivity',
    classification: QRE_CLASSIFICATION.CONTRACT,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },
  CONTRACT_FEDERAL_PROJECT: {
    key: 'ContractFederalProject',
    classification: QRE_CLASSIFICATION.CONTRACT,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },
  CONTRACT_STATE_PROJECT: {
    key: 'ContractStateProject',
    classification: QRE_CLASSIFICATION.CONTRACT,
    jurisdiction: QRE_JURISDICTIONS.STATE,
  },
  CONTRACT_STATE_ACTIVITY: {
    key: 'ContractStateActivity',
    classification: QRE_CLASSIFICATION.CONTRACT,
    jurisdiction: QRE_JURISDICTIONS.STATE,
  },
  SUPPLIES_FEDERAL: {
    key: 'SuppliesFederal',
    classification: QRE_CLASSIFICATION.SUPPLIES,
    jurisdiction: QRE_JURISDICTIONS.FEDERAL,
  },
};
