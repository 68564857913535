import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isNull } from 'lodash';
import FileUpload from '../file_uploader/FileUploader';

const FileImportModal = props => {
  const { isVisible, onDismiss, onConfirm, onCancel, acceptedFileTypes } = props;

  const initialState = {
    form: {
      description: null,
      file: null,
    },
  };

  const [state, setState] = useState(initialState);

  const onCancelHandler = () => {
    onCancel();
    setState(initialState);
  };

  const onConfirmHandler = () => {
    onConfirm(state);
    setState(initialState);
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler} disabled={isNull(state.form.file)}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Import"
    >
      <FileUpload setFileState={setState} state={state} acceptedFileTypes={acceptedFileTypes} />
    </Modal>
  );
};

FileImportModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  acceptedFileTypes: PropTypes.string.isRequired,
};

export default FileImportModal;
