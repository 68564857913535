import { combineReducers } from 'redux';
import schemas from './schemas';
import population from './population';
import qnq from '../../../app/qnq/redux';
import grouping from '../../../app/grouping/redux';
import surveys from '../../../app/survey/redux/surveys';
import updateSurveys from '../../../app/survey/redux/UpdateSurveys';
import configureSurveys from '../../../app/survey/components/redux/surveyConfiguration';
import studySummary from '../../../app/study_summary/redux';
import notifications from '../../../app/notification/redux/index';
import exportData from '../../../common/redux/export';
import qres from '../../../app/qre/redux/index';
import checkSurveys from '../../../app/survey/redux/createSurveyChecker';
import reporting from '../../../app/analytics/components/report_table/redux/reporting';
import getReportRefreshStatus from '../../../app/analytics/components/report_table/redux/GetReportRefreshStatus';
import configureRefreshState from '../../../common/redux/configureRefreshState';
import triggerImportData from '../../../app/import_data/redux/TriggerImport';
import getImportMetadata from '../../../app/import_data/redux/GetImportMetadata';

export default combineReducers({
  schemas,
  population,
  qnq,
  grouping,
  surveys,
  checkSurveys,
  updateSurveys,
  configureSurveys,
  studySummary,
  notifications,
  exportData,
  qres,
  reporting,
  getReportRefreshStatus,
  configureRefreshState,
  triggerImportData,
  getImportMetadata,
});
