import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { get, size, uniq } from 'lodash';
import {
  Table,
  TableCell,
  TableHeader,
  TableBody,
  DataTableCell,
} from '@david.kucsai/react-pdf-table';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  table: {
    paddingLeft: 3,
    paddingTop: 6,
  },
  tableHeader: {
    backgroundColor: '#C8C8C8',
    fontSize: 11,
    paddingLeft: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
  tableCell: {
    fontSize: 11,
    paddingLeft: 2,
    paddingTop: 1,
    paddingBottom: 1,
  },
});

function getHeaders(headerList, entityNameMapping) {
  const headers = headerList.map(header => {
    return <TableCell style={styles.tableHeader}>{get(entityNameMapping, header, '')}</TableCell>;
  });

  return (
    <TableHeader>
      <TableCell style={styles.tableHeader}> </TableCell>
      {headers}
    </TableHeader>
  );
}

function getRowValues(headerList, projectActivityNameMapping) {
  const rowsValue = headerList.map((header, i) => {
    return <DataTableCell style={styles.tableCell} getContent={r => r[`allocation${i + 1}`]} />;
  });
  return (
    <TableBody>
      <DataTableCell
        style={styles.tableCell}
        getContent={r => projectActivityNameMapping[r.name]}
      />
      {rowsValue}
    </TableBody>
  );
}

function getTable(data, headerList, projectActivityNameMapping, entityNameMapping) {
  return (
    <View style={styles.table}>
      <Table data={data}>
        {getHeaders(headerList, entityNameMapping)}
        {getRowValues(headerList, projectActivityNameMapping)}
      </Table>
    </View>
  );
}

function getAllocationData(data, entityList, activityProjectList) {
  const allocationData = [];
  activityProjectList.forEach(name => {
    const allocationDataMap = { name };
    entityList.forEach((entity, j) => {
      allocationDataMap[`allocation${j + 1}`] = get(get(data, [entity], {}), [name], 0);
    });
    allocationData.push(allocationDataMap);
  });

  return allocationData;
}

function getTableData(data, entityList) {
  let activityProjectList = [];
  entityList.forEach(entity => {
    const entityProjectActivityList = Object.keys(get(data, entity, {})).filter(
      j => j !== 'total' && j !== 'prefilledProjectsList',
    );
    activityProjectList = [...activityProjectList, ...entityProjectActivityList];
  });
  const uniqueItems = uniq(activityProjectList);
  return {
    entityList,
    allocationData: getAllocationData(data, entityList, uniqueItems),
  };
}

export function AllocationMatrix({ data, projectActivityNameMapping, entityNameMapping }) {
  const entityIdList = Object.keys(data);
  const allocationTables = [];
  for (let i = 0; i < size(entityIdList); i += 4) {
    const subEntityList = entityIdList.slice(i, i + 4);
    const tableData = getTableData(data, subEntityList);
    allocationTables.push(tableData);
  }

  const tables = allocationTables.map(tableData => {
    const tableRows = tableData.allocationData;
    const tableHeaders = tableData.entityList;
    return getTable(tableRows, tableHeaders, projectActivityNameMapping, entityNameMapping);
  });

  return <View>{tables}</View>;
}

AllocationMatrix.propTypes = {
  data: PropTypes.object.isRequired,
  projectActivityNameMapping: PropTypes.object.isRequired,
  entityNameMapping: PropTypes.object.isRequired,
};

export default AllocationMatrix;
