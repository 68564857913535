import { get, isNumber } from 'lodash';
import isString from 'lodash/isString';
import isObject from 'lodash/isObject';

export const getSerializedData = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return [];
  }
};

export const extractTableDataFromPayload = payload => {
  if (!isObject(payload)) {
    return {};
  }
  const { pageNumber, data, totalResultCount } = payload;
  const importMetadata = get(payload, 'importMetadata', null);
  if (!isNumber(pageNumber) || !isString(data) || !isNumber(totalResultCount)) {
    return {};
  }

  return {
    [pageNumber]: getSerializedData(data),
    totalResultCount,
    importMetadata,
  };
};

export const extractGroupingTableDataFromPayload = payload => {
  if (!isObject(payload)) {
    return {};
  }

  const {
    pageNumber,
    data,
    totalResultCount,
    groupingId,
    lastChangedOn,
    groupingStatus,
    importMetadata,
  } = payload;
  if (!isNumber(pageNumber) || !isString(data) || !isNumber(totalResultCount)) {
    return {};
  }

  return {
    [pageNumber]: getSerializedData(data),
    totalResultCount,
    groupingId,
    lastChangedOn,
    groupingStatus,
    importMetadata,
  };
};
