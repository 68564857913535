import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../../../../common/components/table/components/Table';
import { getQRETableConfig } from '../../../../common/qre_table_config';
import { searchContractQREsAction } from '../redux/contractQREs';

const QRETable = props => {
  return <Table {...props} config={getQRETableConfig(props.qreType)} />;
};

QRETable.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  totalResultCount: PropTypes.number.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  qreType: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  schema: get(state, 'entities.schemas.data.pages.qre.contract', []) || [],
  data: get(state, 'entities.qres.contract.data', {}) || {},
  totalResultCount: get(state, 'entities.qres.contract.data.totalResultCount', 0) || 0,
  isFetchingData: get(state, 'entities.qres.contract.isFetching', false) || false,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchContractQREsAction.BEGIN,
      clearData: searchContractQREsAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(QRETable);
