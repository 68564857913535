import PropTypes from 'prop-types';
import * as React from 'react';
import StudySummaryDetails from '../../../common/study_summary_details/StudySummaryDetails';
import {
  AGG_BY_SURVEY_TYPE_SORT_ARRAY,
  AGGREGATION_ITEMS,
  AGGREGATIONS_IDS,
} from '../../constants/aggregations';
import { getItems, getTableColumnDefinitions } from './utils';
import SURVEY_STATUSES from '../../constants/surveyStatuses';

const SurveyStatusDetails = ({ surveysStudySummary, isSurveysStudySummaryLoading }) => {
  return (
    <StudySummaryDetails
      heading="Survey status"
      dataValueSuffix="surveys"
      aggregation={surveysStudySummary}
      isAggregationLoading={isSurveysStudySummaryLoading}
      initialSelectedAggId={AGGREGATIONS_IDS.BY_SURVEY_TYPE}
      aggItems={AGGREGATION_ITEMS}
      aggTypes={SURVEY_STATUSES}
      getItems={getItems}
      getColumnDefinitions={getTableColumnDefinitions}
      yTile="Surveys"
      xDomainSortArray={AGG_BY_SURVEY_TYPE_SORT_ARRAY}
    />
  );
};

SurveyStatusDetails.propTypes = {
  surveysStudySummary: PropTypes.object,
  isSurveysStudySummaryLoading: PropTypes.bool,
};

SurveyStatusDetails.defaultProps = {
  surveysStudySummary: {},
  isSurveysStudySummaryLoading: false,
};
export default SurveyStatusDetails;
