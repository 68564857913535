import { Box, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import CostCentersTab from './components/cost_centers_tab/components/CostCentersTab';
import JobCodesTab from './components/job_codes_tab/components/JobCodesTab';
import ProjectsTab from './components/projects_tab/components/ProjectsTab';
import { getStudyPeriod } from '../../common/constants/study_period';

const QNQPage = ({ studyPeriod }) => {
  const content = (
    <div>
      <Header variant="h1">QNQ</Header>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'Cost centers',
              id: 'first',
              content: <CostCentersTab />,
            },
            {
              label: 'Job codes',
              id: 'second',
              content: <JobCodesTab />,
            },
            // {
            //   label: 'Activities',
            //   id: 'third',
            //   content: null,
            // },
            {
              label: 'Projects',
              id: 'fourth',
              content: <ProjectsTab />,
            },
          ]}
        />
      </Box>
    </div>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_QNQ}
      isPageAccessControlled
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.QNQ(studyPeriod)}
    />
  );
};

QNQPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(QNQPage);
