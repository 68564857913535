import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../common/components/table/constants';
import CostCenterActions from '../components/CostCenterActions';
import { DEFAULT_BUSINESS_ID } from '../../../../../../../common/config/api_constants';
import { getImportStatusHeaderDescription } from '../../../../../../import_data/utils/import_utils';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../../import_data/constants/import_types';

const EmptyTableView = () => <p>No cost centers.</p>;

export default ({ importTypeMetadata }) => {
  return {
    dataTrackingId: 'CostCenterId',
    dataLoadingText: 'Loading cost centers',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Cost Centers',
      description: getImportStatusHeaderDescription(importTypeMetadata),
      Actions: CostCenterActions,
      importTypeMetadata,
    },
    // TODO : This needs to be dynamic
    getDataRequestBody: () => {
      return {
        // TODO : This needs to be removed when business start accepting clientId,
        // which is by default added in all requests
        businessId: DEFAULT_BUSINESS_ID,
      };
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes: SUPPORTED_IMPORT_TYPES.QNQ_CC,
        },
      };
    },
  };
};
