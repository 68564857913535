import filter from 'lodash/filter';
import REPORT_TYPES, { GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES } from './report_types';

export const isReportRefreshable = reportType => {
  if (reportType === REPORT_TYPES.SURVEY_STATUS_REPORT) return false;
  if (reportType === REPORT_TYPES.DATA_DUMP_REPORT) return false;
  return true;
};

export const getReportRefreshStatusRequest = () => {
  return filter(REPORT_TYPES, isReportRefreshable).map(reportType => {
    return { reportType: GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES[reportType] };
  });
};

export const getTriggerAllReportRefreshRequest = () => {
  return filter(REPORT_TYPES, isReportRefreshable).map(reportType => {
    return { reportType };
  });
};

export const REPORT_REFRESH_STATUS = {
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed',
  Unknown: 'Unknown',
};

export const REPORT_REFRESH_STATUS_NAME = {
  InProgress: 'In progress',
  Completed: 'Completed',
  Failed: 'Failed',
  Unknown: '-',
};
