import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { get, isObject, map, size } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileImportModal from '../../../../../../../common/components/file_import_modal/FileImportModal';
import { updateJobCodesQNQAction } from '../redux/updateJobCodesQnq';
import UpdateJobCodesModal from './UpdateJobCodesModal';
import { exportDataAction } from '../../../../../../../common/redux/export';
import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
} from '../../../../../../../common/config/api_constants';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../../../common/constants/study_period';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  SUPPORTED_IMPORT_TYPES,
} from '../../../../../../import_data/constants/import_types';
import { uploadFileToS3Action } from '../../../../../../../common/components/file_importer/redux/actions/UploadFileToS3';
import {
  getUploadToS3PayloadForTriggerImport,
  isImportDisabled,
  isImportInProgress,
} from '../../../../../../import_data/utils/import_utils';

const JobCodesActions = ({
  getDataBegin,
  items,
  setSelectedItems,
  exportDataBegin,
  query,
  studyPeriod,
  uploadFileToS3,
  config,
}) => {
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);
  const [isUpdateJobCodesModalVisible, setUpdateJobCodesModalVisibility] = React.useState(false);
  // eslint-disable-next-line
  const showFileImportModal = () => {
    setFileImportModalVisibility(true);
  };

  const hideCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    // state will contain file details in props
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType: SUPPORTED_IMPORT_TYPES.QNQ_JC[0],
      }),
    );
  };

  const areAllJobCodesSame = () => {
    const jobCodeTypes = new Set();
    map(items, ({ JobCodeQNQ }) => jobCodeTypes.add(JobCodeQNQ));
    if (size(jobCodeTypes) === 1) return true;
    return false;
  };

  const showUpdateJobCodesModal = () => {
    setUpdateJobCodesModalVisibility(true);
  };

  const onCancelUpdateJobCodesModal = () => {
    setUpdateJobCodesModalVisibility(false);
  };
  const filterSelectedItems = (listOfItems, value) => {
    return listOfItems.map(record => {
      return {
        companyOFACode: record.CompanyOFACode,
        jobCode: record.JobCode,
        jobCodeQNQ: value,
      };
    });
  };
  const onConfirmUpdateJobCodesModal = updates => {
    if (isObject(updates)) {
      const { selectedOption } = updates;
      const { value } = selectedOption;
      getDataBegin({
        body: {
          jobCodesUpdateList: filterSelectedItems(items, value),
        },
      });
    }
    setSelectedItems([]);
    setUpdateJobCodesModalVisibility(false);
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'JOB_CODES_EXTRACT',
        isJSON: false,
        query: {
          StudyPeriod: studyPeriod,
          Country: DEFAULT_COUNTRY,
          BusinessId: DEFAULT_BUSINESS_ID,
          query,
        },
      },
    });
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button
        onClick={showFileImportModal}
        disabled={
          isActionDisabledForStudyPeriod(studyPeriod) ||
          isImportInProgress(config) ||
          isImportDisabled(config)
        }
      >
        Import
      </Button>
      <Button onClick={onExportHandler}>Export</Button>
      <Button
        disabled={!areAllJobCodesSame() || isActionDisabledForStudyPeriod(studyPeriod)}
        onClick={showUpdateJobCodesModal}
      >
        Update
      </Button>

      <UpdateJobCodesModal
        items={items}
        isVisible={isUpdateJobCodesModalVisible}
        onDismiss={onCancelUpdateJobCodesModal}
        onConfirm={onConfirmUpdateJobCodesModal}
        onCancel={onCancelUpdateJobCodesModal}
      />
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={hideCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={hideCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};

JobCodesActions.propTypes = {
  items: PropTypes.array.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    isFetchingData: get(state, 'entities.qnq.updateJobCodes.isFetching', false) || false,
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: updateJobCodesQNQAction.BEGIN,
      clearData: updateJobCodesQNQAction.CLEAR,
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );
export default connect(mapStateToProps, mapDispatchToProps)(JobCodesActions);
