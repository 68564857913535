import { Grid } from '@amzn/awsui-components-react/polaris';
import { get, isEmpty, isNumber } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StudyPeriodComponent from '../../../../../../../common/components/study_period/StudyPeriodComponent';
import { ITEM_TYPE_LABOR } from '../../../../../../../common/config/api_constants';
import { getStudyPeriod } from '../../../../../../../common/constants/study_period';
import { getLaborPopulationOverviewActions } from '../redux/populationOverview';
import EExceptionsCard from './EExceptionsCard';
import EOverviewCard from './EOverviewCard';

class EOverview extends StudyPeriodComponent {
  componentDidMount() {
    const { populationOverview } = this.props;
    if (!populationOverview || isEmpty(populationOverview)) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.clearData();
  }

  fetchData() {
    const { getLaborPopulationOverviewBegin } = this.props;
    // TODO : Pass the parameters when app evolves
    getLaborPopulationOverviewBegin({
      body: {
        itemType: ITEM_TYPE_LABOR,
      },
    });
  }

  clearData() {
    const { clearLaborPopulationOverview } = this.props;
    clearLaborPopulationOverview();
  }

  getOverviewData = key => {
    try {
      const overview = JSON.parse(get(this.props, 'populationOverview.overview', ''));
      return get(overview, key, 'N/A');
    } catch (e) {
      return 'N/A';
    }
  };

  getExceptionsData = key => {
    try {
      const overview = JSON.parse(get(this.props, 'populationOverview.exceptions', ''));
      return get(overview, key, 'N/A');
    } catch (e) {
      return 'N/A';
    }
  };

  getExceptionsCountDisplay = () => {
    const count =
      this.getExceptionsData('withoutHRData') + this.getExceptionsData('withoutPayrollData');
    return isNumber(count) ? `(${count})` : '';
  };

  render() {
    const { isFetchingPopulationOverview } = this.props;
    return (
      <div>
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
          <EOverviewCard
            isFetchingPopulationOverview={isFetchingPopulationOverview}
            totalEmployees={this.getOverviewData('totalEmployees')}
            terminatedEmployees={this.getOverviewData('terminatedEmployees')}
          />
          <EExceptionsCard
            isFetchingPopulationOverview={isFetchingPopulationOverview}
            exceptionsCountDisplay={this.getExceptionsCountDisplay()}
            employeesWithoutHRData={this.getExceptionsData('employeesWithoutHRData')}
            employeesWithoutPayrollData={this.getExceptionsData('employeesWithoutPayrollData')}
          />
        </Grid>
      </div>
    );
  }
}

EOverview.propTypes = {
  populationOverview: PropTypes.object.isRequired,
  isFetchingPopulationOverview: PropTypes.bool.isRequired,
  getLaborPopulationOverviewBegin: PropTypes.func.isRequired,
  clearLaborPopulationOverview: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  populationOverview: get(state, 'entities.population.labor.populationOverview.data', {}) || {},
  isFetchingPopulationOverview:
    get(state, 'entities.population.labor.populationOverview.isFetching', false) || false,
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getLaborPopulationOverviewBegin: getLaborPopulationOverviewActions.BEGIN,
      clearLaborPopulationOverview: getLaborPopulationOverviewActions.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(EOverview);
