import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../../../../../common/components/table/components/Table';
import ccTableConfig from '../constants/cc_table_config';
import { searchNotificationsAction } from '../../../../recipients_tab/components/recipients_table/redux';

const CCTable = props => {
  const { notificationId } = props;
  const { getDataRequestBody } = ccTableConfig;
  const mappedRequest = () => {
    return { ...getDataRequestBody(), notificationId };
  };
  return (
    <Table
      {...props}
      config={{
        ...ccTableConfig,
        getDataRequestBody: mappedRequest,
        headerConfig: { ...ccTableConfig.headerConfig, notificationId },
      }}
    />
  );
};

CCTable.propTypes = {
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  notificationId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    schema: get(state, 'entities.schemas.data.pages.notifications.cc', []) || [],
    data: get(state, 'entities.notifications.searchNotifications.data', {}) || {},
    totalResultCount:
      get(state, 'entities.notifications.searchNotifications.data.totalResultCount', 0) || 0,
    isFetchingData:
      get(state, 'entities.notifications.searchNotifications.isFetching', false) || false,
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchNotificationsAction.BEGIN,
      clearData: searchNotificationsAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(CCTable);
