import map from 'lodash/map';
import union from 'lodash/union';
import get from 'lodash/get';
import { isNumber } from 'lodash';
import QRE_SUM_AGG_TYPES, { QRE_SUMMARY_ATTRIBUTES } from '../../constants/qreTypes';

export const getItems = ({ aggregation }) => {
  return map(aggregation, (item, bucket) => {
    return { ...item, bucket };
  });
};

export const getTableColumnDefinitions = ({ aggId, aggText }) => {
  return union(
    [
      {
        id: aggId,
        header: aggText,
        cell: e => get(e, ['bucket']),
      },
    ],
    map(QRE_SUM_AGG_TYPES, ({ label, key }) => {
      return {
        id: key,
        header: label,
        cell: e => {
          const value = get(e, [key], '-');
          if (QRE_SUMMARY_ATTRIBUTES.includes(key) && isNumber(value))
            return value.toLocaleString('en-US', { maximumFractionDigits: 2 });
          return value;
        },
      };
    }),
  );
};
