import { Select, TextContent } from '@amzn/awsui-components-react';
import Box from '@amzn/awsui-components-react/polaris/box';
import React from 'react';
import PropTypes from 'prop-types';

const ChooseReason = ({ REASONS, selectedReason, setSelectedReason }) => {
  return (
    <Box>
      <TextContent>
        <p>Choose the reason</p>
      </TextContent>
      <Select
        placeholder="Select One"
        selectedOption={selectedReason}
        onChange={({ detail }) => setSelectedReason(detail.selectedOption)}
        options={REASONS}
      />
    </Box>
  );
};

ChooseReason.propTypes = {
  REASONS: PropTypes.array.isRequired,
  setSelectedReason: PropTypes.func.isRequired,
  selectedReason: PropTypes.string,
};

ChooseReason.defaultProps = {
  selectedReason: null,
};

export default ChooseReason;
