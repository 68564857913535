import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React from 'react';

const NotificationDeleteModal = ({
  onCancelHandler,
  isModalVisible,
  onConfirmHandler,
  notificationName,
}) => {
  return (
    <Modal
      onDismiss={onCancelHandler}
      visible={isModalVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="normal" onClick={onConfirmHandler}>
              Delete
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Delete"
    >
      Are you sure you want to delete <b>{notificationName}</b>?
    </Modal>
  );
};

NotificationDeleteModal.propTypes = {
  onCancelHandler: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  onConfirmHandler: PropTypes.func.isRequired,
  notificationName: PropTypes.string.isRequired,
};

export default NotificationDeleteModal;
