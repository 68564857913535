import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Box, Modal } from '@amzn/awsui-components-react';
import { sortedUniq, map, forEach } from 'lodash';
import Button from '@amzn/awsui-components-react/polaris/button';
import size from 'lodash/size';

const DeactivateModal = props => {
  const { isVisible, onConfirm, onCancel, records } = props;
  const getSMEs = () => {
    return sortedUniq(map(records, ({ userName }) => userName)).join(', ');
  };
  const getStatusString = () => {
    const statuses = new Set();
    forEach(records, ({ status }) => {
      statuses.add(status);
    });

    if (size(statuses) === 1) return records[0].status;
    return "Submitted' and/or 'Deactivated";
  };
  const onConfirmHandler = () => {
    onConfirm(records);
  };
  return (
    <Modal
      onDismiss={onCancel}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Request additional information"
    >
      <SpaceBetween size="l">
        <Box variant="p">
          Request additional information to the following SME(s):
          <Box variant="p">{getSMEs()}</Box>
        </Box>
        <Alert type="info">
          Survey status will change from &apos;{getStatusString()}&apos; to &apos;In progress&apos;.
        </Alert>
      </SpaceBetween>
    </Modal>
  );
};

DeactivateModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default DeactivateModal;
