import * as React from 'react';
import Cards from '@amzn/awsui-components-react/polaris/cards';
import { Header } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import size from 'lodash/size';
import PropTypes from 'prop-types';
import {
  FILTER_ON,
  FILTER_ON_PLACEHOLDER,
  getReportCardsDefinition,
  REPORT_CARDS_PER_ROW,
  SELECTION_TYPE,
  TRACK_BY,
  VISIBLE_SECTIONS,
} from './constants/report_cards_config';
import TextFiltering from '../../../../common/components/table/components/TextFiltering';
import ReportsCardsFilterActions from './components/ReportsCardsFilterActions';
import { REPORT_CARDS } from '../../constants/report_cards';

const ReportCards = ({
  reportCards,
  setReportCards,
  selectedReportCards,
  setSelectedReportCards,
}) => {
  const onSelectionChangeHandler = ({ detail }) => {
    setSelectedReportCards([get(detail, ['selectedItems', 0])]);
  };

  const getHeader = () => {
    return (
      <Header
        counter={`(${size(reportCards)})`}
        actions={<ReportsCardsFilterActions selectedReportCards={selectedReportCards} />}
      >
        Reports
      </Header>
    );
  };

  const getFilter = () => {
    return (
      <TextFiltering
        setVisibleItems={setReportCards}
        filterOn={FILTER_ON}
        filteringPlaceholder={FILTER_ON_PLACEHOLDER}
        data={REPORT_CARDS}
      />
    );
  };

  return (
    <Cards
      trackBy={TRACK_BY}
      cardDefinition={getReportCardsDefinition({ setSelectedReportCard: setSelectedReportCards })}
      cardsPerRow={REPORT_CARDS_PER_ROW}
      selectionType={SELECTION_TYPE}
      onSelectionChange={onSelectionChangeHandler}
      selectedItems={selectedReportCards}
      visibleSections={VISIBLE_SECTIONS}
      items={reportCards}
      filter={getFilter()}
      header={getHeader()}
    />
  );
};

ReportCards.propTypes = {
  reportCards: PropTypes.array.isRequired,
  setReportCards: PropTypes.func.isRequired,
  selectedReportCards: PropTypes.array.isRequired,
  setSelectedReportCards: PropTypes.func.isRequired,
};

export default ReportCards;
