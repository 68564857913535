import { createAction } from 'redux-actions';
import createGetDocumentDownloadPresignedURLEpic from '../epics/getDocumentDownloadPresignedURLUtil';
import { downloadFileFromS3Action } from './DownloadFileFromS3';

const GET_DOCUMENT_DOWNLOAD_PRESIGNED_URL_ACTION_NAME =
  'GET_DOCUMENT_DOWNLOAD_PRESIGNED_URL_ACTION';
export const getDocumentDownloadPresignedURLAction = createAction(
  GET_DOCUMENT_DOWNLOAD_PRESIGNED_URL_ACTION_NAME,
);

const errorCallback = (response, store, action) => {
  const { onDownloadFailure } = action.payload;
  const { action: onDownloadFailureAction } = onDownloadFailure;
  return [onDownloadFailureAction()];
};

const successCallback = (response, store, action) => {
  if (response !== null && response.body !== null) {
    const { onDownloadSuccess, onDownloadFailure } = action.payload;
    const { presignedUrl } = JSON.parse(response.body);
    return [downloadFileFromS3Action({ presignedUrl, onDownloadSuccess, onDownloadFailure })];
  }
  return errorCallback(response, store, action);
};

export const getDocumentDownloadPresignedURLEpic = createGetDocumentDownloadPresignedURLEpic({
  actionType: GET_DOCUMENT_DOWNLOAD_PRESIGNED_URL_ACTION_NAME,
  successCallback,
  errorCallback,
});
