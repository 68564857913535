import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import PropTypes from 'prop-types';
import * as React from 'react';
import getOverview from './utils';

const SurveyMetrics = ({ surveysStudySummary }) => {
  const getHeader = () => {
    return <Header variant="h2">Survey metrics</Header>;
  };

  return <Container header={getHeader()}>{getOverview(surveysStudySummary)}</Container>;
};

SurveyMetrics.propTypes = {
  surveysStudySummary: PropTypes.object,
};

SurveyMetrics.defaultProps = {
  surveysStudySummary: {},
};

export default SurveyMetrics;
