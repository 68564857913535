import PropTypes from 'prop-types';
import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import NotificationContentContainer from './components/NotificationContentContainer';

const NotificationContentTab = ({ templateId }) => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <NotificationContentContainer templateId={templateId} />
      </div>
    </Grid>
  );
};

NotificationContentTab.propTypes = {
  templateId: PropTypes.string.isRequired,
};

export default NotificationContentTab;
