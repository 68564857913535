import { Link } from '@amzn/awsui-components-react';
import * as React from 'react';
import { get } from 'lodash';
import isEqual from 'lodash/isEqual';
import { getDateInMMDDYYYYHHMIFormat } from '../../../../../common/utils/date_time_util';

export const TRACK_BY = 'name';
export const SELECTION_TYPE = 'single';
export const FILTER_ON = 'name';
export const FILTER_ON_PLACEHOLDER = 'Search';
export const REPORT_CARDS_PER_ROW = [{ cards: 1 }];
export const VISIBLE_SECTIONS = ['lastUpdated'];
export const getReportCardsDefinition = ({ setSelectedReportCard }) => {
  return {
    header: card => (
      <Link
        fontSize="heading-m"
        onFollow={() => {
          setSelectedReportCard([card]);
        }}
      >
        {get(card, 'name', '')}
      </Link>
    ),
    sections: [
      {
        id: 'lastUpdated',
        header: 'Last Updated',
        content: card => {
          const lastUpdated = get(card, 'lastUpdated', '-');
          return !isEqual(lastUpdated, '-')
            ? getDateInMMDDYYYYHHMIFormat(lastUpdated)
            : lastUpdated;
        },
      },
    ],
  };
};
