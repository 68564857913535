import { set } from 'lodash';
import { createAction } from 'redux-actions';
import createDownloadFileFromS3Epic from '../epics/downloadFileFromS3Util';

const DOWNLOAD_FILE_FROM_S3_ACTION_NAME = 'DOWNLOAD_FILE_FROM_S3_ACTION';
export const downloadFileFromS3Action = createAction(DOWNLOAD_FILE_FROM_S3_ACTION_NAME);

const successCallback = (response, store, action) => {
  const { onDownloadSuccess } = action.payload;
  const { action: onDownloadSuccessAction, payload: onDownloadSuccessPayload } = onDownloadSuccess;
  set(onDownloadSuccessPayload, 'data', response.data);
  return [onDownloadSuccessAction(onDownloadSuccessPayload)];
};

const errorCallback = (response, store, action) => {
  const { onDownloadFailure } = action.payload;
  const { action: onDownloadFailureAction } = onDownloadFailure;
  return [onDownloadFailureAction()];
};

export const downloadFileFromS3Epic = createDownloadFileFromS3Epic({
  actionType: DOWNLOAD_FILE_FROM_S3_ACTION_NAME,
  successCallback,
  errorCallback,
});
