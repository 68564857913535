import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { SurveyEmployeeListTable } from './SurveyEmployeeListTable';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
  hyperLinkText: {
    color: '#007399',
  },
  subSection: {
    paddingTop: 8,
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
});

export function SurveyEmployeeSection({ data }) {
  const { section, hyperLinkText, subSection } = styles;
  const employeeData = get(data, 'employeeData', []);

  const issueLink =
    'https://issues.amazon.com/issues/create?template=6c5aeeab-46c2-4b2c-aa3d-fe6fce6004a4';
  return (
    <View>
      <Text style={section}>
        Review the employee(s) assigned to your survey. You will be asked to enter the percentage of
        time each employee spent on various projects and activities in {data.studyPeriod}.
      </Text>
      <SurveyEmployeeListTable employeeData={employeeData} />
      <Text style={subSection}>
        Need to add or remove an employee from your survey? Request through{' '}
        <Text style={hyperLinkText} src={issueLink}>
          SIM
        </Text>{' '}
        or contact{' '}
        <Text style={hyperLinkText} src="mailto:usrdtax@amazon.com">
          usrdtax@amazon.com
        </Text>{' '}
      </Text>
      <Text style={subSection}>
        <Text>Describe the overall function of your team in 1-2 sentences.</Text>
      </Text>
      <Text style={subSection}>
        <Text style={{ color: 'black' }}>
          {get(data, 'userResponse.Employee.data.textBoxContent', '')}
        </Text>
      </Text>
    </View>
  );
}

SurveyEmployeeSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyEmployeeSection;
