import { set } from 'lodash';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../../redux/actions/action_utils';
import { CREATE_NOTIFICATION_SCHEDULED } from '../../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../../redux/epics/utils';

export const CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES = createAPILifecycleActionTypes(
  'CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES',
);
export const createNotificationScheduleAction = createAPILifecycleActions(
  CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const payloadGenerator = async ({ originalPayload }) => {
  const newPayload = {};
  set(newPayload, ['body'], originalPayload.body);
  return newPayload;
};

const successCallback = (response, store, action) => {
  const originalPayload = action.payload;
  const { onSuccess } = originalPayload;
  if (response.status === 200) {
    onSuccess(response.body.status);
  }
  return [createNotificationScheduleAction.SUCCESS(response)];
};

export const createNotificationScheduleEpic = createAmplifyAPICallEpic({
  actionType: CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.BEGIN,
  payloadGenerator,
  api: CREATE_NOTIFICATION_SCHEDULED,
  successCallback,
  errorCallback: () => [createNotificationScheduleAction.FAILURE()],
});
