import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { EmployeeActivityMatrixDescription } from './components/EmployeeActivityMatrixDescription';
import { EmployeeActivityAllocationMatrix } from './components/EmployeeActivityAllocationMatrix';

export function EmployeeActivityMatrixSection({ data }) {
  return (
    <View>
      <Heading name="Activity Allocation" />
      <EmployeeActivityMatrixDescription />
      <EmployeeActivityAllocationMatrix data={data} />
    </View>
  );
}

EmployeeActivityMatrixSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeActivityMatrixSection;
