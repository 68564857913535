import { Box } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import PropTypes from 'prop-types';

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div style={{ paddingTop: '0%' }}>
      <p style={{ fontSize: '44px', margin: '18px 18px 18px 0px', fontWeight: 'lighter' }}>
        {children}
      </p>
    </div>
  </div>
);

ValueWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
};

export default ValueWithLabel;
