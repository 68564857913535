import { from } from 'rxjs';
import { mergeMap, concatMap, catchError } from 'rxjs/operators';
import axios from 'axios';

const putFileInS3 = async originalPayload => {
  const { uploadToS3Payload } = originalPayload;
  const { presignedUrl, documentFile } = uploadToS3Payload;
  const response = await axios.put(presignedUrl, documentFile);
  return response;
};

const ZERO_ACTIONS_STREAM = () => [];
export default ({ actionType, successCallback, errorCallback }) => {
  if (!actionType) {
    return ZERO_ACTIONS_STREAM;
  }

  const epic = (action$, store) =>
    action$.ofType(actionType).pipe(
      mergeMap(action => {
        const originalPayload = action.payload;
        return from(putFileInS3(originalPayload)).pipe(
          concatMap(
            successCallback
              ? response => successCallback(response, store, action)
              : ZERO_ACTIONS_STREAM,
          ),
          catchError(
            errorCallback ? error => errorCallback(error, store, action) : ZERO_ACTIONS_STREAM,
          ),
        );
      }),
    );

  return epic;
};
