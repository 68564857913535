export const DEFAULT_TABLE_PAGE_SIZE = 20;

export const OPERATORS = ['=', '!=', ':', '!:'];

export const OPERATOR_TRANSFORMATION_MAP = {
  '=': 'equals',
  '!=': 'notEquals',
  ':': 'contains',
  '!:': 'notContains',
};

export const OPERATION_TRANSFORMATION_MAP = {
  and: '&&',
  or: '||',
};

export const UPDATE_QNQ_DATA_REFRESH_DELAY_TIME_IN_MS = 10000;

export const UPDATE_QRE_DATA_REFRESH_DELAY_TIME_IN_MS = 5000;

export const UPDATE_SURVEY_DATA_REFRESH_DELAY_TIME_IN_MS = 6000;

export const UPDATE_GROUPING_DATA_REFRESH_DELAY_TIME_IN_MS = 15000;
