import { combineReducers } from 'redux';
import projects from '../components/projects_tab/components/projects_table/redux/projectsQnq';
import costCenters from '../components/cost_centers_tab/components/costs_table/redux/costCentersQnq';
import updateCostCentersQnq from '../components/cost_centers_tab/components/costs_table/redux/updateCostCentersQnq';
import jobCodes from '../components/job_codes_tab/components/job_codes_table/redux/jobCodesQnq';
import updateJobCodesQnq from '../components/job_codes_tab/components/job_codes_table/redux/updateJobCodesQnq';
import updateProjectsQnq from '../components/projects_tab/components/projects_table/redux/updateProjectsQnq';

export default combineReducers({
  costCenters,
  jobCodes,
  projects,
  updateCostCentersQnq,
  updateJobCodesQnq,
  updateProjectsQnq,
});
