export const NOTIFICATION_TYPE_ERROR = 'error';
export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_INFO = 'info';

export const PAGE_NOTIFICATIONS = {
  // Below is a sample, will be used later
  // GET_PROCESSES_FAILED: {
  //   type: NOTIFICATION_TYPE_ERROR,
  //   content: 'Some problem occurred while fetching processes list!',
  // }
};
