import { createAction } from 'redux-actions';

export const CONFIGURE_REFRESH_STATE_ACTION_TYPES = {
  NAME: 'CONFIGURE_REFRESH_STATE_ACTION_TYPES',
  BEGIN: 'CONFIGURE_REFRESH_STATE_ACTION_TYPES_BEGIN',
  CLEAR: 'CONFIGURE_REFRESH_STATE_ACTION_TYPES_CLEAR',
};
export const configureRefreshStateAction = {
  BEGIN: createAction(CONFIGURE_REFRESH_STATE_ACTION_TYPES.BEGIN),
  CLEAR: createAction(CONFIGURE_REFRESH_STATE_ACTION_TYPES.CLEAR),
};

const initialState = {
  isRefreshingData: false,
  refreshDelayTime: 0,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIGURE_REFRESH_STATE_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isRefreshingData: true,
        refreshDelayTime: payload,
      };
    case CONFIGURE_REFRESH_STATE_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};
