import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
});

export function SurveyAttestationSectionDescription() {
  const { section } = styles;

  return (
    <View style={section}>
      <Text>Select all the methodology(s) you used to complete the survey.</Text>
    </View>
  );
}

export default SurveyAttestationSectionDescription;
