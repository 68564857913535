import { cloneDeep, filter, get } from 'lodash';
import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { UPDATE_LABOR_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/labor_tab/components/labor_table/redux/updateLaborGrouping';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';
import { LIST_PERMISSIONS_ENDPOINT } from '../../../config/api_endpoints';
import { OVERRIDE_LABOR_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/labor_tab/components/labor_table/redux/overrideLaborGrouping';
import { addOrUpdateNotification, notificationActionHandler } from '../notifications/utils';
import { CONFIGURE_SURVEYS_ACTION_TYPES } from '../../../../app/survey/components/redux/surveyConfiguration';
import { OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/contract_tab/components/contract_table/redux/overrideContractGrouping';
import { UPDATE_CONTRACT_GROUPING_ACTION_TYPES } from '../../../../app/grouping/components/contract_tab/components/contract_table/redux/updateContractGrouping';
import { UPDATE_SURVEY_ACTION_TYPES } from '../../../../app/survey/redux/UpdateSurveys';
import { UPDATE_QRES_ACTION_TYPES } from '../../../../app/qre/redux/updateQREs';
import { EXPORT_DATA_ACTION_TYPES } from '../../../redux/export';
import { UPDATE_JOB_CODES_QNQ_ACTION_TYPES } from '../../../../app/qnq/components/job_codes_tab/components/job_codes_table/redux/updateJobCodesQnq';
import { UPDATE_COST_CENTERS_QNQ_ACTION_TYPES } from '../../../../app/qnq/components/cost_centers_tab/components/costs_table/redux/updateCostCentersQnq';
import { UPDATE_PROJECTS_QNQ_ACTION_TYPES } from '../../../../app/qnq/components/projects_tab/components/projects_table/redux/updateProjectsQnq';
import { TRIGGER_REPORT_REFRESH_ACTION_TYPES } from '../../../../app/analytics/components/report_table/redux/TriggerReportRefresh';
import { TRIGGER_IMPORT_ACTION_TYPES } from '../../../../app/import_data/redux/TriggerImport';
import { UPLOAD_FILE_TO_S3_ACTION_NAME } from '../../file_importer/redux/actions/UploadFileToS3';
import { CONFIGURE_NOTIFICATION_ACTION_TYPES } from '../../../../app/notification/configure_notification/redux/configure-notification-slice';
import { CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES } from '../../../../app/notification/configure_notification/redux/configureAttachmentState';
import { DELETE_NOTIFICATION_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/deleteNotifications';
import { CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/createNotificationSchedule';
import { GET_NOTIFICATION_DETAILS_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/getNotificationDetails';
import { CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES } from '../../../../app/notification/Notifications/components/Overview/redux/cancelScheduledNotification';

export const SET_PAGE_NOTIFICATION = 'app/common/ui/set_page_notification';
export const setPageNotification = createAction(SET_PAGE_NOTIFICATION);
export const CLEAR_PAGE_NOTIFICATION = 'app/common/ui/clear_page_notification';
export const clearPageNotification = createAction(CLEAR_PAGE_NOTIFICATION);

export const SET_PAGE_LOADING = 'app/common/ui/set_page_loading';
export const setPageLoading = createAction(SET_PAGE_LOADING);

const LIST_PERMISSIONS_ACTION_TYPES = createAPILifecycleActionTypes(
  'LIST_PERMISSIONS_ACTION_TYPES',
);

const loadingInitialState = false;
const loading = (state = loadingInitialState, action) => {
  switch (action.type) {
    case SET_PAGE_LOADING:
    case UPDATE_LABOR_GROUPING_ACTION_TYPES.BEGIN:
    case OVERRIDE_LABOR_GROUPING_ACTION_TYPES.BEGIN:
    case OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES.BEGIN:
    case CONFIGURE_SURVEYS_ACTION_TYPES.BEGIN:
    case UPDATE_CONTRACT_GROUPING_ACTION_TYPES.BEGIN:
    case UPDATE_QRES_ACTION_TYPES.BEGIN:
    case LIST_PERMISSIONS_ACTION_TYPES.BEGIN:
    case UPDATE_SURVEY_ACTION_TYPES.BEGIN:
    case EXPORT_DATA_ACTION_TYPES.BEGIN:
    case UPDATE_JOB_CODES_QNQ_ACTION_TYPES.BEGIN:
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.BEGIN:
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.BEGIN:
    case UPDATE_PROJECTS_QNQ_ACTION_TYPES.BEGIN:
    case CONFIGURE_NOTIFICATION_ACTION_TYPES.BEGIN:
    case UPLOAD_FILE_TO_S3_ACTION_NAME:
    case DELETE_NOTIFICATION_ACTION_TYPES.BEGIN:
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.BEGIN:
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.BEGIN:
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.BEGIN:
      return true;
    case UPDATE_LABOR_GROUPING_ACTION_TYPES.SUCCESS:
    case UPDATE_LABOR_GROUPING_ACTION_TYPES.FAILURE:
    case OVERRIDE_LABOR_GROUPING_ACTION_TYPES.SUCCESS:
    case OVERRIDE_LABOR_GROUPING_ACTION_TYPES.FAILURE:
    case OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES.SUCCESS:
    case OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES.FAILURE:
    case UPDATE_CONTRACT_GROUPING_ACTION_TYPES.SUCCESS:
    case UPDATE_CONTRACT_GROUPING_ACTION_TYPES.FAILURE:
    case CONFIGURE_SURVEYS_ACTION_TYPES.SUCCESS:
    case CONFIGURE_SURVEYS_ACTION_TYPES.FAILURE:
    case UPDATE_QRES_ACTION_TYPES.SUCCESS:
    case UPDATE_QRES_ACTION_TYPES.FAILURE:
    case LIST_PERMISSIONS_ACTION_TYPES.SUCCESS:
    case LIST_PERMISSIONS_ACTION_TYPES.FAILURE:
    case UPDATE_SURVEY_ACTION_TYPES.SUCCESS:
    case UPDATE_SURVEY_ACTION_TYPES.FAILURE:
    case EXPORT_DATA_ACTION_TYPES.SUCCESS:
    case EXPORT_DATA_ACTION_TYPES.FAILURE:
    case UPDATE_JOB_CODES_QNQ_ACTION_TYPES.SUCCESS:
    case UPDATE_JOB_CODES_QNQ_ACTION_TYPES.FAILURE:
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.SUCCESS:
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.FAILURE:
    case UPDATE_PROJECTS_QNQ_ACTION_TYPES.SUCCESS:
    case UPDATE_PROJECTS_QNQ_ACTION_TYPES.FAILURE:
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.SUCCESS:
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.FAILURE:
    case TRIGGER_IMPORT_ACTION_TYPES.SUCCESS:
    case TRIGGER_IMPORT_ACTION_TYPES.FAILURE:
    case CONFIGURE_NOTIFICATION_ACTION_TYPES.SUCCESS:
    case CONFIGURE_NOTIFICATION_ACTION_TYPES.FAILURE:
    case CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.ADD:
    case CONFIGURE_ATTACHMENT_STATE_ACTION_TYPES.FAILURE:
    case DELETE_NOTIFICATION_ACTION_TYPES.SUCCESS:
    case DELETE_NOTIFICATION_ACTION_TYPES.FAILURE:
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.SUCCESS:
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.FAILURE:
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.SUCCESS:
    case GET_NOTIFICATION_DETAILS_ACTION_TYPES.FAILURE:
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.SUCCESS:
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.FAILURE:
      return false;
    default:
      return state;
  }
};

const notificationInitialState = [];
const notification = (state = notificationInitialState, action) => {
  switch (action.type) {
    case UPDATE_LABOR_GROUPING_ACTION_TYPES.SUCCESS:
    case OVERRIDE_LABOR_GROUPING_ACTION_TYPES.SUCCESS:
    case OVERRIDE_CONTRACT_GROUPING_ACTION_TYPES.SUCCESS:
    case UPDATE_CONTRACT_GROUPING_ACTION_TYPES.SUCCESS:
    case CONFIGURE_SURVEYS_ACTION_TYPES.SUCCESS:
    case UPDATE_SURVEY_ACTION_TYPES.SUCCESS:
    case EXPORT_DATA_ACTION_TYPES.SUCCESS:
    case UPDATE_JOB_CODES_QNQ_ACTION_TYPES.SUCCESS:
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.SUCCESS:
    case UPDATE_PROJECTS_QNQ_ACTION_TYPES.SUCCESS:
    case TRIGGER_REPORT_REFRESH_ACTION_TYPES.SUCCESS:
    case TRIGGER_IMPORT_ACTION_TYPES.SUCCESS:
    case CONFIGURE_NOTIFICATION_ACTION_TYPES.SUCCESS:
    case DELETE_NOTIFICATION_ACTION_TYPES.SUCCESS:
    case CREATE_NOTIFICATION_SCHEDULED_ACTION_TYPES.SUCCESS:
    case CANCEL_SCHEDULED_NOTIFICATION_ACTION_TYPES.SUCCESS:
      return [...state, notificationActionHandler(action)];

    case SET_PAGE_NOTIFICATION:
      return addOrUpdateNotification(state, action);

    case CLEAR_PAGE_NOTIFICATION: {
      return filter(cloneDeep(state), ({ id }) => id !== get(action.payload, 'id'));
    }

    default:
      return state;
  }
};

export const listPermissionsAction = createAPILifecycleActions(LIST_PERMISSIONS_ACTION_TYPES.NAME);
const permissionsInitialState = {
  isFetching: false,
  data: null,
  error: null,
};
const permissions = (state = permissionsInitialState, { type, payload }) => {
  switch (type) {
    case LIST_PERMISSIONS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case LIST_PERMISSIONS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: get(payload, 'permissions', null),
        error: null,
      };
    case LIST_PERMISSIONS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case LIST_PERMISSIONS_ACTION_TYPES.CLEAR:
      return permissionsInitialState;
    default:
      return state;
  }
};

export const listPermissionsEpic = createAmplifyAPICallEpic({
  actionType: LIST_PERMISSIONS_ACTION_TYPES.BEGIN,
  api: LIST_PERMISSIONS_ENDPOINT,
  successCallback: response => [listPermissionsAction.SUCCESS(response.body)],
  errorCallback: () => [listPermissionsAction.FAILURE()],
});

export default combineReducers({
  ui: combineReducers({
    loading,
    notification,
  }),
  permissions,
});
