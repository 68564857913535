const REPORT_TYPES = {
  SURVEY_STATUS_REPORT: 'SurveyStatusReport',
  FINANCE_AND_VP_QUICKSIGHT_REPORT: 'FinanceAndVPQuicksightReport',
  DAILY_ANALYTICS_REPORT: 'DailyAnalyticsReport',
  COST_SHARE_REPORT: 'CostShareReport',
  PROJECT_REPORT: 'ProjectReport',
  PROJECT_DOCUMENT_REPORT: 'ProjectDocumentReport',
  COST_CENTER_ACTIVITY_REPORT: 'CostCenterActivityReport',
  PROJECT_ALLOCATION_REPORT: 'ProjectAllocationReport',
  DATA_DUMP_REPORT: 'DataDumpReport',
};

export const SCHEMA_TYPES = {
  SurveyStatusReport: 'SurveyStatusReport',
  FinanceAndVPQuicksightReport: 'FinanceAndVPQuicksightReport',
  DailyAnalyticsReport: 'DailyAnalyticsReport',
  ProjectReport: 'ProjectReport',
  ProjectDocumentReport: 'ProjectDocumentReport',
  CostCenterActivityReport: 'CostCenterActivityReport',
  ProjectAllocationReport: 'ProjectAllocationReport',
  DataDumpReport: 'DataDumpReport',
  CostShareReport: 'CostShareReport',
};

export const SCHEMA_TYPES_FOR_STUDY_PERIOD = {
  '2021': {
    ...SCHEMA_TYPES,
    CostShareReport: 'CostShareReport_2021',
  },
};

export const EXPORT_REQUEST_REPORT_TYPES = {
  SurveyStatusReport: 'SURVEY_STATUS_REPORT',
  FinanceAndVPQuicksightReport: 'FINANCE_AND_VP_QUICKSIGHT_REPORT',
  DailyAnalyticsReport: 'DAILY_ANALYTICS_REPORT',
  CostShareReport: 'COST_SHARE_REPORT',
  ProjectReport: 'PROJECT_REPORT',
  ProjectDocumentReport: 'PROJECT_DOCUMENT_REPORT',
  CostCenterActivityReport: 'COST_CENTER_ACTIVITY_REPORT',
  ProjectAllocationReport: 'PROJECT_ALLOCATION_REPORT',
  DataDumpReport: 'DATA_DUMP_REPORT',
};

export const GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES = {
  FinanceAndVPQuicksightReport: 'FINANCE_AND_VP_QUICKSIGHT_REPORT',
  DailyAnalyticsReport: 'DAILY_ANALYTICS',
  CostShareReport: 'COST_SHARE',
  ProjectReport: 'PROJECT_REPORT',
  ProjectDocumentReport: 'PROJECT_DOCUMENT_REPORT',
  CostCenterActivityReport: 'COST_CENTER_ACTIVITY',
  ProjectAllocationReport: 'PROJECT_ALLOCATION_REPORT',
  // DataDumpReport is not supported
};

export default REPORT_TYPES;
