import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Input, Textarea } from '@amzn/awsui-components-react';
import { get, size } from 'lodash';

const UpdateProjectsModal = props => {
  const { isVisible, onDismiss, onConfirm, onCancel, items } = props;

  const initialState = {
    name: '',
    description: '',
  };

  const [state, setState] = useState(initialState);

  useEffect(() => {
    setState({
      name: get(items, [0, 'projectName'], ''),
      description: get(items, [0, 'projectDescription'], ''),
    });
  }, [items]);

  const setName = name => {
    setState({ ...state, name });
  };

  const setDescription = description => {
    setState({ ...state, description });
  };

  const onCancelHandler = () => {
    onCancel();
    setState(initialState);
  };

  const onConfirmHandler = () => {
    onConfirm(state);
    setState(initialState);
  };

  const NameUpdater = () => {
    if (size(items) === 1) {
      return (
        <Grid gridDefinition={[{ colspan: 5 }]}>
          <Box>
            Name
            <Input
              onChange={({ detail }) => setName(detail.value)}
              value={state.name}
              inputMode="text"
            />
          </Box>
        </Grid>
      );
    }
    return null;
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" onClick={onConfirmHandler}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Edit Project Information"
    >
      <SpaceBetween size="l">
        {NameUpdater()}
        <Box>
          Description
          <Textarea
            onChange={({ detail }) => setDescription(detail.value)}
            value={state.description}
            placeholder="Enter Description"
          />
        </Box>
      </SpaceBetween>
    </Modal>
  );
};

UpdateProjectsModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
};

export default UpdateProjectsModal;
