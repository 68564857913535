import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../components/Heading';
import { ActivityList } from './components/ActivityList';
import { ActivityDescription } from './components/ActivityDescription';

export function SurveyActivitySection({ data, surveyType }) {
  return (
    <View>
      <Heading name="Activity" />
      <ActivityDescription data={data} surveyType={surveyType} />
      <ActivityList data={data} />
    </View>
  );
}

SurveyActivitySection.propTypes = {
  data: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
};

export default SurveyActivitySection;
