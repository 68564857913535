import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import omit from 'lodash/omit';
import { GET_STUDY_SUMMARY_ENDPOINT } from '../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import calculateAdditionalBenefits from '../utils/study_summary_utils';

const GET_STUDY_SUMMARY_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_STUDY_SUMMARY_ACTION_TYPES',
);
export const getStudySummaryActions = createAPILifecycleActions(
  GET_STUDY_SUMMARY_ACTION_TYPES.NAME,
);

const getStudySummary = payload => {
  if (!isObject(payload)) {
    return null;
  }

  const { summaryType, summary } = payload;
  if (!isString(summaryType) || !isString(summary)) {
    return null;
  }

  try {
    switch (summaryType) {
      case 'QRE':
        return { [summaryType]: calculateAdditionalBenefits(JSON.parse(summary)) };
      default:
        return { [summaryType]: JSON.parse(summary) };
    }
  } catch (e) {
    return null;
  }
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_STUDY_SUMMARY_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_STUDY_SUMMARY_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: isObject(state.data)
          ? { ...state.data, ...getStudySummary(payload) }
          : getStudySummary(payload),
        error: null,
      };
    case GET_STUDY_SUMMARY_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case GET_STUDY_SUMMARY_ACTION_TYPES.CLEAR:
      return {
        ...state,
        data: isObject(state.data) && isString(payload) ? omit(state.data, payload) : null,
      };
    default:
      return state;
  }
};

export const getStudySummaryEpic = createAmplifyAPICallEpic({
  actionType: GET_STUDY_SUMMARY_ACTION_TYPES.BEGIN,
  api: GET_STUDY_SUMMARY_ENDPOINT,
  successCallback: response => [getStudySummaryActions.SUCCESS(response.body)],
  errorCallback: () => [getStudySummaryActions.FAILURE()],
});
