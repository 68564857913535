import map from 'lodash/map';
import { get } from 'lodash';
import isEqual from 'lodash/isEqual';
import REPORT_TYPES from './report_types';

export const REPORT_CARDS = [
  {
    name: 'Daily analytics',
    id: REPORT_TYPES.DAILY_ANALYTICS_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'FGBS VP & Delegates',
    id: REPORT_TYPES.FINANCE_AND_VP_QUICKSIGHT_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'Source data',
    id: REPORT_TYPES.DATA_DUMP_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'Project allocation',
    id: REPORT_TYPES.PROJECT_ALLOCATION_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'Project',
    id: REPORT_TYPES.PROJECT_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'Project document',
    id: REPORT_TYPES.PROJECT_DOCUMENT_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'Cost center activity',
    id: REPORT_TYPES.COST_CENTER_ACTIVITY_REPORT,
    lastUpdated: '-',
  },
  {
    name: 'Cost share',
    id: REPORT_TYPES.COST_SHARE_REPORT,
    lastUpdated: '-',
  },
];

export const getDecoratedReportCards = ({ reportCards, reportingData }) => {
  return map(reportCards, ({ name, id, lastUpdated: oldLastUpdated }) => {
    const newLastUpdated = get(reportingData, [id, 'reportGeneratedOn'], '-');

    if (isEqual(oldLastUpdated, '-') || oldLastUpdated < newLastUpdated) {
      return { name, id, lastUpdated: newLastUpdated };
    }
    return { name, id, lastUpdated: oldLastUpdated };
  });
};
