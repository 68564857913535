import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PropTypes from 'prop-types';

const styles = StyleSheet.create({
  sectionCSS: {
    backgroundColor: '#C8C8C8',
    fontSize: 9,
    flexDirection: 'row',
    width: '100%',
    height: 70,
    borderWidth: 0.5,
    borderColor: '#000000',
    paddingTop: 10,
    paddingBottom: 5,
  },
  fieldsCSS: {
    width: '30%',
    height: '100%',
    textAlign: 'right',
    paddingRight: 7,
  },
  dataCSS: {
    width: '70%',
    height: '100%',
    textAlign: 'left',
  },
});

export function SurveyTemplateHeadingSection({ data }) {
  const { sectionCSS, fieldsCSS, dataCSS } = styles;
  const { surveyName, surveyType, studyPeriod, surveyGroupId, userName } = data;

  return (
    <View style={sectionCSS}>
      <View style={fieldsCSS}>
        <Text>Name:</Text>
        <Text>Description:</Text>
        <Text>Study Period:</Text>
        <Text>Company | Survey Group:</Text>
        <Text>Primary Assignee:</Text>
      </View>
      <View style={dataCSS}>
        <Text>{surveyName}</Text>
        <Text>{surveyType}</Text>
        <Text>{studyPeriod}</Text>
        <Text>{surveyGroupId}</Text>
        <Text>{userName}</Text>
      </View>
    </View>
  );
}

SurveyTemplateHeadingSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyTemplateHeadingSection;
