import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Grid, Textarea } from '@amzn/awsui-components-react';
import { filter, get, isEmpty, map } from 'lodash';
import { trim } from 'lodash/string';
import { join } from 'lodash/array';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { each, includes } from 'lodash/collection';
import isEqual from 'lodash/isEqual';
import { updateQREsActions } from '../../../redux/updateQREs';
import { QRE_CLASSIFICATION, QRE_JURISDICTIONS, QRE_TYPES } from '../../../constants/qre_constants';

const QRE_JURISDICTION_CONFIG = {
  [QRE_CLASSIFICATION.LABOR]: {
    [QRE_JURISDICTIONS.FEDERAL]: {
      header: 'Clear QRE - Federal',
      instruction: 'Clear Federal and State QRE for the following employee(s)',
      warning: 'Once cleared, the Federal and State QRE for these employees will become zero.',
    },
    [QRE_JURISDICTIONS.STATE]: {
      header: 'Clear QRE - State',
      instruction: 'Clear State QRE for the following employee(s)',
      warning: 'Once cleared, the State QRE for these employees will become zero.',
    },
  },
  [QRE_CLASSIFICATION.CONTRACT]: {
    [QRE_JURISDICTIONS.FEDERAL]: {
      header: 'Clear QRE - Federal',
      instruction: 'Clear Federal and State QRE for the following vendor(s)',
      warning: 'Once cleared, the Federal and State QRE for these vendor will become zero.',
    },
    [QRE_JURISDICTIONS.STATE]: {
      header: 'Clear QRE - State',
      instruction: 'Clear State QRE for the following vendor(s)',
      warning: 'Once cleared, the State QRE for these vendors will become zero.',
    },
  },
};

const CLEAR_QRE_VALUE = 0.0;

const ClearQREModal = ({
  qreClassification,
  qreJurisdiction,
  isVisible,
  onDismiss,
  onConfirm,
  onCancel,
  selectedItems,
  updateQREsBegin,
}) => {
  const initialState = {
    clearQREReason: null,
  };
  const [state, setState] = useState(initialState);

  const getClearQREReason = () => {
    return get(state, 'clearQREReason', '');
  };
  const setClearQREReason = reason => {
    setState({ ...state, clearQREReason: reason });
  };

  const hasReasonBeenProvidedYet = () => {
    return !isEmpty(trim(get(state, 'clearQREReason', '')));
  };

  const getAffectedEmployeesNamesString = () => {
    return join(
      map(selectedItems, ({ qreEntityName }) => (isEmpty(qreEntityName) ? '-' : qreEntityName)),
      ', ',
    );
  };

  const getApplicableQREKeys = () => {
    const applicableJurisdictions = isEqual(qreJurisdiction, QRE_JURISDICTIONS.FEDERAL)
      ? [QRE_JURISDICTIONS.FEDERAL, QRE_JURISDICTIONS.STATE]
      : [QRE_JURISDICTIONS.STATE];

    return map(
      filter(QRE_TYPES, ({ classification, jurisdiction }) => {
        return (
          isEqual(qreClassification, classification) &&
          includes(applicableJurisdictions, jurisdiction)
        );
      }),
      ({ key }) => key,
    );
  };

  const onConfirmHandler = () => {
    const qreUpdateReason = getClearQREReason();
    const updateQREList = [];
    each(selectedItems, ({ qreId }) => {
      each(getApplicableQREKeys(), qreKey => {
        updateQREList.push({
          qreId,
          qreKey,
          qreValue: CLEAR_QRE_VALUE,
          qreUpdateReason,
        });
      });
    });

    updateQREsBegin({
      body: {
        updateQREList,
      },
    });

    onConfirm();
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="large"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onConfirmHandler}
              disabled={!hasReasonBeenProvidedYet()}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={get(QRE_JURISDICTION_CONFIG, [qreClassification, qreJurisdiction, 'header'], '')}
    >
      <SpaceBetween size="l">
        <Box>
          <div>
            {get(QRE_JURISDICTION_CONFIG, [qreClassification, qreJurisdiction, 'instruction'], '')}
          </div>
          <div>{getAffectedEmployeesNamesString()}</div>
        </Box>
        <Grid gridDefinition={[{ colspan: 6 }]}>
          <Textarea
            onChange={({ detail }) => setClearQREReason(detail.value)}
            value={getClearQREReason()}
          />
        </Grid>
        <Alert type="warning">
          {get(QRE_JURISDICTION_CONFIG, [qreClassification, qreJurisdiction, 'warning'], '')}
        </Alert>
      </SpaceBetween>
    </Modal>
  );
};

ClearQREModal.propTypes = {
  qreClassification: PropTypes.string.isRequired,
  qreJurisdiction: PropTypes.string.isRequired,
  selectedItems: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  updateQREsBegin: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateQREsBegin: updateQREsActions.BEGIN,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(ClearQREModal);
