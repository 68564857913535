import { get, set } from 'lodash';
import isObject from 'lodash/isObject';
import { createAction } from 'redux-actions';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../redux/actions/action_utils';
import { GET_REPORT_REFRESH_STATUS } from '../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../redux/epics/utils';
import { GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES } from '../../../constants/report_types';
import { REPORT_REFRESH_STATUS } from '../../../constants/api_constants';

export const GET_REPORT_REFRESH_STATUS_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_REPORT_REFRESH_STATUS_ACTION_TYPES',
);
export const getReportRefreshStatusAction = createAPILifecycleActions(
  GET_REPORT_REFRESH_STATUS_ACTION_TYPES.NAME,
);
export const SET_REPORT_REFRESH_STATUS_ACTION_TYPES = {
  NAME: 'SET_REPORT_REFRESH_STATUS_ACTION_TYPES',
  BEGIN: 'SET_REPORT_REFRESH_STATUS_ACTION_TYPES_BEGIN',
};
export const setReportRefreshStatusAction = {
  BEGIN: createAction(SET_REPORT_REFRESH_STATUS_ACTION_TYPES.BEGIN),
};

const getData = payload => {
  if (!isObject(payload)) {
    return null;
  }
  const reports = get(payload, 'body.reports', []);
  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const report of reports) {
    if (report) {
      const reportType = get(report, 'reportType');
      const reportRefreshStatus = get(report, 'reportRefreshStatus');
      const reportId = get(report, 'reportId');
      set(data, [reportType], { reportRefreshStatus, reportId });
    }
  }
  return data;
};

const setData = () => {
  const data = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const reportType in GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES) {
    if (reportType)
      set(
        data,
        [GET_REPORT_REFRESH_STATUS_REQUEST_REPORT_TYPES[reportType], 'reportRefreshStatus'],
        REPORT_REFRESH_STATUS.InProgress,
      );
  }
  return data;
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPORT_REFRESH_STATUS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_REPORT_REFRESH_STATUS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...getData(payload),
        },
        error: null,
      };
    case GET_REPORT_REFRESH_STATUS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };
    case GET_REPORT_REFRESH_STATUS_ACTION_TYPES.CLEAR:
      return initialState;
    case SET_REPORT_REFRESH_STATUS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        data: {
          ...setData(),
        },
        error: null,
      };
    default:
      return state;
  }
};

export const getReportRefreshStatusEpic = createAmplifyAPICallEpic({
  actionType: GET_REPORT_REFRESH_STATUS_ACTION_TYPES.BEGIN,
  api: GET_REPORT_REFRESH_STATUS,
  successCallback: response => [getReportRefreshStatusAction.SUCCESS(response)],
  errorCallback: () => [getReportRefreshStatusAction.FAILURE()],
});
