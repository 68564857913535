import { combineReducers } from 'redux';
import labor from '../components/labor_tab/components/labor_table/redux/laborGrouping';
import contract from '../components/contract_tab/components/contract_table/redux/contractGrouping';
import overrideLaborGrouping from '../components/labor_tab/components/labor_table/redux/overrideLaborGrouping';
import updateLaborGrouping from '../components/labor_tab/components/labor_table/redux/updateLaborGrouping';
import updateContractGrouping from '../components/contract_tab/components/contract_table/redux/updateContractGrouping';
import overrideContractGrouping from '../components/contract_tab/components/contract_table/redux/overrideContractGrouping';
import supplies from '../components/supplies_tab/components/supplies_table/redux/suppliesPopulation';

const grouping = combineReducers({
  labor,
  contract,
  updateContractGrouping,
  overrideContractGrouping,
  updateLaborGrouping,
  overrideLaborGrouping,
  supplies,
});
export default grouping;
