export const VALARI_API_NAME = 'ValariAPI';

export const LIST_PERMISSIONS_ENDPOINT = '/listPermissions';
export const GET_SCHEMAS_ENDPOINT = '/getSchema';
export const GET_POPULATION_OVERVIEW_ENDPOINT = '/getPopulationOverview';
export const SEARCH_POPULATION_ENDPOINT = '/searchPopulation';
export const SEARCH_COST_CENTERS_QNQ_ENDPOINT = '/searchCostCenters';
export const SEARCH_PROJECTS_QNQ_ENDPOINT = '/searchProjects';
export const SEARCH_JOB_CODES_QNQ_ENDPOINT = '/searchJobCodes';
export const UPDATE_COST_CENTERS_QNQ_ENDPOINT = '/updateCostCenters';
export const UPDATE_JOB_CODES_QNQ_ENDPOINT = '/updateJobCodes';
export const UPDATE_PROJECTS_QNQ_ENDPOINT = '/updateProjects';
export const SEARCH_GROUPING_ENDPOINT = '/searchGrouping';
export const UPDATE_GROUPING_ENDPOINT = '/generateGrouping';
export const OVERRIDE_GROUPING_ENDPOINT = '/overrideGrouping';
export const SEARCH_SURVEYS_ENDPOINT = '/searchSurveys';
export const CONFIGURE_SURVEYS_ENDPOINT = '/configuresurvey';
export const UPDATE_SURVEYS_ENDPOINT = '/updateSurveys';
export const GET_STUDY_SUMMARY_ENDPOINT = '/getStudySummary';
export const SEARCH_NOTIFICATIONS_ENDPOINT = '/searchNotifications';
export const GET_NOTIFICATION_EVENTS_OVERVIEW_ENDPOINT = '/getNotificationEventsOverview';
export const CONFIGURE_NOTIFICATION_ENDPOINT = '/configureNotification';
export const SEARCH_QRES_ENDPOINT = '/searchQREs';
export const UPDATE_QRES_ENDPOINT = '/updateQREs';
export const EXPORT_DATA_ENDPOINT = '/ExportData';
export const GET_SURVEYS_BY_USER_ENDPOINT = '/getAllSurveysForUser';
export const GET_ACTIVITIES_ENDPOINT = '/getAllActivities';
export const GET_EMPLOYEES_FOR_SURVEY_ENDPOINT = '/GetEmployeesForSurvey';
export const GET_VENDORS_FOR_SURVEY_ENDPOINT = '/GetVendorsForSurvey';
export const SEARCH_REPORT_ITEMS = '/searchReportItems';
export const VIEW_EMAIL_ENDPOINT = '/getEntityFilePresignedDownloadURL';
export const GET_PROJECT_DOCUMENTS = '/getProjectDocuments';
export const TRIGGER_REPORT_REFRESH = '/triggerReportRefresh';
export const GET_REPORT_REFRESH_STATUS = '/getReportRefreshStatus';
export const TRIGGER_IMPORT = '/triggerImport';
export const GET_DOCUMENT_UPLOAD_PRESIGNED_URL = '/getDocumentUploadPresignedURL';
export const GET_NOTIFICATION_FORM_DATA = '/getNotificationFormData';
export const DELETE_NOTIFICATION = '/deleteNotification';
export const CREATE_NOTIFICATION_SCHEDULED = '/createNotificationSchedule';
export const GET_NOTIFICATION_DETAILS = '/getNotificationDetails';
export const CANCEL_SCHEDULED_NOTIFICATION = '/cancelNotificationSchedule';
export const GET_IMPORT_METADATA = '/getImportMetadata';
