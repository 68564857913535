const alertText = type => {
  switch (type) {
    case 'Labor':
      return (
        'Once confirmed, the selected employee(s) ' +
        'will be removed from the survey(s) and QRE will not be computed for these employee(s).'
      );
    case 'Contract':
      return (
        'Once confirmed, the selected vendor(s) ' +
        'will be removed from the survey(s) and QRE will not be computed for these vendor(s).'
      );
    default:
      return '';
  }
};
export default alertText;
