import Box from '@amzn/awsui-components-react/polaris/box';
import Button from '@amzn/awsui-components-react/polaris/button';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Grid, Icon } from '@amzn/awsui-components-react';
import { get, map, sortedUniq, isEmpty, isUndefined } from 'lodash';
import transformDate from '../../constants/utils';
import {
  changeDateToEpoch,
  getTodayDateInUSTimeZone,
} from '../../../../common/utils/date_time_util';

const UpdateSurveyDurationModal = props => {
  const { isVisible, onDismiss, onConfirm, onCancel, records } = props;
  const initialDates = {
    initialReleaseDate: '',
    releaseDate: '',
    dueDate: '',
    disableConfirm: true,
  };
  const [dates, setDates] = React.useState(initialDates);
  const todayDate = getTodayDateInUSTimeZone();

  useEffect(() => {
    let sideEffectDates;
    const releaseDate = get(records[0], 'releaseDate', '');
    const dueDate = get(records[0], 'endDate', '');

    if (!isEmpty(records)) {
      sideEffectDates = {
        initialReleaseDate: releaseDate === '' ? todayDate : transformDate(releaseDate),
        releaseDate: releaseDate === '' ? todayDate : transformDate(releaseDate),
        dueDate: dueDate === '' ? todayDate : transformDate(dueDate),
        disableConfirm: true,
      };
    }
    setDates(sideEffectDates);
    // eslint-disable-next-line
  }, [records]);

  const getSMEs = () => {
    return sortedUniq(map(records, ({ userName }) => userName)).join(', ');
  };

  function disableHandler(release, due) {
    const initialReleaseDate = get(dates, 'initialReleaseDate');
    if (
      isUndefined(release) ||
      isUndefined(due) ||
      (release < todayDate && initialReleaseDate >= todayDate) ||
      due < todayDate ||
      due < release
    )
      return true;
    return false;
  }

  const onConfirmHandler = () => {
    const res = {
      releaseDate: changeDateToEpoch(dates.releaseDate, 'releaseDate'),
      dueDate: changeDateToEpoch(dates.dueDate, 'endDate'),
    };
    onConfirm(records, res);
  };

  const disabledReleaseDateInfo = () => {
    return todayDate >= get(dates, 'initialReleaseDate') ? (
      <SpaceBetween size="xs" direction="horizontal">
        <Box padding={{ top: 'xs' }}>
          <Icon name="status-info" size="small" variant="link" />
        </Box>
        <Box color="text-status-info" variant="p">
          Survey(s) has been released.
        </Box>
      </SpaceBetween>
    ) : (
      <div />
    );
  };

  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={onConfirmHandler}
              disabled={get(dates, 'disableConfirm', true)}
            >
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Update survey duration"
    >
      <SpaceBetween size="l">
        <Box variant="p">
          Update the survey duration for survey(s) of the following SME(s):
          <Box variant="p">{getSMEs()}</Box>
        </Box>
        <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
          <Box>
            Release date
            <DatePicker
              disabled={todayDate >= get(dates, 'initialReleaseDate')}
              onChange={({ detail }) => {
                const disableConfirm = disableHandler(detail.value, get(dates, 'dueDate'));
                setDates({ ...dates, releaseDate: detail.value, disableConfirm });
              }}
              isDateEnabled={date => transformDate(date) >= todayDate}
              value={get(dates, 'releaseDate')}
              placeholder="YYYY/MM/DD"
            />
            {disabledReleaseDateInfo()}
          </Box>
          <Box>
            Due date
            <DatePicker
              onChange={({ detail }) => {
                const disableConfirm = disableHandler(get(dates, 'releaseDate'), detail.value);
                setDates({ ...dates, dueDate: detail.value, disableConfirm });
              }}
              value={get(dates, 'dueDate')}
              isDateEnabled={date =>
                transformDate(date) >= todayDate && transformDate(date) > get(dates, 'releaseDate')
              }
              placeholder="YYYY/MM/DD"
            />
          </Box>
        </Grid>
      </SpaceBetween>
    </Modal>
  );
};

UpdateSurveyDurationModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
};

export default UpdateSurveyDurationModal;
