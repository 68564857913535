import { createAction } from 'redux-actions';

export const CONFIGURE_TEMPLATE_STATE_ACTION_TYPES = {
  NAME: 'CONFIGURE_TEMPLATE_STATE_ACTION',
  SET: 'CONFIGURE_TEMPLATE_STATE_ACTION_SET',
  CLEAR: 'CONFIGURE_TEMPLATE_STATE_ACTION_CLEAR',
};
export const configureTemplateStateAction = {
  SET: createAction(CONFIGURE_TEMPLATE_STATE_ACTION_TYPES.SET),
  CLEAR: createAction(CONFIGURE_TEMPLATE_STATE_ACTION_TYPES.CLEAR),
};

const initialState = {
  template: {},
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CONFIGURE_TEMPLATE_STATE_ACTION_TYPES.SET:
      return {
        ...state,
        template: payload.data,
      };
    case CONFIGURE_TEMPLATE_STATE_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};
