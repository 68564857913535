import { Box } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import PropTypes from 'prop-types';

const ValueWithLabel = ({ label, children }) => (
  <div>
    <Box margin={{ bottom: 'xxxs' }} color="text-label">
      {label}
    </Box>
    <div>{children}</div>
  </div>
);

ValueWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element]),
};

export default ValueWithLabel;
