export const AGGREGATIONS_IDS = {
  BY_SURVEY_TYPE: 'aggBySurveyType',
  BY_BUSINESS_UNIT: 'aggByBusinessUnit',
  BY_DATE: 'aggByDate',
};

export const AGGREGATION_ITEMS = [
  { id: AGGREGATIONS_IDS.BY_SURVEY_TYPE, label: 'Survey type', aggSelectorText: 'by survey type' },
  {
    id: AGGREGATIONS_IDS.BY_BUSINESS_UNIT,
    label: 'Business unit',
    aggSelectorText: 'by business unit',
  },
  {
    id: AGGREGATIONS_IDS.BY_DATE,
    label: 'Date',
    aggSelectorText: 'by date',
  },
];

export const AGG_BY_SURVEY_TYPE_SORT_ARRAY = ['Labor', 'Contract', 'Supplies'];
