export const ACCEPTED_IMPORT_FILE_TYPES = '.xlsx,.xls';

const IMPORT_TYPES = {
  PROJECT: 'Project',
  PROJECT_ALLOCATION: 'ProjectAllocation',
  FGBS: 'FGBS',
  CC: 'CC',
  JC: 'JC',
  HR_AND_PAYROLL: 'HRAndPayroll',
  PAYROLL_ONLY: 'PayrollOnly',
  CONTRACTS: 'ContractsData',
  SUPPLIES: 'SuppliesData',
  LABOR_GROUPING: 'LaborGrouping',
  CONTRACT_GROUPING: 'ContractsGrouping',
  UPDATE_SURVEY_DURATION: 'UpdateSurveyDuration',
};

export const SUPPORTED_IMPORT_TYPES = {
  QNQ_CC: [IMPORT_TYPES.CC, IMPORT_TYPES.FGBS],
  QNQ_JC: [IMPORT_TYPES.JC],
  QNQ_PROJECTS: [IMPORT_TYPES.PROJECT, IMPORT_TYPES.PROJECT_ALLOCATION],
  POPULATION_LABOR: [IMPORT_TYPES.HR_AND_PAYROLL, IMPORT_TYPES.PAYROLL_ONLY],
  POPULATION_CONTRACTS: [IMPORT_TYPES.CONTRACTS],
  POPULATION_SUPPLIES: [IMPORT_TYPES.SUPPLIES],
  LABOR_GROUPING: [IMPORT_TYPES.LABOR_GROUPING],
  CONTRACT_GROUPING: [IMPORT_TYPES.CONTRACT_GROUPING],
  SURVEY: [IMPORT_TYPES.UPDATE_SURVEY_DURATION],
};

export const IMPORT_TYPE_NAMES = {
  Project: 'Project',
  ProjectAllocation: 'Project Allocation',
  FGBS: 'FGBS',
  CC: 'Cost Center',
  JC: 'Job Code',
  HRAndPayroll: 'Personnel',
  PayrollOnly: 'Payroll',
  ContractsData: 'Contracts',
  SuppliesData: 'Supplies',
  LaborGrouping: 'Labor Grouping',
  ContractsGrouping: 'Contract Grouping',
  UpdateSurveyDuration: 'Update Duration',
};

export const IMPORT_DISABLE_POSSIBLE = {
  Project: false,
  ProjectAllocation: false,
  FGBS: false,
  CC: true,
  JC: true,
  HRAndPayroll: false,
  PayrollOnly: false,
  ContractsData: true,
  LaborGrouping: false,
  SuppliesData: false,
  ContractsGrouping: true,
  UpdateSurveyDuration: false,
};

export const IMPORT_STATUS = {
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed',
  Unknown: 'Unknown',
};

export const IMPORT_STATUS_NAME = {
  InProgress: 'In progress',
  Completed: 'Completed',
  Failed: 'Failed',
  Unknown: '-',
};
