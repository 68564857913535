import { GET_SCHEMAS_ENDPOINT } from '../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../actions/action_utils';
import createAmplifyAPICallEpic from '../../../epics/utils';

const GET_SCHEMA = createAPILifecycleActionTypes('GET_SCHEMA');
export const getSchemaActions = createAPILifecycleActions(GET_SCHEMA.NAME);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SCHEMA.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SCHEMA.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case GET_SCHEMA.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case GET_SCHEMA.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const getSchemasEpic = createAmplifyAPICallEpic({
  actionType: GET_SCHEMA.BEGIN,
  api: GET_SCHEMAS_ENDPOINT,
  successCallback: response => [getSchemaActions.SUCCESS(response.body)],
  errorCallback: () => [getSchemaActions.FAILURE()],
});
