import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { VendorActivityMatrixDescription } from './components/VendorActivityMatrixDescription';
import { VendorActivityAllocationMatrix } from './components/VendorActivityAllocationMatrix';

export function VendorActivityMatrixSection({ data }) {
  return (
    <View>
      <Heading name="Activity Allocation" />
      <VendorActivityMatrixDescription />
      <VendorActivityAllocationMatrix data={data} />
    </View>
  );
}

VendorActivityMatrixSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VendorActivityMatrixSection;
