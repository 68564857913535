import PropTypes from 'prop-types';
import React from 'react';
import { Auth } from 'aws-amplify';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import { SideNavigation } from '@amzn/awsui-components-react/polaris';
import { getNavBarHeaderConfig, NAVBAR_ITEMS_CONFIG, SIGN_OUT_HREF } from './constants';
import { getStudyPeriod } from '../../../../constants/study_period';

const NavBar = props => {
  const onFollowHandler = event => {
    event.preventDefault();

    if (event.detail.href && props) {
      const { navigateTo } = props;
      const { href } = event.detail;

      if (href === SIGN_OUT_HREF) {
        Auth.signOut();
      } else if (navigateTo) {
        navigateTo(href);
      }
    }
  };

  const { currentPathName, studyPeriod } = props;
  return (
    <SideNavigation
      activeHref={currentPathName}
      onFollow={onFollowHandler}
      header={getNavBarHeaderConfig(studyPeriod)}
      items={NAVBAR_ITEMS_CONFIG}
    />
  );
};

NavBar.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  currentPathName: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    currentPathName: get(state, 'router.location.pathname'),
    studyPeriod: getStudyPeriod(state),
  };
};
const mapDispatchToProps = dispatch => {
  return {
    navigateTo: payload => dispatch(push(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
