import isObject from 'lodash/isObject';
import { get } from 'lodash';
import isString from 'lodash/isString';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../redux/actions/action_utils';
import { extractTableDataFromPayload } from '../../../../../common/components/table/utils/table_redux_payload_utils';
import { SEARCH_REPORT_ITEMS } from '../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../redux/epics/utils';

const SEARCH_REPORTS_ACTION_TYPES = createAPILifecycleActionTypes('SEARCH_REPORTS_ACTION_TYPES');
export const searchReportsAction = createAPILifecycleActions(SEARCH_REPORTS_ACTION_TYPES.NAME);

const getReportData = (stateData, payload) => {
  if (!isObject(payload)) {
    return null;
  }

  const { reportType } = payload;
  if (!isString(reportType)) {
    return null;
  }

  try {
    return {
      [reportType]: {
        ...get(stateData, [reportType], {}),
        ...extractTableDataFromPayload(payload),
        reportGeneratedOn: get(payload, ['reportGeneratedOn'], ''),
        reportId: get(payload, ['reportId'], ''),
      },
    };
  } catch (e) {
    return null;
  }
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_REPORTS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_REPORTS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...getReportData(state.data, payload),
        },
        error: null,
      };
    case SEARCH_REPORTS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case SEARCH_REPORTS_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const searchReportsEpic = createAmplifyAPICallEpic({
  actionType: SEARCH_REPORTS_ACTION_TYPES.BEGIN,
  api: SEARCH_REPORT_ITEMS,
  successCallback: response => [searchReportsAction.SUCCESS(response.body)],
  errorCallback: () => [searchReportsAction.FAILURE()],
});
