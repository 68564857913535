import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../common/components/table/constants';
import { getImportStatusHeaderDescription } from '../../../../../../import_data/utils/import_utils';

import JobCodesActions from '../components/JobCodesActions';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../../import_data/constants/import_types';

const EmptyTableView = () => <p>No job codes.</p>;

export default ({ importTypeMetadata }) => {
  return {
    dataTrackingId: 'JobCodeId',
    dataLoadingText: 'Loading job codes',
    emptyElement: EmptyTableView,
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Job Codes',
      description: getImportStatusHeaderDescription(importTypeMetadata),
      Actions: JobCodesActions,
      importTypeMetadata,
    },
    // TODO : This needs to be dynamic
    getDataRequestBody: () => {
      return {};
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes: SUPPORTED_IMPORT_TYPES.QNQ_JC,
        },
      };
    },
  };
};
