import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../components/Heading';
import { SurveyAttestationSectionDescription } from './components/SurveyAttestationSectionDescription';
import { SurveyAttestationSectionList } from './components/SurveyAttestationSectionList';

export function SurveyAttestationSection({ data }) {
  return (
    <View>
      <Heading name="Submit" />
      <SurveyAttestationSectionDescription />
      <SurveyAttestationSectionList data={data} />
    </View>
  );
}

SurveyAttestationSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyAttestationSection;
