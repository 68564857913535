import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Container,
  FormField,
  Grid,
  Header,
  Input,
  Multiselect,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { get, set, map } from 'lodash';
import debounce from '@amzn/awsui-components-react/polaris/internal/debounce';
import { bindActionCreators } from 'redux';
import RadioGroup from '@amzn/awsui-components-react/polaris/radio-group';
import getOptionsDefinition from './utils/util';
import { DEBOUNCE_DELAY } from '../../../grouping/components/common/constants/update_selected_constants';
import { DEFAULT_BUSINESS_ID } from '../../../../common/config/api_constants';
import { searchCostCentersQNQAction } from '../../../qnq/components/cost_centers_tab/components/costs_table/redux/costCentersQnq';

const DetailComponent = ({
  detailComponentState,
  setDetailComponentState,
  templateOptions,
  applicableToOptions,
  ccListOptions,
  recipientOptions,
  applicableCCOperationOptions,
  costCenterData,
  isFetchingCostCenters,
  searchCostCenter,
  applicableEntitySectionOptions,
}) => {
  const getStatus = () => {
    if (isFetchingCostCenters) {
      return 'loading';
    }
    return 'finished';
  };

  const fetchCostCenters = filteringText => {
    searchCostCenter({
      body: {
        query: `fgbsVpName equals ${filteringText} || fgbsVpName contains ${filteringText}`,
        businessId: DEFAULT_BUSINESS_ID,
        pageSize: 20,
        pageNumber: 1,
      },
    });
  };

  const getCostCenters = () => {
    const data = get(costCenterData, '1', []);
    const dataMap = {};
    // eslint-disable-next-line array-callback-return
    data.map(obj => {
      const prevArray = get(dataMap, obj.fgbsVpName, []);
      prevArray.push(obj);
      set(dataMap, obj.fgbsVpName, prevArray);
    });

    const costCenterOptions = [];
    Object.keys(dataMap).forEach(key => {
      costCenterOptions.push({
        label: key,
        options: map(
          dataMap[key],
          ({ AmazonOFACostCenterNumber, CostCenterNumber, CostCenterName }) => {
            return {
              label: CostCenterName,
              description: `${CostCenterNumber}_${AmazonOFACostCenterNumber}`,
              value: `${CostCenterNumber}_${AmazonOFACostCenterNumber}`,
            };
          },
        ),
      });
    });
    return costCenterOptions;
  };

  const [filterText, setFilterText] = useState('');

  const handleLoadItems = ({ detail: { filteringText } }) => {
    if (filterText === filteringText) return;
    setFilterText(filteringText);
    fetchCostCenters(filteringText);
  };

  return (
    <Container header={<Header variant="h2">Details</Header>}>
      <SpaceBetween size="l">
        <FormField label="Notification name">
          <Input
            value={detailComponentState.name}
            onChange={event =>
              setDetailComponentState(previousDetail => ({
                ...previousDetail,
                name: event.detail.value,
              }))
            }
          />
        </FormField>

        <FormField label="Template" description="To add a new template, contact Taskless">
          <Select
            options={templateOptions}
            selectedOption={detailComponentState.template}
            onChange={({ detail }) =>
              setDetailComponentState(previousDetail => ({
                ...previousDetail,
                template: detail.selectedOption,
              }))
            }
          />
        </FormField>

        <FormField label="Recipient">
          <Select
            options={recipientOptions}
            selectedOption={detailComponentState.recipient}
            onChange={({ detail }) =>
              setDetailComponentState(previousDetail => ({
                ...previousDetail,
                recipient: detail.selectedOption,
              }))
            }
          />
        </FormField>

        <FormField
          label={
            <span>
              CC <i>- optional</i>{' '}
            </span>
          }
        >
          <Multiselect
            selectedOptions={detailComponentState.selectedCCList}
            onChange={({ detail }) =>
              setDetailComponentState(previousDetail => ({
                ...previousDetail,
                selectedCCList: detail.selectedOptions,
              }))
            }
            deselectAriaLabel={e => `Remove ${e.label}`}
            options={ccListOptions}
            placeholder="Choose options"
            selectedAriaLabel="Selected"
          />
        </FormField>

        <FormField label="Applicable to">
          <Select
            options={applicableToOptions}
            selectedOption={detailComponentState.applicableTo}
            onChange={({ detail }) =>
              setDetailComponentState(previousDetail => ({
                ...previousDetail,
                applicableTo: detail.selectedOption,
              }))
            }
          />
        </FormField>
        <FormField
          label={
            <span>
              Applicable cost centers <i>- optional</i>{' '}
            </span>
          }
          description="If no cost center is selected, 
        notifications will be sent to recipients in all cost centers."
        >
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <Select
              options={applicableCCOperationOptions}
              selectedOption={detailComponentState.applicableCCOperation}
              onChange={({ detail }) =>
                setDetailComponentState(previousDetail => ({
                  ...previousDetail,
                  applicableCCOperation: detail.selectedOption,
                }))
              }
            />
            <Multiselect
              selectedOptions={detailComponentState.selectedCostCenterIdentifier}
              onChange={({ detail }) =>
                setDetailComponentState(prevDetails => ({
                  ...prevDetails,
                  selectedCostCenterIdentifier: detail.selectedOptions,
                }))
              }
              statusType={getStatus()}
              onLoadItems={debounce(handleLoadItems, DEBOUNCE_DELAY)}
              loadingText="Loading cost centers"
              options={getCostCenters()}
              filteringType="auto"
              selectedAriaLabel="Selected"
              errorText="Error fetching results."
            />
          </Grid>
        </FormField>
        <FormField label={<span>Applicable entity section</span>}>
          <RadioGroup
            onChange={({ detail }) =>
              setDetailComponentState(prevDetails => ({
                ...prevDetails,
                selectedApplicableEntitySection: {
                  value: detail.value,
                  label: detail.label,
                },
              }))
            }
            value={detailComponentState.selectedApplicableEntitySection.value}
            items={applicableEntitySectionOptions}
          />
        </FormField>
      </SpaceBetween>
    </Container>
  );
};

DetailComponent.propTypes = {
  detailComponentState: PropTypes.object.isRequired,
  setDetailComponentState: PropTypes.func.isRequired,
  templateOptions: PropTypes.array.isRequired,
  applicableToOptions: PropTypes.array.isRequired,
  ccListOptions: PropTypes.array.isRequired,
  recipientOptions: PropTypes.array.isRequired,
  applicableCCOperationOptions: PropTypes.array.isRequired,
  costCenterData: PropTypes.object,
  isFetchingCostCenters: PropTypes.bool.isRequired,
  searchCostCenter: PropTypes.func.isRequired,
  applicableEntitySectionOptions: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  templateOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.template', []),
  ),
  applicableToOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.applicable', []),
  ),
  ccListOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.ccList', []),
  ),
  recipientOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.recipient', []),
  ),
  applicableCCOperationOptions: getOptionsDefinition(
    get(
      state,
      'entities.notifications.notificationFormData.data.applicableCostCenterOperation',
      [],
    ),
  ),
  costCenterData: get(state, 'entities.qnq.costCenters.data', {}),
  isFetchingCostCenters: get(state, 'entities.qnq.costCenters.isFetching', false) || false,
  applicableEntitySectionOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.applicableEntitySection', []),
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      searchCostCenter: searchCostCentersQNQAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(DetailComponent);
