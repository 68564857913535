import { UPDATE_COST_CENTERS_QNQ_ENDPOINT } from '../../../../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../../../../redux/epics/utils';
import { configureRefreshStateAction } from '../../../../../../../common/redux/configureRefreshState';
import { UPDATE_QNQ_DATA_REFRESH_DELAY_TIME_IN_MS } from '../../../../../../../common/components/table/constants';

export const UPDATE_COST_CENTERS_QNQ_ACTION_TYPES = createAPILifecycleActionTypes(
  'UPDATE_COST_CENTERS_QNQ_ACTION_TYPES',
);
export const updateCostCentersQNQAction = createAPILifecycleActions(
  UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          payload,
        },
        error: null,
      };
    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const updateCostCentersQNQEpic = createAmplifyAPICallEpic({
  actionType: UPDATE_COST_CENTERS_QNQ_ACTION_TYPES.BEGIN,
  api: UPDATE_COST_CENTERS_QNQ_ENDPOINT,
  successCallback: response => [
    updateCostCentersQNQAction.SUCCESS(response),
    configureRefreshStateAction.BEGIN(UPDATE_QNQ_DATA_REFRESH_DELAY_TIME_IN_MS),
  ],
  errorCallback: () => [updateCostCentersQNQAction.FAILURE()],
});
