import { Grid } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import EmployeesOverview from './employees_overview/components/EOverview';
import EmployeeTable from './employees_table/components/EmployeeTable';

const LaborTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
      <div>
        <EmployeesOverview />
      </div>
      <div>
        <EmployeeTable />
      </div>
    </Grid>
  );
};

export default LaborTab;
