import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import ContractTable from './components/ContractTable';

const GroupingContractTable = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <ContractTable />
      </div>
    </Grid>
  );
};

export default GroupingContractTable;
