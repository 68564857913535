export const AGGREGATIONS_IDS = {
  BY_STATE: 'aggByState',
  BY_BUSINESS_UNIT: 'aggByBusinessUnit',
  BY_COST_CENTER: 'aggByCostCenter',
};

export const AGGREGATION_ITEMS = [
  { id: AGGREGATIONS_IDS.BY_STATE, label: 'State', aggSelectorText: 'by state' },
  {
    id: AGGREGATIONS_IDS.BY_BUSINESS_UNIT,
    label: 'Business unit',
    aggSelectorText: 'by business unit',
  },
  {
    id: AGGREGATIONS_IDS.BY_COST_CENTER,
    label: 'Cost center',
    aggSelectorText: 'by cost center',
  },
];
