import PropertyFilter from '@amzn/awsui-components-react/polaris/property-filter';
import { map, reduce, trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { OPERATION_TRANSFORMATION_MAP, OPERATOR_TRANSFORMATION_MAP, OPERATORS } from '../constants';

const I18N_STRINGS = {
  filteringAriaLabel: 'your choice',
  dismissAriaLabel: 'Dismiss',
  filteringPlaceholder: 'Search',
  groupValuesText: 'Values',
  groupPropertiesText: 'Properties',
  operatorsText: 'Operators',
  operationAndText: 'and',
  operationOrText: 'or',
  operatorLessText: 'Less than',
  operatorLessOrEqualText: 'Less than or equal',
  operatorGreaterText: 'Greater than',
  operatorGreaterOrEqualText: 'Greater than or equal',
  operatorContainsText: 'Contains',
  operatorDoesNotContainText: 'Does not contain',
  operatorEqualsText: 'Equals',
  operatorDoesNotEqualText: 'Does not equal',
  editTokenHeader: 'Edit filter',
  propertyText: 'Property',
  operatorText: 'Operator',
  valueText: 'Value',
  cancelActionText: 'Cancel',
  applyActionText: 'Apply',
  allPropertiesLabel: 'All properties',
  tokenLimitShowMore: 'Show more',
  tokenLimitShowFewer: 'Show fewer',
  clearFiltersText: 'Clear filters',
  removeTokenButtonAriaLabel: () => 'Remove token',
  enteredTextLabel: text => `Use: "${text}"`,
};

export const getQueryString = queryObj => {
  const { tokens, operation } = queryObj;
  return trimStart(
    reduce(
      tokens,
      (queryString, { propertyKey, operator, value }) =>
        `${queryString} ${OPERATION_TRANSFORMATION_MAP[operation]}` +
        ` ${propertyKey} ${OPERATOR_TRANSFORMATION_MAP[operator]} ${value}`,
      '',
    ),
    ` ${OPERATION_TRANSFORMATION_MAP[operation]} `,
  );
};

const getFilteringProperties = schema => {
  return map(schema || [], ({ key, displayName }) => {
    return {
      key,
      operators: OPERATORS,
      propertyLabel: displayName,
      groupValuesLabel: `${displayName} values`,
    };
  });
};

const TOKEN_LIMIT = 5;
const IS_FREE_TEXT_FILTERING_DISABLED = true;

const TFilter = props => {
  const { schema, query, setQuery } = props;

  return (
    <PropertyFilter
      onChange={({ detail }) => setQuery(detail)}
      query={query}
      i18nStrings={I18N_STRINGS}
      filteringProperties={getFilteringProperties(schema)}
      tokenLimit={TOKEN_LIMIT}
      disableFreeTextFiltering={IS_FREE_TEXT_FILTERING_DISABLED}
    />
  );
};

TFilter.propTypes = {
  schema: PropTypes.array.isRequired,
  query: PropTypes.object.isRequired,
  setQuery: PropTypes.func.isRequired,
};

export default TFilter;
