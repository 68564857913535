import isEqual from 'lodash/isEqual';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import get from 'lodash/get';

export const getData = ({ aggregation, aggTypes }) => {
  if (!isObject(aggregation) || isEmpty(aggregation) || !isObject(aggTypes) || isEmpty(aggTypes)) {
    return [];
  }

  return map(aggTypes, ({ label, key, color }) => {
    return { title: label, value: get(aggregation, [key], 0), color };
  });
};
export const getDetailPopoverContent = viewType => {
  if (isEqual(viewType, 'number')) {
    return datum => [{ key: 'Number', value: datum.value }];
  }
  if (isEqual(viewType, 'percentage')) {
    return (datum, sum) => [
      {
        key: 'Percentage',
        value: `${((datum.value / sum) * 100).toFixed(0)}%`,
      },
    ];
  }
  return (datum, sum) => [
    { key: 'Number', value: datum.value },
    {
      key: 'Percentage',
      value: `${((datum.value / sum) * 100).toFixed(0)}%`,
    },
  ];
};

export const datumValuePrefix = (viewType, dataValuePrefix, datumValueSuffix) => {
  if (isEqual(viewType, 'number')) {
    return datum =>
      `${dataValuePrefix}${datum.value.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })} ${datumValueSuffix}`;
  }

  return (datum, sum) => `${((datum.value / sum) * 100).toFixed(0)}%`;
};
