import { Header, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import * as React from 'react';
import TPreferences from './TPreferences';

const THeader = props => {
  const {
    count,
    config,
    selectedItems,
    setSelectedItems,
    refreshData,
    schema,
    collectionPreferences,
    setCollectionPreferences,
    query,
  } = props;
  const { title, Actions, description } = config;

  return (
    <Header
      counter={`(${count})`}
      actions={
        <SpaceBetween direction="horizontal" size="xs">
          <Actions
            items={selectedItems}
            setSelectedItems={setSelectedItems}
            refreshData={refreshData}
            query={query}
            config={config}
          />
          <TPreferences
            schema={schema}
            collectionPreferences={collectionPreferences}
            setCollectionPreferences={setCollectionPreferences}
          />
        </SpaceBetween>
      }
      description={description}
    >
      {title}
    </Header>
  );
};

THeader.propTypes = {
  count: PropTypes.number.isRequired,
  config: PropTypes.object.isRequired,
  selectedItems: PropTypes.array.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  refreshData: PropTypes.func.isRequired,
  schema: PropTypes.array.isRequired,
  collectionPreferences: PropTypes.object.isRequired,
  setCollectionPreferences: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
};

export default THeader;
