import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '../../../../common/components/table/components/Table';
import getReportTableConfig from './constants/report_table_config';
import { searchReportsAction } from './redux/reporting';
import REPORT_TYPES, {
  SCHEMA_TYPES,
  SCHEMA_TYPES_FOR_STUDY_PERIOD,
} from '../../constants/report_types';
import { getStudyPeriod } from '../../../../common/constants/study_period';

const ReportTable = props => {
  const [reportGeneratedTill, setReportGeneratedTill] = React.useState('');
  const {
    selectedReportCard,
    schema,
    data,
    isFetchingData,
    getDataBegin,
    clearData,
    reportRefreshStatusData,
    studyPeriod,
  } = props;
  const reportKey = get(selectedReportCard, ['id'], REPORT_TYPES.FINANCE_AND_VP_QUICKSIGHT_REPORT);

  useEffect(() => {
    setReportGeneratedTill('');
  }, [selectedReportCard]);

  const reportTypeProps = {
    schema: get(
      schema,
      get(SCHEMA_TYPES_FOR_STUDY_PERIOD, [studyPeriod, reportKey], SCHEMA_TYPES[reportKey]),
      [],
    ),
    data: get(data, reportKey, {}),
    totalResultCount: get(data, [reportKey, 'totalResultCount'], 0),
    isFetchingData,
    getDataBegin,
    clearData: () => clearData(reportKey),
  };

  const reportGeneratedOn = get(data, [reportKey, 'reportGeneratedOn'], '');

  return (
    <Table
      key={reportKey + reportGeneratedTill}
      {...reportTypeProps}
      config={getReportTableConfig({
        selectedReportCard,
        reportGeneratedTill,
        setReportGeneratedTill,
        reportGeneratedOn,
        reportRefreshStatusData,
        reportData: data,
      })}
    />
  );
};

ReportTable.propTypes = {
  selectedReportCard: PropTypes.object.isRequired,
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  getDataBegin: PropTypes.func.isRequired,
  clearData: PropTypes.func.isRequired,
  reportRefreshStatusData: PropTypes.object,
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  schema: get(state, 'entities.schemas.data.pages.reporting', {}) || {},
  data: get(state, 'entities.reporting.data', {}) || {},
  isFetchingData: get(state, 'entities.reporting.isFetching', false) || false,
  reportRefreshStatusData: get(state, 'entities.getReportRefreshStatus.data', {}),
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDataBegin: searchReportsAction.BEGIN,
      clearData: searchReportsAction.CLEAR,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReportTable);
