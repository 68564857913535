const getOptionsDefinition = options => {
  const updatedOptions = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const option of options) {
    const { label, value } = option;
    updatedOptions.push({ label, value });
  }
  return updatedOptions;
};

export default getOptionsDefinition;
