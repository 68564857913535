import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { VendorProjectMatrixSectionDescription } from './components/VendorProjectMatrixSectionDescription';
import { VendorProjectAllocationMatrix } from './components/VendorProjectAllocationMatrix';

export function VendorProjectMatrixSection({ data }) {
  return (
    <View>
      <Heading name="Project Allocation" />
      <VendorProjectMatrixSectionDescription />
      <VendorProjectAllocationMatrix data={data} />
    </View>
  );
}

VendorProjectMatrixSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VendorProjectMatrixSection;
