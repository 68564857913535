import * as React from 'react';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import { get, map, size } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import { connect } from 'react-redux';
import {
  ITEM_TYPE_CONTRACT,
  ITEM_TYPE_LABOR,
} from '../../../../../../../common/config/api_constants';
import UpdateAllModal from '../../../../common/components/update_all_modal/UpdateAllModal';
import { updateContractGroupingAction } from '../redux/updateContractGrouping';
import { overrideContractGroupingAction } from '../redux/overrideContractGrouping';
import UpdateSelectedModal from '../../../../common/components/update_selected_modal/UpdateSelectedModal';
import { exportDataAction } from '../../../../../../../common/redux/export';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../../../../common/constants/study_period';
import {
  getUploadToS3PayloadForTriggerImport,
  isImportDisabled,
  isImportInProgress,
} from '../../../../../../import_data/utils/import_utils';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  SUPPORTED_IMPORT_TYPES,
} from '../../../../../../import_data/constants/import_types';
import FileImportModal from '../../../../../../../common/components/file_import_modal/FileImportModal';
import { uploadFileToS3Action } from '../../../../../../../common/components/file_importer/redux/actions/UploadFileToS3';

const ContractActions = ({
  updateAllBegin,
  items,
  updateSelectedBegin,
  groupingId,
  lastChangedOn,
  setSelectedItems,
  exportDataBegin,
  query,
  studyPeriod,
  uploadFileToS3,
  config,
}) => {
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);
  const [isUpdateAllModalVisible, setUpdateAllModalVisibility] = React.useState(false);
  const [isUpdateSelectedModalVisible, setUpdateSelectedModalVisibility] = React.useState(false);

  const showFileImportModal = () => {
    setFileImportModalVisibility(true);
  };

  const onCancelUpdateAllModal = () => {
    // state contains the file details
    setUpdateAllModalVisibility(false);
  };

  const onCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    // state will contain file details in props
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType: SUPPORTED_IMPORT_TYPES.CONTRACT_GROUPING[0],
      }),
    );
  };

  const showUpdateAllModal = () => {
    setUpdateAllModalVisibility(true);
  };

  const onConfirmUpdateAllModal = async () => {
    // state contains the file details
    updateAllBegin({
      body: {
        itemType: ITEM_TYPE_CONTRACT,
      },
    });
    setUpdateAllModalVisibility(false);
  };

  const showUpdateSelectedModal = () => {
    setUpdateSelectedModalVisibility(true);
  };
  const onCancelUpdateSelectedModal = () => {
    // state contains the file details
    setSelectedItems([]);
    setUpdateSelectedModalVisibility(false);
  };
  const filterSelectedItems = updates => {
    return map(items, record => {
      return {
        subordinateRecordId: get(record, 'partitionKey', null),
        updatedOn: get(record, 'updatedOn', null),
        oldQualificationStatus: get(record, 'contractResearchQNQ', null),
        newQualificationStatus: get(updates, 'selectedQualification', null),
        oldSmeRecordId: get(record, 'smeRecordId', null),
        newSmeRecordId: get(updates, 'selectedSME.recordId', null),
        reason: get(updates, 'selectedReason.label', null),
        surveyId: get(updates, ['selectedSMESurvey', 'surveyId'], null),
        operationSource: 'UI',
      };
    });
  };

  const onConfirmUpdateSelectedModal = updates => {
    updateSelectedBegin({
      body: {
        itemType: ITEM_TYPE_CONTRACT,
        groupingId,
        lastChangedOn,
        subordinates: filterSelectedItems(updates),
      },
    });
    setSelectedItems([]);
    setUpdateSelectedModalVisibility(false);
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'GROUPING_CONTRACT_RESEARCH_EXTRACT',
        isJSON: false,
        query: {
          itemType: ITEM_TYPE_LABOR,
          sortKey: groupingId,
          query,
        },
      },
    });
  };

  const showUpdateSelected = () => {
    const contractTypes = new Set();
    items.map(({ contractResearchQNQ }) => contractTypes.add(contractResearchQNQ));
    if (size(contractTypes) === 1) return false;
    return true;
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={onExportHandler}>Export</Button>
      <Button
        onClick={showFileImportModal}
        disabled={
          isActionDisabledForStudyPeriod(studyPeriod) ||
          isImportInProgress(config) ||
          isImportDisabled(config)
        }
      >
        Import
      </Button>
      <Button
        onClick={showUpdateSelectedModal}
        disabled={
          showUpdateSelected() ||
          isActionDisabledForStudyPeriod(studyPeriod) ||
          isImportInProgress(config)
        }
      >
        Update selected
      </Button>
      <Button
        onClick={showUpdateAllModal}
        disabled={isActionDisabledForStudyPeriod(studyPeriod) || isImportInProgress(config)}
      >
        Update all
      </Button>

      <UpdateAllModal
        isVisible={isUpdateAllModalVisible}
        onDismiss={onCancelUpdateAllModal}
        onConfirm={onConfirmUpdateAllModal}
        onCancel={onCancelUpdateAllModal}
      />
      <UpdateSelectedModal
        itemType={ITEM_TYPE_CONTRACT}
        entityType="Cost Center"
        selectedEmployees={size(items)}
        isVisible={isUpdateSelectedModalVisible}
        onDismiss={onCancelUpdateSelectedModal}
        onConfirm={onConfirmUpdateSelectedModal}
        onCancel={onCancelUpdateSelectedModal}
      />
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={onCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={onCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};

ContractActions.propTypes = {
  updateAllBegin: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  updateSelectedBegin: PropTypes.func.isRequired,
  groupingId: PropTypes.string.isRequired,
  lastChangedOn: PropTypes.string.isRequired,
  setSelectedItems: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    groupingId: get(state, 'entities.grouping.contract.data.groupingId', null) || null,
    lastChangedOn: get(state, 'entities.grouping.contract.data.lastChangedOn', null) || null,
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateAllBegin: updateContractGroupingAction.BEGIN,
      updateSelectedBegin: overrideContractGroupingAction.BEGIN,
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ContractActions);
