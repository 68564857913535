import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get } from 'lodash';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
  },
  name: {
    paddingTop: 10,
  },
  description: {
    paddingTop: 3,
    paddingLeft: 10,
  },
});

export function ActivityList({ data }) {
  const { section, name, description } = styles;

  const defaultActivityList = get(data, 'userResponse.Activity.data.defaultItems', []);
  const selectedActivityList = get(data, 'userResponse.Activity.data.selectedActivities', []);
  const totalActivitiesList = [...defaultActivityList, ...selectedActivityList];
  const activityMapping = {};
  get(data, 'activityList', []).forEach(activity => {
    const projectId = get(activity, 'activityId', '');
    activityMapping[projectId] = get(activity, 'activityDescription', '');
  });

  const activities = totalActivitiesList.map((activity, index) => {
    return (
      <View>
        <Text style={name}>
          {index + 1}. {activity.name}
        </Text>
        <Text style={description}>{activityMapping[activity.id]}</Text>
      </View>
    );
  });

  return <View style={section}>{activities}</View>;
}

ActivityList.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ActivityList;
