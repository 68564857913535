import { SEARCH_SURVEYS_ENDPOINT } from '../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { extractTableDataFromPayload } from '../../../common/components/table/utils/table_redux_payload_utils';

const SEARCH_SURVEYS_ACTION_TYPES = createAPILifecycleActionTypes('SEARCH_SURVEYS_ACTION_TYPES');
export const searchSurveysAction = createAPILifecycleActions(SEARCH_SURVEYS_ACTION_TYPES.NAME);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_SURVEYS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_SURVEYS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...extractTableDataFromPayload(payload),
        },
        error: null,
      };
    case SEARCH_SURVEYS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case SEARCH_SURVEYS_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const searchSurveysEpic = createAmplifyAPICallEpic({
  actionType: SEARCH_SURVEYS_ACTION_TYPES.BEGIN,
  api: SEARCH_SURVEYS_ENDPOINT,
  successCallback: response => [searchSurveysAction.SUCCESS(response.body)],
  errorCallback: () => [searchSurveysAction.FAILURE()],
});
