import { set } from 'lodash';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../../redux/actions/action_utils';
import { DELETE_NOTIFICATION } from '../../../../../../common/config/api_endpoints';
import createAmplifyAPICallEpic from '../../../../../../redux/epics/utils';

export const DELETE_NOTIFICATION_ACTION_TYPES = createAPILifecycleActionTypes(
  'DELETE_NOTIFICATION_ACTION_TYPES',
);
export const deleteNotificationAction = createAPILifecycleActions(
  DELETE_NOTIFICATION_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case DELETE_NOTIFICATION_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case DELETE_NOTIFICATION_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case DELETE_NOTIFICATION_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case DELETE_NOTIFICATION_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const payloadGenerator = async ({ originalPayload }) => {
  const newPayload = {};
  set(newPayload, ['body'], originalPayload.body);
  return newPayload;
};

const successCallback = (response, store, action) => {
  const originalPayload = action.payload;
  const { onSuccess } = originalPayload;
  if (response.status === 200 && response.isDeleted) {
    onSuccess();
  }
  return [deleteNotificationAction.SUCCESS(response)];
};

export const deleteNotificationEpic = createAmplifyAPICallEpic({
  actionType: DELETE_NOTIFICATION_ACTION_TYPES.BEGIN,
  payloadGenerator,
  api: DELETE_NOTIFICATION,
  successCallback,
  errorCallback: () => [deleteNotificationAction.FAILURE()],
});
