import { isUndefined, isNull, isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import transformDate from '../../app/survey/constants/utils';

export const convertISOToDateTime = str => {
  return new Date(str);
};
export const getDate = str => {
  if (isNull(str) || isUndefined(str)) return null;
  return str.split('T')[0];
};

export const getDateInPST = str => {
  return isNull(str) || isUndefined(str)
    ? null
    : convertISOToDateTime(str).toLocaleDateString('en-US', { timeZone: 'America/Los_Angeles' });
};
const getTimeInPST = str => {
  return isNull(str) || isUndefined(str)
    ? null
    : convertISOToDateTime(str).toLocaleTimeString('en-US', { timeZone: 'America/Los_Angeles' });
};
export const getDateTime = str => {
  return isNull(str) || isUndefined(str)
    ? null
    : convertISOToDateTime(str).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
};

export const getDateInMMDDYYYYHHMIFormat = str => {
  if (isEmpty(str) || isNull(str) || isUndefined(str)) return '-';
  return `${getDateInPST(str)} ${getTimeInPST(str)} PT`;
};

/**
 * Function to convert dateTime to date in MM/dd/yyyy
 * TODO - Remove date conversion from frontend and convert date from backend itself
 *        as this may cause out of sync formats between UI and exports.
 *        Also, add proper date handling by using inbuilt functionality.
 * @param str
 * @returns {string|null}
 */
export const getDateInMMDDYYYYWithoutBrowserTimezoneEffect = str => {
  if (isNull(str) || isUndefined(str)) return null;
  const isoDate = str.split('T')[0];
  const splitDate = isoDate.split('-');
  return `${splitDate[1]}/${splitDate[2]}/${splitDate[0]}`;
};

/**
 * Function to convert date to browser's timeZone date
 */
export const getDateInMMDDYYYYWithBrowserTimezoneEffect = str => {
  if (isNull(str) || isUndefined(str)) return null;
  return new Date(str).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

/**
 * Function to convert date to browser's timeZone dateTime
 */
export const getDateInMMDDYYYYHHMMSSWithBrowserTimezoneEffect = str => {
  if (isNull(str) || isUndefined(str)) return null;
  return new Date(str).toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
};

/**
 * Function to convert timeZone of datetime from input timezone to output timezone
 * @param inputDateTime dateTime value as yyyy-MM-ddThh:mm:ss
 * @param inputTimeZone input timeZone
 * @param outputTimeZone output timeZone
 * @returns {DateTime} Corresponding dateTime value in output timeZone
 */
export const convertTimeZone = (inputDateTime, inputTimeZone, outputTimeZone) => {
  const inputDate = DateTime.fromISO(inputDateTime, { zone: inputTimeZone });
  return inputDate.setZone(outputTimeZone);
};

/**
 * Function to find epoch in millis for US date
 * @param date Date in US timezone in yyyy-MM-dd format
 * @param type Whether release date or due date based on which time value is decided
 * @returns {string} Corresponding epoch millis
 */
export const changeDateToEpoch = (date, type) => {
  const time = type === 'releaseDate' ? '00:00:00' : '23:59:59';
  return Date.parse(
    convertTimeZone(`${date}T${time}`, 'America/Los_Angeles', 'UTC').toString(),
  ).toString();
};

/**
 * Function to find current date in US timezone
 * @returns {string|*} Current date in US timezone in yyyy-MM-dd format
 */
export const getTodayDateInUSTimeZone = () => {
  const currentDateTime = new Date().toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
    timeZone: 'America/Los_Angeles',
  });
  return transformDate(currentDateTime);
};
