import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducers';
import entities from './entities';
import appMetaData from '../../common/components/with_page/redux/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    appMetaData,
    entities,
  });
