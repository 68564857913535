import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import JobCodesTable from './job_codes_table/components/JobCodesTable';

const JobCodesTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <JobCodesTable />
      </div>
    </Grid>
  );
};

export default JobCodesTab;
