import { Box, Header, Tabs } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Page from '../../common/components/with_page/Page';
import { RESOURCE } from '../auth/constants/rbac_constants';
import SurveysTab from './components/surveys_tab/SurveysTab';
import QRETab from './components/qre_tab/QRETab';
import { getStudyPeriod } from '../../common/constants/study_period';

const StudySummaryPage = ({ studyPeriod }) => {
  const content = (
    <div>
      <Header variant="h1">Study Period {studyPeriod}</Header>
      <Box>
        <Tabs
          tabs={[
            {
              label: 'QRE',
              id: 'first',
              content: <QRETab />,
            },
            {
              label: 'Surveys',
              id: 'second',
              content: <SurveysTab />,
            },
          ]}
        />
      </Box>
    </div>
  );

  return <Page PageId={RESOURCE.PAGE_STUDY_SUMMARY} PageContent={content} />;
};

StudySummaryPage.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(StudySummaryPage);
