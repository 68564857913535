import { Box, Button, SpaceBetween, Header, Link } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { useState } from 'react';
import { get, isUndefined } from 'lodash';
import Table from '@amzn/awsui-components-react/polaris/table';
import { bindActionCreators } from 'redux';
import { getColumnDefinitions } from '../../../../common/components/table/utils';
import { uploadFileToS3Action } from '../../../../common/components/file_importer/redux/actions/UploadFileToS3';
import { configureAttachmentStateAction } from '../redux/configureAttachmentState';
import AttachmentModal from './attachment-modal';

const AttachmentComponent = ({
  attachments,
  uploadFileToS3,
  deleteAttachment,
  attachmentTableSchema,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const fileInitialState = {
    form: {
      file: null,
    },
  };
  const [fileState, setFileState] = useState(fileInitialState);
  const onCancelHandler = () => {
    setIsModalVisible(false);
    setFileState(fileInitialState);
  };
  const addDocumentHandler = () => {
    setIsModalVisible(true);
  };
  const onConfirmHandler = async () => {
    uploadFileToS3({
      documentFile: fileState.form.file,
      presignedURLPayload: {
        useCase: 'Attachment',
      },
      onUploadSuccess: {
        action: configureAttachmentStateAction.ADD,
        payload: {
          body: {
            label: fileState.form.file.name,
            size: fileState.form.file.size.toString(),
            active: true,
          },
        },
        documentIdentifier: 'id',
      },
      onUploadFailure: {
        action: configureAttachmentStateAction.FAILURE,
      },
    });
    setIsModalVisible(false);
    setFileState(fileInitialState);
  };
  const getEmptyView = () => {
    return (
      <Box textAlign="center">
        <SpaceBetween size="l">
          <div> No attachment is added</div>
          <Button onClick={addDocumentHandler}>Add attachment</Button>
        </SpaceBetween>
      </Box>
    );
  };
  const deleteAttachmentHandler = fileId => {
    deleteAttachment(fileId);
  };
  const getAttachmentData = () => {
    const activeAttachment = attachments.filter(({ active }) => {
      return active;
    });
    return activeAttachment.map(({ label, size, id, presignedUrl }) => {
      return {
        fileName: isUndefined(presignedUrl) ? (
          label
        ) : (
          <Link href={presignedUrl} target="_blank">
            {label}
          </Link>
        ),
        size: `${(Number(size) / 1000).toFixed(0)}KB`,
        delete: (
          <Link onFollow={() => deleteAttachmentHandler(id)}>
            <div style={{ color: 'red' }}>Delete</div>
          </Link>
        ),
      };
    });
  };
  return (
    <div>
      <Table
        columnDefinitions={getColumnDefinitions(attachmentTableSchema)}
        header={
          <Header actions={<Button onClick={addDocumentHandler}>Add</Button>}>Attachment</Header>
        }
        trackBy="AttachmentId"
        items={getAttachmentData()}
        empty={getEmptyView()}
      />
      <AttachmentModal
        setFileState={setFileState}
        fileState={fileState}
        onCancelHandler={onCancelHandler}
        onConfirmHandler={onConfirmHandler}
        isModalVisible={isModalVisible}
      />
    </div>
  );
};

AttachmentComponent.propTypes = {
  attachments: PropTypes.array.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
  deleteAttachment: PropTypes.func.isRequired,
  attachmentTableSchema: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  attachments: get(state, 'entities.notifications.configureAttachmentState.attachments', []),
  attachmentTableSchema: get(
    state,
    'entities.notifications.notificationFormData.data.attachmentTable',
    {},
  ),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      uploadFileToS3: uploadFileToS3Action,
      deleteAttachment: configureAttachmentStateAction.DELETE,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(AttachmentComponent);
