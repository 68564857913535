import {
  DEFAULT_BUSINESS_ID,
  DEFAULT_COUNTRY,
  ITEM_TYPE_LABOR,
} from '../../../../../../../common/config/api_constants';

export const requestType = {
  hr: 'MISSING_HR_INFORMATION_REPORT',
  payroll: 'MISSING_PAYROLL_INFORMATION_REPORT',
};

export const exceptionsRequest = studyPeriod => {
  return {
    isJSON: false,
    query: {
      studyPeriod,
      regulatoryRegion: DEFAULT_COUNTRY,
      itemType: ITEM_TYPE_LABOR,
      clientId: DEFAULT_BUSINESS_ID,
      query: null,
    },
  };
};
