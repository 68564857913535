import { TextFilter } from '@amzn/awsui-components-react';
import * as React from 'react';
import { filter, lowerCase, startsWith, get } from 'lodash';
import PropTypes from 'prop-types';

const TextFiltering = ({ setVisibleItems, filterOn, data, filteringPlaceholder }) => {
  const [filteringText, setFilteringText] = React.useState('');

  const handleFiltering = ({ detail }) => {
    setFilteringText(detail.filteringText);
    setVisibleItems(
      filter(data, ob =>
        startsWith(lowerCase(get(ob, [filterOn], '')), lowerCase(detail.filteringText)),
      ),
    );
  };

  return (
    <TextFilter
      filteringText={filteringText}
      onChange={handleFiltering}
      filteringPlaceholder={filteringPlaceholder}
    />
  );
};
TextFiltering.propTypes = {
  setVisibleItems: PropTypes.func.isRequired,
  filterOn: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  filteringPlaceholder: PropTypes.string.isRequired,
};

export default TextFiltering;
