import { ColumnLayout, Container, Header } from '@amzn/awsui-components-react';
import React from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import ValueWithLabel from '../../../../../common/components/value_with_label/ValueWithLabel';
import { getNotificationStatus } from '../../common/util/utils';
import { SURVEY_NAMES } from '../../common/constants/constants';

const NotificationsOverview = ({ details }) => {
  const formattedTimeline = get(details, 'formattedTimeline', '');
  const triggeringEvent = get(details, 'triggeringEvent', '');
  return (
    <Container header={<Header variant="h2">Summary</Header>}>
      <ColumnLayout columns={4} variant="text-grid">
        <ValueWithLabel label="Frequency">{formattedTimeline.split('-')[0]}</ValueWithLabel>
        <ValueWithLabel label={triggeringEvent === 'EVENT' ? 'Triggering event' : 'Delivery date'}>
          {formattedTimeline
            .split('-')
            .slice(1)
            .join('-')}
        </ValueWithLabel>
        <ValueWithLabel label="Survey">
          {SURVEY_NAMES[get(details, 'applicableTo', '')]}
        </ValueWithLabel>
        <ValueWithLabel label="Status">{getNotificationStatus(details)}</ValueWithLabel>
      </ColumnLayout>
    </Container>
  );
};
NotificationsOverview.propTypes = {
  details: PropTypes.object.isRequired,
};
export default NotificationsOverview;
