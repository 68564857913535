import { Box, Grid, Toggle } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React from 'react';

const ToggleInput = ({ id, label, checked, setChecked, disabled }) => {
  return (
    <Box padding="xs">
      <Grid gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}>
        <div>{label}</div>
        <div>
          <Toggle
            onChange={({ detail }) => setChecked(id, detail.checked)}
            checked={checked}
            disabled={disabled}
          />
        </div>
      </Grid>
    </Box>
  );
};

ToggleInput.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

ToggleInput.defaultProps = {
  disabled: false,
};

export default ToggleInput;
