import { API } from 'aws-amplify';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootEpic from '../epics/index';
import rootReducer from '../reducers';

export const history = createBrowserHistory();

const epicMiddleware = createEpicMiddleware({ dependencies: { API } });
const enhancer = compose(applyMiddleware(epicMiddleware, routerMiddleware(history)));
const persistConfig = {
  key: 'root',
  whitelist: [],
  storage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export default function configureStore(initialState) {
  const store = createStore(persistedReducer, initialState, enhancer);
  const persistor = persistStore(store);
  epicMiddleware.run(rootEpic);

  return { store, persistor };
}
