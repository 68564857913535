import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../common/components/table/constants';
import { ITEM_TYPE_LABOR } from '../../../../../../../common/config/api_constants';
import { getImportStatusHeaderDescription } from '../../../../../../import_data/utils/import_utils';
import EmployeeLaborActions from '../components/EmployeeLaborActions';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../../import_data/constants/import_types';

const EmptyTableView = () => <p>No employees.</p>;

export default ({ importTypeMetadata }) => {
  return {
    dataTrackingId: 'employeeId',
    dataLoadingText: 'Loading employees',
    emptyElement: EmptyTableView, // element
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Employees',
      description: getImportStatusHeaderDescription(importTypeMetadata),
      Actions: EmployeeLaborActions,
      importTypeMetadata,
    },
    // TODO : Pass the parameters when app evolves
    getDataRequestBody: () => {
      return {
        itemType: ITEM_TYPE_LABOR,
      };
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes: SUPPORTED_IMPORT_TYPES.POPULATION_LABOR,
        },
      };
    },
  };
};
