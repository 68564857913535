import Box from '@amzn/awsui-components-react/polaris/box';
import { Alert, Grid, RadioGroup, TextContent } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import { SEARCH_BY_VALUES } from '../../../constants/update_selected_constants';
import ChooseSME from './components/ChooseSME';
import ChooseSurvey from './components/ChooseSurvey';
import {
  getPopulationForQOverride,
  getReasonsForNQOverride,
  getReasonsForOverride,
} from '../utils/modal-utils';

const ModalContent = ({
  itemType,
  setSearchBy,
  getSMEs,
  getStatus,
  handleLoadItems,
  setSMEOption,
  getSMESurveys,
  isFetchingSurveys,
  setSelectedSMESurvey,
  Q_OVERRIDE_REASONS,
  NQ_OVERRIDE_REASONS,
  setSelectedReasonForQ,
  setSelectedReasonForNQ,
  getEntityTypeText,
  selectedQualification,
  searchBy,
  selectedSME,
  selectedSMESurvey,
  selectedReasonForQ,
  selectedReasonForNQ,
  areSurveysConfigured,
  POPULATION,
  setSelectedPopulation,
  selectedPopulation,
}) => {
  switch (selectedQualification) {
    case 'Q': {
      return (
        <div>
          <Box>
            <TextContent>
              <p>Choose SME By</p>
            </TextContent>
            <RadioGroup
              onChange={({ detail }) => setSearchBy(detail.value)}
              value={searchBy}
              items={SEARCH_BY_VALUES}
            />
          </Box>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            <ChooseSME
              getSMEs={getSMEs}
              selectedSME={selectedSME}
              getStatus={getStatus}
              handleLoadItems={handleLoadItems}
              setSMEOption={setSMEOption}
            />
            <ChooseSurvey
              getSMESurveys={getSMESurveys}
              selectedSMESurvey={selectedSMESurvey}
              isFetchingSurveys={isFetchingSurveys}
              setSelectedSMESurvey={setSelectedSMESurvey}
              areSurveysConfigured={areSurveysConfigured}
            />
          </Grid>
          <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            {getPopulationForQOverride(
              itemType,
              POPULATION,
              selectedPopulation,
              setSelectedPopulation,
            )}
            {getReasonsForOverride(
              itemType,
              Q_OVERRIDE_REASONS,
              setSelectedReasonForQ,
              selectedReasonForQ,
            )}
          </Grid>
        </div>
      );
    }
    case 'NQ': {
      return (
        <div>
          {getReasonsForNQOverride(
            itemType,
            NQ_OVERRIDE_REASONS,
            selectedReasonForNQ,
            setSelectedReasonForNQ,
          )}
          <br />
          <Alert type="warning">SME of the {getEntityTypeText()} will be emptied.</Alert>
        </div>
      );
    }
    default:
      return <div />;
  }
};

ModalContent.propTypes = {
  itemType: PropTypes.string.isRequired,
  setSearchBy: PropTypes.func.isRequired,
  getSMEs: PropTypes.func.isRequired,
  getStatus: PropTypes.func.isRequired,
  handleLoadItems: PropTypes.func.isRequired,
  setSMEOption: PropTypes.func.isRequired,
  getSMESurveys: PropTypes.func.isRequired,
  isFetchingSurveys: PropTypes.bool.isRequired,
  setSelectedSMESurvey: PropTypes.func.isRequired,
  Q_OVERRIDE_REASONS: PropTypes.array.isRequired,
  NQ_OVERRIDE_REASONS: PropTypes.array.isRequired,
  setSelectedReasonForQ: PropTypes.func.isRequired,
  setSelectedReasonForNQ: PropTypes.func.isRequired,
  getEntityTypeText: PropTypes.func.isRequired,
  selectedQualification: PropTypes.string.isRequired,
  searchBy: PropTypes.string.isRequired,
  selectedSME: PropTypes.object.isRequired,
  selectedReasonForQ: PropTypes.object,
  selectedReasonForNQ: PropTypes.object,
  selectedSMESurvey: PropTypes.object,
  areSurveysConfigured: PropTypes.bool,
  POPULATION: PropTypes.array.isRequired,
  setSelectedPopulation: PropTypes.func.isRequired,
  selectedPopulation: PropTypes.object,
};

ModalContent.defaultProps = {
  selectedReasonForQ: null,
  selectedReasonForNQ: null,
  selectedSMESurvey: null,
  areSurveysConfigured: false,
  selectedPopulation: null,
};

export default ModalContent;
