import * as React from 'react';
import { DEFAULT_TABLE_PAGE_SIZE } from '../../../../../../../common/components/table/constants';
import { ITEM_TYPE_CONTRACT } from '../../../../../../../common/config/api_constants';
import { getImportStatusHeaderDescription } from '../../../../../../import_data/utils/import_utils';
import ContractVendorActions from '../components/ContractVendorActions';
import { SUPPORTED_IMPORT_TYPES } from '../../../../../../import_data/constants/import_types';

const EmptyTableView = () => <p>No vendors.</p>;

export default ({ importTypeMetadata }) => {
  return {
    dataTrackingId: 'recordId',
    dataLoadingText: 'Loading vendors',
    emptyElement: EmptyTableView, // element
    pageSize: DEFAULT_TABLE_PAGE_SIZE,
    multiSelectConfig: { selectionType: 'multi' },
    headerConfig: {
      title: 'Vendors',
      description: getImportStatusHeaderDescription(importTypeMetadata),
      Actions: ContractVendorActions,
      importTypeMetadata,
    },
    // TODO : Pass the parameters when app evolves
    getDataRequestBody: () => {
      return {
        itemType: ITEM_TYPE_CONTRACT,
      };
    },
    getImportDataRequestBody: () => {
      return {
        body: {
          importTypes: SUPPORTED_IMPORT_TYPES.POPULATION_CONTRACTS,
        },
      };
    },
  };
};
