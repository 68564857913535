import React from 'react';
import { get } from 'lodash';
import { Icon, Link } from '@amzn/awsui-components-react';
import {
  CANCELLED_X_DELIVERED_REGEX,
  NOTIFICATION_STATUS_TYPES,
  X_PERCENT_DELIVERED_REGEX,
} from '../constants/constants';

export const getNotificationStatusType = status => {
  if (status.toString().match(X_PERCENT_DELIVERED_REGEX)) {
    return NOTIFICATION_STATUS_TYPES.X_PERCENT_DELIVERED;
  }
  if (status.toString().match(CANCELLED_X_DELIVERED_REGEX)) {
    return NOTIFICATION_STATUS_TYPES.CANCELLED_X_DELIVERED;
  }
  return NOTIFICATION_STATUS_TYPES[status];
};

export const getNotificationStatus = item => {
  const status = get(item, 'status', 'UNKNOWN');
  const statusType = getNotificationStatusType(status);
  switch (statusType) {
    case NOTIFICATION_STATUS_TYPES.READY:
      return (
        <div>
          <Icon name="status-positive" variant="link" />{' '}
          <Link className="ready-status" href="#">
            {NOTIFICATION_STATUS_TYPES.READY}
          </Link>
        </div>
      );
    case NOTIFICATION_STATUS_TYPES.IN_PROGRESS:
      return (
        <div>
          <Icon name="status-pending" variant="link" />{' '}
          <Link className="ready-status" href="#">
            {NOTIFICATION_STATUS_TYPES.IN_PROGRESS}
          </Link>
        </div>
      );
    case NOTIFICATION_STATUS_TYPES.Cancelled:
    case NOTIFICATION_STATUS_TYPES.CANCELLED_X_DELIVERED:
      return (
        <div>
          <Icon name="status-negative" variant="warning" />{' '}
          <span style={{ color: 'red' }}>{item.status}</span>
        </div>
      );
    case NOTIFICATION_STATUS_TYPES.IN_EDIT:
    case NOTIFICATION_STATUS_TYPES.CANCELLATION_IN_PROGRESS:
      return (
        <div>
          <Icon name="status-in-progress" variant="subtle" />{' '}
          <span style={{ color: 'grey' }}>{statusType}</span>
        </div>
      );
    case NOTIFICATION_STATUS_TYPES.X_PERCENT_DELIVERED:
      return (
        <div>
          <Icon name="status-positive" variant="success" />{' '}
          <span style={{ color: 'green' }}>{item.status}</span>
        </div>
      );
    default:
      return '';
  }
};
