import { combineReducers } from 'redux';
import { combineEpics } from 'redux-observable';
import populationOverview, {
  getLaborPopulationOverviewEpic,
} from '../components/employees_overview/redux/populationOverview';
import population, {
  searchLaborPopulationEpic,
} from '../components/employees_table/redux/population';

export const laborEpics = combineEpics(getLaborPopulationOverviewEpic, searchLaborPopulationEpic);
export const laborReducers = combineReducers({
  populationOverview,
  population,
});
