import { Box } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React from 'react';

const TEmptyView = props => {
  const { EmptyElement } = props;

  return (
    <Box textAlign="center" color="inherit">
      <EmptyElement />
    </Box>
  );
};

TEmptyView.propTypes = {
  EmptyElement: PropTypes.func.isRequired,
};

export default TEmptyView;
