import {
  Checkbox,
  Container,
  FormField,
  Header,
  Select,
  SpaceBetween,
  ColumnLayout,
  Input,
  DatePicker,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React from 'react';
import { get } from 'lodash';
import {
  isEventSchedule,
  isOneTimeSchedule,
  isOnReferenceDateNotSelected,
  isRecurringCustomDateSelected,
  isRecurringSchedule,
  ONE_TIME_INITIAL_STATE,
  RECURRING_INITIAL_STATE,
  TEMPORAL_DATA_INITIAL_STATE,
  TRIGGERING_EVENT_INITIAL_STATE,
} from '../constants/delivery-schedule-constant';
import getOptionsDefinition from './utils/util';

const DeliveryScheduleComponent = ({
  deliverySchedule,
  setDeliverySchedule,
  frequencyOptions,
  triggerOptions,
  triggeringEventOptions,
  relativeOperatorOptions,
  relativeDateOptions,
  temporalUnitOptions,
}) => {
  return (
    <Container header={<Header variant="h2">Delivery schedule</Header>}>
      <SpaceBetween size="l">
        <FormField label="Frequency">
          <Select
            options={frequencyOptions}
            selectedOption={deliverySchedule.frequency}
            onChange={({ detail }) => {
              if (deliverySchedule.frequency.value !== detail.selectedOption.value) {
                setDeliverySchedule(previousSchedule => ({
                  ...previousSchedule,
                  ...TRIGGERING_EVENT_INITIAL_STATE,
                  ...ONE_TIME_INITIAL_STATE,
                  ...RECURRING_INITIAL_STATE,
                  frequency: detail.selectedOption,
                }));
              }
            }}
          />
        </FormField>
        {!isRecurringSchedule(deliverySchedule) && (
          <FormField label="Trigger">
            <Select
              options={triggerOptions}
              selectedOption={deliverySchedule.trigger}
              onChange={({ detail }) => {
                if (deliverySchedule.trigger.value !== detail.selectedOption.value) {
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    ...TRIGGERING_EVENT_INITIAL_STATE,
                    ...ONE_TIME_INITIAL_STATE,
                    ...RECURRING_INITIAL_STATE,
                    trigger: detail.selectedOption,
                  }));
                }
              }}
            />
          </FormField>
        )}

        {isEventSchedule(deliverySchedule) && (
          <FormField label="Triggering event">
            <Select
              options={triggeringEventOptions}
              selectedOption={deliverySchedule.triggeringEvent}
              onChange={({ detail }) =>
                setDeliverySchedule(previousSchedule => ({
                  ...previousSchedule,
                  triggeringEvent: detail.selectedOption,
                }))
              }
            />
          </FormField>
        )}
        {isOneTimeSchedule(deliverySchedule) && (
          <FormField label="When to deliver the notification?">
            <ColumnLayout columns={2}>
              <Select
                options={relativeOperatorOptions}
                selectedOption={deliverySchedule.relativeOperator}
                onChange={({ detail }) => {
                  if (deliverySchedule.relativeOperator.value !== detail.selectedOption.value) {
                    setDeliverySchedule(previousSchedule => ({
                      ...previousSchedule,
                      relativeOperator: detail.selectedOption,
                      ...TEMPORAL_DATA_INITIAL_STATE,
                    }));
                  }
                }}
              />
              <Select
                options={[
                  { label: 'release date', value: 'RELEASE_DATE' },
                  { label: 'due date', value: 'DUE_DATE' },
                ]}
                selectedOption={deliverySchedule.relativeDate}
                onChange={({ detail }) => {
                  if (deliverySchedule.relativeDate.value !== detail.selectedOption.value) {
                    setDeliverySchedule(previousSchedule => ({
                      ...previousSchedule,
                      relativeDate: detail.selectedOption,
                      ...TEMPORAL_DATA_INITIAL_STATE,
                    }));
                  }
                }}
              />
            </ColumnLayout>
          </FormField>
        )}
        {isOnReferenceDateNotSelected(deliverySchedule) && (
          <FormField
            label={`How long ${deliverySchedule.relativeOperator.label.toLowerCase()} 
                the ${deliverySchedule.relativeDate.label.toLowerCase()}?`}
          >
            <ColumnLayout columns={2}>
              <Input
                value={deliverySchedule.temporalData}
                onChange={event =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    temporalData: event.detail.value,
                  }))
                }
              />
              <Select
                options={temporalUnitOptions}
                selectedOption={deliverySchedule.temporalUnit}
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    temporalUnit: detail.selectedOption,
                  }))
                }
              />
            </ColumnLayout>
          </FormField>
        )}
        {isRecurringSchedule(deliverySchedule) && (
          <FormField label="Start">
            <Select
              options={relativeDateOptions}
              selectedOption={deliverySchedule.relativeStartDate}
              onChange={({ detail }) =>
                setDeliverySchedule(previousSchedule => ({
                  ...previousSchedule,
                  relativeStartDate: detail.selectedOption,
                }))
              }
            />
          </FormField>
        )}
        {isRecurringCustomDateSelected(
          deliverySchedule,
          deliverySchedule.relativeStartDate.value,
        ) && (
          <FormField label="Start date">
            <DatePicker
              onChange={({ detail }) =>
                setDeliverySchedule(previousSchedule => ({
                  ...previousSchedule,
                  relativeStartDateValue: detail.value,
                }))
              }
              value={deliverySchedule.relativeStartDateValue}
              openCalendarAriaLabel={selectedDate =>
                `Choose certificate expiry date${
                  selectedDate ? `, selected date is ${selectedDate}` : ''
                }`
              }
              nextMonthAriaLabel="Next month"
              placeholder="YYYY/MM/DD"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
              stretch
            />
          </FormField>
        )}
        {isRecurringSchedule(deliverySchedule) && (
          <FormField label="End">
            <Select
              options={relativeDateOptions}
              selectedOption={deliverySchedule.relativeEndDate}
              onChange={({ detail }) =>
                setDeliverySchedule(previousSchedule => ({
                  ...previousSchedule,
                  relativeEndDate: detail.selectedOption,
                }))
              }
            />
          </FormField>
        )}
        {isRecurringCustomDateSelected(
          deliverySchedule,
          deliverySchedule.relativeEndDate.value,
        ) && (
          <FormField label="End date">
            <DatePicker
              onChange={({ detail }) =>
                setDeliverySchedule(previousSchedule => ({
                  ...previousSchedule,
                  relativeEndDateValue: detail.value,
                }))
              }
              value={deliverySchedule.relativeEndDateValue}
              openCalendarAriaLabel={selectedDate =>
                `Choose certificate expiry date${
                  selectedDate ? `, selected date is ${selectedDate}` : ''
                }`
              }
              nextMonthAriaLabel="Next month"
              placeholder="YYYY/MM/DD"
              previousMonthAriaLabel="Previous month"
              todayAriaLabel="Today"
              stretch
            />
          </FormField>
        )}

        {isRecurringSchedule(deliverySchedule) && (
          <FormField label="Days of delivery">
            <ColumnLayout columns={2}>
              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...previousSchedule.daysOfWeek, SUNDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.SUNDAY}
              >
                Sunday
              </Checkbox>

              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...deliverySchedule.daysOfWeek, THURSDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.THURSDAY}
              >
                Thursday
              </Checkbox>

              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...deliverySchedule.daysOfWeek, MONDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.MONDAY}
              >
                Monday
              </Checkbox>

              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...previousSchedule.daysOfWeek, FRIDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.FRIDAY}
              >
                Friday
              </Checkbox>

              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...deliverySchedule.daysOfWeek, TUESDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.TUESDAY}
              >
                Tuesday
              </Checkbox>
              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...previousSchedule.daysOfWeek, SATURDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.SATURDAY}
              >
                Saturday
              </Checkbox>
              <Checkbox
                onChange={({ detail }) =>
                  setDeliverySchedule(previousSchedule => ({
                    ...previousSchedule,
                    daysOfWeek: { ...deliverySchedule.daysOfWeek, WEDNESDAY: detail.checked },
                  }))
                }
                checked={deliverySchedule.daysOfWeek.WEDNESDAY}
              >
                Wednesday
              </Checkbox>
            </ColumnLayout>
          </FormField>
        )}
      </SpaceBetween>
    </Container>
  );
};

DeliveryScheduleComponent.propTypes = {
  deliverySchedule: PropTypes.object.isRequired,
  setDeliverySchedule: PropTypes.func.isRequired,
  frequencyOptions: PropTypes.array.isRequired,
  triggerOptions: PropTypes.array.isRequired,
  triggeringEventOptions: PropTypes.array.isRequired,
  relativeOperatorOptions: PropTypes.array.isRequired,
  relativeDateOptions: PropTypes.array.isRequired,
  temporalUnitOptions: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
  frequencyOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.frequency', []),
  ),
  triggerOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.trigger', []),
  ),
  triggeringEventOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.triggeringEvent', []),
  ),
  relativeOperatorOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.relativeOperator', []),
  ),
  relativeDateOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.relativeDate', []),
  ),
  temporalUnitOptions: getOptionsDefinition(
    get(state, 'entities.notifications.notificationFormData.data.temporalUnit', []),
  ),
});

export default connect(mapStateToProps)(DeliveryScheduleComponent);
