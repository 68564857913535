import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import SegmentedControl from '@amzn/awsui-components-react/polaris/segmented-control';
import SpaceBetween from '@amzn/awsui-components-react/polaris/space-between';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import * as React from 'react';
import { map, mergeWith } from 'lodash';
import getOverview from './utils';
import StudySummaryPieChart from '../../../common/chats/pie_chat/StudySummaryPieChart';
import QRE_SUM_AGG_TYPES from '../../constants/qreTypes';
import { aggregationsMergeFun } from '../../../common/utils/aggregation_utils';

const OverallQRE = ({ qreStudySummary, isQREStudySummaryLoading, selectedStatuses }) => {
  const [viewType, setViewType] = React.useState('percentage');

  const getHeader = () => {
    return (
      <Header
        variant="h2"
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <SegmentedControl
              selectedId={viewType}
              onChange={({ detail }) => setViewType(detail.selectedId)}
              options={[
                { text: 'View percentage', id: 'percentage' },
                { text: 'View number', id: 'number' },
              ]}
            />
          </SpaceBetween>
        }
      >
        Federal QRE
      </Header>
    );
  };

  const getMergedAggregation = () => {
    const selectedStatusesAggregations = map(selectedStatuses, selectedStatus =>
      get(qreStudySummary, ['aggByStatus', selectedStatus, 'aggByFederalQRE'], {}),
    );
    return mergeWith({}, ...selectedStatusesAggregations, aggregationsMergeFun);
  };

  const getPieChart = () => {
    return (
      <StudySummaryPieChart
        aggregation={getMergedAggregation()}
        aggTypes={QRE_SUM_AGG_TYPES}
        viewType={viewType}
        isLoading={isQREStudySummaryLoading}
        dataValuePrefix="$"
      />
    );
  };

  return (
    <Container header={getHeader()}>
      {getOverview(getMergedAggregation(), viewType)}
      {getPieChart()}
    </Container>
  );
};

OverallQRE.propTypes = {
  qreStudySummary: PropTypes.object,
  isQREStudySummaryLoading: PropTypes.bool,
  selectedStatuses: PropTypes.array.isRequired,
};

OverallQRE.defaultProps = {
  qreStudySummary: {},
  isQREStudySummaryLoading: false,
};

export default OverallQRE;
