import { Grid } from '@amzn/awsui-components-react';
import React from 'react';
import LaborTable from './labor_table/components/LaborTable';

const GroupingLaborTable = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <LaborTable />
      </div>
    </Grid>
  );
};

export default GroupingLaborTable;
