import { Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BRC_ITEMS_PAGE } from '../../common/components/with_page/components/bread_crumb/constants';
import Page from '../../common/components/with_page/Page';
import { isPayrollDataAccessible, RESOURCE } from '../auth/constants/rbac_constants';
import ReportCards from './components/report_cards/ReportCards';
import ReportTable from './components/report_table/ReportTable';
import { getDecoratedReportCards, REPORT_CARDS } from './constants/report_cards';
import { getStudyPeriod } from '../../common/constants/study_period';

const AnalyticsPage = ({ reportingData, studyPeriod }) => {
  const [reportCards, setReportCards] = React.useState(REPORT_CARDS);
  const [selectedReportCards, setSelectedReportCards] = useState([REPORT_CARDS[0]]);

  const setDecoratedReportCards = cards => {
    setReportCards(getDecoratedReportCards({ reportCards: cards, reportingData }));
  };

  useEffect(() => {
    setDecoratedReportCards(reportCards);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportingData]);

  useEffect(() => {
    setReportCards(REPORT_CARDS);
    setSelectedReportCards([REPORT_CARDS[0]]);
  }, [studyPeriod]);

  const getSelectedReportCard = () => {
    return get(selectedReportCards, [0], REPORT_CARDS[0]);
  };

  const content = (
    <SpaceBetween size="l">
      <Header variant="h1">Analytics</Header>
      <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
        <ReportCards
          reportCards={reportCards}
          setReportCards={setDecoratedReportCards}
          selectedReportCards={selectedReportCards}
          setSelectedReportCards={setSelectedReportCards}
        />
        <ReportTable selectedReportCard={getSelectedReportCard()} />
      </Grid>
    </SpaceBetween>
  );

  return (
    <Page
      PageId={RESOURCE.PAGE_ANALYTICS}
      isPageAccessControlled
      customAccessControl={isPayrollDataAccessible}
      PageContent={content}
      breadCrumbItems={BRC_ITEMS_PAGE.ANALYTICS(studyPeriod)}
    />
  );
};

AnalyticsPage.propTypes = {
  reportingData: PropTypes.object.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  reportingData: get(state, 'entities.reporting.data', {}) || {},
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(AnalyticsPage);
