import { Grid } from '@amzn/awsui-components-react/polaris';
import React from 'react';
import VendorTable from './vendors_table/components/VendorTable';

const PopulationContractTab = () => {
  return (
    <Grid gridDefinition={[{ colspan: 12 }]}>
      <div>
        <VendorTable />
      </div>
    </Grid>
  );
};

export default PopulationContractTab;
