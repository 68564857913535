import { ColumnLayout, Container, Header, Spinner } from '@amzn/awsui-components-react/polaris';
import PropTypes from 'prop-types';
import React from 'react';
import ValueWithLabel from '../../../../../../../common/components/value_with_label/ValueWithLabel';
import '../../../../../../../index.css';

const EOverviewCard = props => {
  const { isFetchingPopulationOverview, totalEmployees, terminatedEmployees } = props;
  return (
    <Container header={<Header variant="h2">Overview</Header>} className="full-height-card">
      {isFetchingPopulationOverview ? (
        <Spinner />
      ) : (
        <ColumnLayout columns={2} variant="text-grid">
          <ValueWithLabel label="Total Employees">{totalEmployees}</ValueWithLabel>
          <ValueWithLabel label="Terminated Employees">{terminatedEmployees}</ValueWithLabel>
        </ColumnLayout>
      )}
    </Container>
  );
};

EOverviewCard.propTypes = {
  isFetchingPopulationOverview: PropTypes.bool.isRequired,
  totalEmployees: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  terminatedEmployees: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default EOverviewCard;
