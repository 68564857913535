import {
  colorChartsBlue1300,
  colorChartsGreen300,
  colorChartsRed300,
  colorChartsYellow300,
} from '@amzn/awsui-components-react/polaris/internal/generated/styles/tokens';
import map from 'lodash/map';
import { values } from 'lodash';

const SURVEY_STATUSES = {
  NOT_STARTED: {
    label: 'Not started',
    key: 'Created',
    color: colorChartsRed300,
  },
  IN_PROGRESS: {
    label: 'In progress',
    key: 'InProgress',
    color: colorChartsBlue1300,
  },
  DEACTIVATED: {
    label: 'Deactivated',
    key: 'Deactivated',
    color: colorChartsYellow300,
  },
  COMPLETED: {
    label: 'Completed',
    key: 'Submitted',
    color: colorChartsGreen300,
  },
};

export const SURVEY_STATUES_KEYS = map(values(SURVEY_STATUSES), ({ key }) => key);

export default SURVEY_STATUSES;
