import { VIEW_EMAIL_ENDPOINT } from '../../../common/config/api_endpoints';
import { configureTemplateStateAction } from '../configure_notification/redux/configureTemplateState';

const getPayloadForTemplateDownload = templateId => {
  return {
    api: VIEW_EMAIL_ENDPOINT,
    presignedURLPayload: {
      entityType: 'SURVEY_NOTIFICATION',
      entityFileType: 'TEMPLATE',
      entityIdMap: {
        templateId,
      },
    },
    onDownloadSuccess: {
      action: configureTemplateStateAction.SET,
      payload: {},
    },
    onDownloadFailure: {
      action: configureTemplateStateAction.CLEAR,
    },
  };
};

export default getPayloadForTemplateDownload;
