const METRICS = {
  ON_TIME_COMPLETION_RATE: {
    label: 'On-time completion rate',
    key: 'OnTimeCompletionRate',
    isPercentage: true,
  },
  FIRST_DAY_COMPLETION_RATE: {
    label: '24hr completion rate',
    key: 'FirstDayCompletionRate',
    isPercentage: true,
  },
  SURVEY_REASSIGNMENT_RATE: {
    label: 'Re-assignment rate',
    key: 'SurveyReassignmentRate',
    isPercentage: true,
  },
  SECONDARY_ASSIGNEE_RATE: {
    label: 'Secondary assignment rate',
    key: 'SecondaryAssigneeRate',
    isPercentage: true,
  },
  AVERAGE_NUMBER_OF_SECONDARY_ASSIGNEES: {
    label: 'Average # of secondary assignees',
    key: 'AverageNumberOfSecondaryAssignees',
    isPercentage: false,
  },
};

export default METRICS;
