import { GET_POPULATION_OVERVIEW_ENDPOINT } from '../../../../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../../../../redux/epics/utils';

const SEARCH_LABOR_POPULATION_OVERVIEW = createAPILifecycleActionTypes(
  'SEARCH_LABOR_POPULATION_OVERVIEW',
);
export const getLaborPopulationOverviewActions = createAPILifecycleActions(
  SEARCH_LABOR_POPULATION_OVERVIEW.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_LABOR_POPULATION_OVERVIEW.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_LABOR_POPULATION_OVERVIEW.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case SEARCH_LABOR_POPULATION_OVERVIEW.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case SEARCH_LABOR_POPULATION_OVERVIEW.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const getLaborPopulationOverviewEpic = createAmplifyAPICallEpic({
  actionType: SEARCH_LABOR_POPULATION_OVERVIEW.BEGIN,
  api: GET_POPULATION_OVERVIEW_ENDPOINT,
  successCallback: response => [getLaborPopulationOverviewActions.SUCCESS(response.body)],
  errorCallback: () => [getLaborPopulationOverviewActions.FAILURE()],
});
