import get from 'lodash/get';
import isObject from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import * as React from 'react';
import { pick } from 'lodash/object';
import { Grid } from '@amzn/awsui-components-react';
import ValueWithLabel from '../../../../../../common/components/value_with_label/ValueWithLabelForSurveysTab';
import SURVEY_STATUSES, { SURVEY_STATUES_KEYS } from '../../constants/surveyStatuses';

const getFormattedOverviewCount = (viewType, statusSurveyCount, totalSurveyCount) => {
  if (isEqual(viewType, 'percentage')) {
    return `${((statusSurveyCount / totalSurveyCount) * 100).toFixed(0)}%`;
  }
  return statusSurveyCount;
};

const getOverview = (surveysStudySummary, viewType) => {
  const aggByStatus = get(surveysStudySummary, 'aggByStatus', {});

  if (isObject(aggByStatus) || isEmpty(aggByStatus)) {
    return null;
  }

  const totalSurveyCount = reduce(
    pick(aggByStatus, SURVEY_STATUES_KEYS),
    (accumulator, statusSurveyCount) => accumulator + statusSurveyCount,
  );

  return (
    <Grid
      gridDefinition={[
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
        { colspan: 2 },
      ]}
    >
      {map(SURVEY_STATUSES, ({ label, key }) => {
        return (
          <div className="right-divider">
            <ValueWithLabel key={label} label={label}>
              {getFormattedOverviewCount(viewType, get(aggByStatus, [key], 0), totalSurveyCount)}
            </ValueWithLabel>
          </div>
        );
      })}
      <ValueWithLabel key="Total" label="Total">
        {getFormattedOverviewCount(viewType, totalSurveyCount, totalSurveyCount)}
      </ValueWithLabel>
    </Grid>
  );
};

export default getOverview;
