import { combineEpics } from 'redux-observable';
import { downloadFileFromS3Epic } from '../actions/DownloadFileFromS3';
import { getDocumentDownloadPresignedURLEpic } from '../actions/GetDocumentDownloadPresignedURL';
import { getFileFromS3Epic } from '../actions/GetFileFromS3';

export default combineEpics(
  getDocumentDownloadPresignedURLEpic,
  getFileFromS3Epic,
  downloadFileFromS3Epic,
);
