import { Auth } from 'aws-amplify';
import { set, get } from 'lodash';
import createAmplifyAPICallEpic from '../../redux/epics/utils';
import { EXPORT_DATA_ENDPOINT } from '../config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../redux/actions/action_utils';

export const EXPORT_DATA_ACTION_TYPES = createAPILifecycleActionTypes('EXPORT_DATA_ACTION_TYPES');
export const exportDataAction = createAPILifecycleActions(EXPORT_DATA_ACTION_TYPES.NAME);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case EXPORT_DATA_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case EXPORT_DATA_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case EXPORT_DATA_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case EXPORT_DATA_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};
const addUserDetailsToPayload = async ({ originalPayload }) => {
  let newPayload = originalPayload;
  if (!newPayload) {
    newPayload = {};
  }
  const exportRequestAttributes = get((await Auth.currentSession()).getIdToken(), 'payload', {});
  set(newPayload, ['body', 'userEmail'], exportRequestAttributes.email);
  set(newPayload, ['body', 'user'], exportRequestAttributes.identities[0].userId);
  return newPayload;
};

export const exportDataEpic = createAmplifyAPICallEpic({
  actionType: EXPORT_DATA_ACTION_TYPES.BEGIN,
  api: EXPORT_DATA_ENDPOINT,
  payloadGenerator: addUserDetailsToPayload,
  successCallback: response => [exportDataAction.SUCCESS(response)],
  errorCallback: () => [exportDataAction.FAILURE()],
});
