import * as React from 'react';
import { useState } from 'react';
import { Button, ButtonDropdown, SpaceBetween } from '@amzn/awsui-components-react';
import { get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { some } from 'lodash/collection';
import ClearQREModal from './clear_qre_modal/ClearQREModal';
import {
  getQREClassificationType,
  QRE_CLASSIFICATION,
  QRE_JURISDICTIONS,
} from '../../constants/qre_constants';
import { exportDataAction } from '../../../../common/redux/export';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../../../../common/config/api_constants';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../../common/constants/study_period';

const QRETableActions = ({ items, exportDataBegin, query, studyPeriod, config }) => {
  const initialState = {
    clearQREModal: {
      isClearQREModalVisible: false,
      qreJurisdiction: QRE_JURISDICTIONS.FEDERAL,
    },
  };
  const [state, setState] = useState(initialState);

  const hideClearQREModal = () => {
    const { clearQREModal } = state;
    setState({
      clearQREModal: {
        ...clearQREModal,
        qreJurisdiction: QRE_JURISDICTIONS.FEDERAL,
        isClearQREModalVisible: false,
      },
    });
  };

  const showClearQREModal = ({ detail: { id } }) => {
    const { clearQREModal } = state;
    setState({
      clearQREModal: { ...clearQREModal, qreJurisdiction: id, isClearQREModalVisible: true },
    });
  };

  const onConfirmQREModal = () => {
    hideClearQREModal();
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'QRE_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          country: DEFAULT_COUNTRY,
          clientId: DEFAULT_BUSINESS_ID,
          query,
        },
      },
    });
  };

  const isClearQREDisabled = () => {
    if (isEmpty(items)) {
      return true;
    }

    return some(items, ({ surveyStatus }) => !isEqual(surveyStatus, 'Submitted'));
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={onExportHandler}>Export</Button>
      {QRE_CLASSIFICATION.SUPPLIES !== config.qreType && (
        <ButtonDropdown
          items={[
            { text: 'Federal', id: QRE_JURISDICTIONS.FEDERAL },
            { text: 'State', id: QRE_JURISDICTIONS.STATE },
          ]}
          onItemClick={showClearQREModal}
          disabled={isClearQREDisabled() || isActionDisabledForStudyPeriod(studyPeriod)}
        >
          Clear QRE
        </ButtonDropdown>
      )}
      <ClearQREModal
        qreClassification={getQREClassificationType(config.qreType)}
        qreJurisdiction={get(
          state,
          ['clearQREModal', 'qreJurisdiction'],
          QRE_JURISDICTIONS.FEDERAL,
        )}
        isVisible={get(state, ['clearQREModal', 'isClearQREModalVisible'], false)}
        onCancel={hideClearQREModal}
        onDismiss={hideClearQREModal}
        onConfirm={onConfirmQREModal}
        selectedItems={items}
      />
    </SpaceBetween>
  );
};

QRETableActions.propTypes = {
  items: PropTypes.array.isRequired,
  refreshData: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    studyPeriod: getStudyPeriod(state),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      exportDataBegin: exportDataAction.BEGIN,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(QRETableActions);
