import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../constants/route_paths';

const getDashboardBRItem = studyPeriod => {
  return {
    text: 'Home',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod)}`,
  };
};

const getPopulationPageBRItem = studyPeriod => {
  return {
    text: 'Population',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.POPULATION_PAGE, studyPeriod)}`,
  };
};

const getCurrentStudyPeriodBRItem = studyPeriod => {
  return {
    text: `Study Period ${studyPeriod}`,
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.STUDY_SUMMARY, studyPeriod)}`,
  };
};

const getNotificationPageBRItem = studyPeriod => {
  return {
    text: 'Notification',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.NOTIFICATION_PAGE, studyPeriod)}`,
  };
};

const getSurveyPageBRItem = studyPeriod => {
  return {
    text: 'Surveys',
    href: `${getRoutePathWithStudyPeriod(ROUTE_PATH.SURVEY_PAGE, studyPeriod)}`,
  };
};

const getBRCItemWithText = text => {
  return {
    text,
  };
};

const getQNQPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getBRCItemWithText('QNQ')];
};

const getSurveyPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getBRCItemWithText('Surveys')];
};

const getCreateSurveyPageBRCItems = studyPeriod => {
  return [
    getCurrentStudyPeriodBRItem(studyPeriod),
    getSurveyPageBRItem(studyPeriod),
    getBRCItemWithText('Create surveys'),
  ];
};

const getPopulationPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getBRCItemWithText('Population')];
};

const getGroupingPageBRCItems = studyPeriod => {
  return [
    getCurrentStudyPeriodBRItem(studyPeriod),
    getPopulationPageBRItem(studyPeriod),
    getBRCItemWithText('Grouping'),
  ];
};

const getNotificationPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getBRCItemWithText('Notification')];
};

const getNotificationDetailsPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getNotificationPageBRItem(studyPeriod)];
};

const getCreateNotificationPageBRCItems = (studyPeriod, brcText) => {
  return [
    getCurrentStudyPeriodBRItem(studyPeriod),
    getNotificationPageBRItem(studyPeriod),
    getBRCItemWithText(brcText),
  ];
};

const getQREPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getBRCItemWithText('QRE')];
};

const getAnalyticsPageBRCItems = studyPeriod => {
  return [getCurrentStudyPeriodBRItem(studyPeriod), getBRCItemWithText('Analytics')];
};

const getNotFoundPageBRCItems = studyPeriod => {
  return [getDashboardBRItem(studyPeriod), getBRCItemWithText('Not found!')];
};

export const NOT_AUTHORIZED_TO_ACCESS_PAGE_BRC_ITEMS = [
  getBRCItemWithText('You are not authorized to access this page!'),
];

export const BRC_ITEMS_PAGE = {
  QNQ: getQNQPageBRCItems,
  SURVEY: getSurveyPageBRCItems,
  CREATE_SURVEY: getCreateSurveyPageBRCItems,
  POPULATION: getPopulationPageBRCItems,
  GROUPING: getGroupingPageBRCItems,
  NOTIFICATION: getNotificationPageBRCItems,
  NOTIFICATION_DETAILS: getNotificationDetailsPageBRCItems,
  CREATE_NOTIFICATION: getCreateNotificationPageBRCItems,
  QRE: getQREPageBRCItems,
  ANALYTICS: getAnalyticsPageBRCItems,
  NOT_FOUND: getNotFoundPageBRCItems,
};
