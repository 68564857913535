import { Button, ButtonDropdown, SpaceBetween } from '@amzn/awsui-components-react';
import * as React from 'react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { forEach, get, isEmpty, map, size } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Auth } from 'aws-amplify';
import DeactivateModal from './Modals/DeactivateModal';
import UpdateSurveyDurationModal from './Modals/UpdateSurveyDurationModal';
import UpdateSurveyTemplateModal from './Modals/UpdateSurveyTemplateModal';
import AdditionalInformationModal from './Modals/AdditionalInfromationModal';
import { updateSurveyAction } from '../redux/UpdateSurveys';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../../../common/config/api_constants';
import { exportDataAction } from '../../../common/redux/export';
import { ROUTE_PATH } from '../../../common/constants/route_paths';
import PDFGenerator from '../../../common/components/pdf_generator/PDFGenerator';
import {
  getStudyPeriod,
  isActionDisabledForStudyPeriod,
} from '../../../common/constants/study_period';
import {
  getImportDropdownItems,
  getUploadToS3PayloadForTriggerImport,
  isImportInProgress,
} from '../../import_data/utils/import_utils';
import {
  ACCEPTED_IMPORT_FILE_TYPES,
  SUPPORTED_IMPORT_TYPES,
} from '../../import_data/constants/import_types';
import FileImportModal from '../../../common/components/file_import_modal/FileImportModal';
import { uploadFileToS3Action } from '../../../common/components/file_importer/redux/actions/UploadFileToS3';
import { changeDateToEpoch, getTodayDateInUSTimeZone } from '../../../common/utils/date_time_util';

const SurveyActions = ({
  items,
  setSelectedItems,
  updateData,
  exportDataBegin,
  query,
  deactivationReasons,
  studyPeriod,
  config,
  uploadFileToS3,
}) => {
  const initialState = {
    deactivate: false,
    duration: false,
    template: false,
    additionalInformation: false,
  };

  const [visibilityState, setVisibilityState] = useState(initialState);

  useEffect(() => {
    Auth.currentSession().then(session =>
      setVisibilityState({
        ...visibilityState,
        user: get(session, 'idToken.payload.identities')[0].userId,
      }),
    );
    // eslint-disable-next-line
  }, []);

  const showDeactivateModal = () => {
    setVisibilityState({ ...visibilityState, deactivate: true });
  };
  const onCancelDeactivateModal = () => {
    setSelectedItems([]);
    setVisibilityState({ ...visibilityState, deactivate: false });
  };
  const onConfirmDeactivateModal = (records, selectedReason) => {
    updateData({
      body: {
        lastUpdatedBy: get(visibilityState, 'user'),
        surveyIdList: map(records, record => get(record, 'surveyId', '')),
        updateType: 'Deactivate',
        deactivationReason: get(selectedReason, 'label', null),
      },
    });
    setVisibilityState({ ...visibilityState, deactivate: false });
    setSelectedItems([]);
  };
  const showInformationModal = () => {
    setVisibilityState({ ...visibilityState, additionalInformation: true });
  };
  const onCancelInformationModal = () => {
    setSelectedItems([]);
    setVisibilityState({ ...visibilityState, additionalInformation: false });
  };
  const onConfirmInformationModal = records => {
    updateData({
      body: {
        lastUpdatedBy: get(visibilityState, 'user'),
        surveyIdList: map(records, record => get(record, 'surveyId', '')),
        updateType: 'Request additional information',
      },
    });
    setVisibilityState({ ...visibilityState, additionalInformation: false });
    setSelectedItems([]);
  };

  const updateHandler = ({ detail }) => {
    setVisibilityState({ ...visibilityState, [detail.id]: true });
  };
  const onCancelUpdateDurationModal = () => {
    setSelectedItems([]);
    setVisibilityState({ ...visibilityState, duration: false });
  };

  const onConfirmUpdateDurationModal = (records, dates) => {
    updateData({
      body: {
        dueDate: get(dates, 'dueDate').toString(),
        lastUpdatedBy: get(visibilityState, 'user'),
        releaseDate:
          changeDateToEpoch(getTodayDateInUSTimeZone(), 'releaseDate') > dates.releaseDate
            ? null
            : get(dates, 'releaseDate').toString(),
        surveyIdList: map(records, record => get(record, 'surveyId', '')),
        updateType: 'Duration',
      },
    });
    setVisibilityState({ ...visibilityState, duration: false });
    setSelectedItems([]);
  };

  const onCancelUpdateTemplateModal = () => {
    setVisibilityState({ ...visibilityState, template: false });
  };
  const onConfirmUpdateTemplateModal = (records, templateDocumentId) => {
    updateData({
      body: {
        lastUpdatedBy: get(visibilityState, 'user'),
        templateDocumentId,
        surveyIdList: map(records, record => get(record, 'surveyId', '')),
        updateType: 'Template',
      },
    });
    setVisibilityState({ ...visibilityState, template: false });
    setSelectedItems([]);
  };
  const areAllTemplateDocumentIdSame = () => {
    const surveyTypes = new Set();
    const surveyStatus = get(items, [0, 'status']);
    map(items, ({ templateDocumentId }) => surveyTypes.add(templateDocumentId));
    if (size(surveyTypes) === 1 && surveyStatus !== 'Submitted' && surveyStatus !== 'Deactivated')
      return false;
    return true;
  };

  const isDeactivateDisabled = () => {
    const surveyStatuses = new Set();
    forEach(items, ({ status }) => surveyStatuses.add(status));
    if (surveyStatuses.has('Submitted') || surveyStatuses.has('Deactivated') || size(items) === 0)
      return true;
    return false;
  };
  const isRequireAdditionalInfoDisabled = () => {
    const surveyStatuses = new Set();
    forEach(items, ({ status }) => surveyStatuses.add(status));
    if (surveyStatuses.has('Created') || surveyStatuses.has('InProgress') || size(items) === 0)
      return true;
    return false;
  };

  const isPdfGeneratorVisible = () => {
    return size(items) === 1;
  };

  const onExportHandler = () => {
    exportDataBegin({
      body: {
        type: 'SURVEYS_EXTRACT',
        isJSON: false,
        query: {
          studyPeriod,
          country: DEFAULT_COUNTRY,
          clientId: DEFAULT_BUSINESS_ID,
          query,
        },
      },
    });
  };

  const getEditSurveyAction = () => {
    const surveyStatus = get(items, [0, 'status']);
    const surveyId = get(items, [0, 'surveyId']);
    const isDisabled = size(items) !== 1 || surveyStatus === 'Deactivated';
    const rndSurveyDashboardEndpoint = `/${studyPeriod}${ROUTE_PATH.RND_SURVEY_DASHBOARD_PAGE}`;
    const editLink =
      isDisabled || isEmpty(surveyId)
        ? null
        : `${process.env.REACT_APP_SME_EXPERIENCE_URI +
            rndSurveyDashboardEndpoint}?surveyId=${surveyId}`;

    return (
      <Button
        target="_blank"
        disabled={isDisabled}
        href={editLink}
        iconAlign="right"
        iconName="external"
      >
        View
      </Button>
    );
  };

  const [fileImportType, setFileImportType] = React.useState(null);
  const [isFileImportModalVisible, setFileImportModalVisibility] = React.useState(false);

  const showFileImportModal = ({ detail }) => {
    setFileImportType(detail.id);
    setFileImportModalVisibility(true);
  };

  const onCancelFileImportModal = () => {
    setFileImportModalVisibility(false);
  };

  const onConfirmFileImportModal = fileState => {
    // state will contain file details in props
    setFileImportModalVisibility(false);

    uploadFileToS3(
      getUploadToS3PayloadForTriggerImport({
        documentFile: fileState.form.file,
        fileImportType,
      }),
    );
  };

  return (
    <SpaceBetween direction="horizontal" size="xs">
      <PDFGenerator record={get(items, [0], {})} isVisible={isPdfGeneratorVisible()} />
      <Button onClick={onExportHandler}>Export</Button>
      <Button
        onClick={showDeactivateModal}
        disabled={isDeactivateDisabled() || isActionDisabledForStudyPeriod(studyPeriod)}
      >
        Deactivate
      </Button>
      <Button
        disabled={isRequireAdditionalInfoDisabled() || isActionDisabledForStudyPeriod(studyPeriod)}
        onClick={showInformationModal}
      >
        Request additional information
      </Button>
      <ButtonDropdown
        items={[
          { text: 'Duration', id: 'duration', disabled: size(items) === 0 },
          { text: 'Template', id: 'template', disabled: areAllTemplateDocumentIdSame() },
        ]}
        onItemClick={updateHandler}
        disabled={isActionDisabledForStudyPeriod(studyPeriod)}
      >
        Update
      </ButtonDropdown>
      <ButtonDropdown
        items={getImportDropdownItems(SUPPORTED_IMPORT_TYPES.SURVEY, config)}
        onItemClick={showFileImportModal}
        disabled={isActionDisabledForStudyPeriod(studyPeriod) || isImportInProgress(config)}
      >
        Import
      </ButtonDropdown>
      {getEditSurveyAction()}
      <DeactivateModal
        records={items}
        isVisible={get(visibilityState, 'deactivate')}
        onDismiss={onCancelDeactivateModal}
        onConfirm={onConfirmDeactivateModal}
        onCancel={onCancelDeactivateModal}
        deactivationReasons={deactivationReasons}
      />
      <AdditionalInformationModal
        isVisible={get(visibilityState, 'additionalInformation')}
        onConfirm={onConfirmInformationModal}
        onCancel={onCancelInformationModal}
        records={items}
      />
      <UpdateSurveyDurationModal
        records={items}
        isVisible={get(visibilityState, 'duration')}
        onDismiss={onCancelUpdateDurationModal}
        onConfirm={onConfirmUpdateDurationModal}
        onCancel={onCancelUpdateDurationModal}
      />
      <UpdateSurveyTemplateModal
        records={items}
        isVisible={get(visibilityState, 'template')}
        onDismiss={onCancelUpdateTemplateModal}
        onConfirm={onConfirmUpdateTemplateModal}
        onCancel={onCancelUpdateTemplateModal}
      />
      <FileImportModal
        isVisible={isFileImportModalVisible}
        onDismiss={onCancelFileImportModal}
        onConfirm={onConfirmFileImportModal}
        onCancel={onCancelFileImportModal}
        acceptedFileTypes={ACCEPTED_IMPORT_FILE_TYPES}
      />
    </SpaceBetween>
  );
};
SurveyActions.propTypes = {
  setSelectedItems: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  updateData: PropTypes.func.isRequired,
  exportDataBegin: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired,
  deactivationReasons: PropTypes.array.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  config: PropTypes.object.isRequired,
  uploadFileToS3: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  deactivationReasons:
    get(state, 'entities.schemas.data.metaData.surveyDeactivationReasons', []) || [],
  studyPeriod: getStudyPeriod(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateData: updateSurveyAction.BEGIN,
      exportDataBegin: exportDataAction.BEGIN,
      uploadFileToS3: uploadFileToS3Action,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SurveyActions);
